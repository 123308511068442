import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "./TrackOffer.css";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import Createoffer from "./Createoffer";
import { fetchCurrentUTCTime } from "../API/AuthApi";
import RolesAndPermissions from "./RolesAndPermissions";
import Location from "./Location";
import { useNavigate } from "react-router-dom";
import { fetchAllCampaigns, getCampaignInsights, startCampaign, stopCampaign } from "../API/Api";

const TrackOffer = () => {
    const [state, setState] = useState({
        createOffer: false,
        trackOffer: false,
        locationTab: false,
        activeButton: "Active",
        offerVisible: true,
        stopOfferBtn: false,
        restaurantId: localStorage.getItem("restaurantId"),
        days: 10,
        campaignId: "",
    });

    const { createOffer, trackOffer, locationTab, stopOfferBtn } = state;
    const [campaignData, setCampaignData] = useState(null);
    const [expandedOffers, setExpandedOffers] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const handleBackNavigation = (event) => {
            event.preventDefault();
            navigate("/TrackOffer", { replace: true }); // Redirect to TrackOffer
        };

        // Add event listener to disable back button
        window.history.pushState(null, null, window.location.href);
        window.addEventListener("popstate", handleBackNavigation);

        return () => {
            window.removeEventListener("popstate", handleBackNavigation);
        };
    }, [navigate]);

    useEffect(() => {
        const syncTime = async () => {
            try {
                const response = await fetchCurrentUTCTime();  // Fetch the UTC time from API
                const utcTime = response.data.utcTime;
                const utcTimestamp = new Date(utcTime);  // Convert fetched time to Date object
                localStorage.setItem("currentTime", utcTimestamp.getTime().toString()); // Store timestamp in milliseconds
            } catch (error) {
                console.error("Failed to sync time:", error);
            }
        };
        syncTime(); // Sync the time initially
        getCampaigns();
    }, []);

    const getCampaigns = async () => {
        try {
            const response = await getCampaignInsights(state.restaurantId);
            setCampaignData(response);
        } catch (error) {
            console.error("Failed to fetch campaign insights", error);
        }
    };

    const discountTypeMapping = {
        0: "Percentage Discount",
        1: "Flat Discount",
        2: "Flat Percentage Discount",
        3: "Predefined Discount",
        4: "Delivery Discount",
        5: "Custom Coupon Discount",
        6: "FreeBie Discount",
        7: "BOGO Discount"
    };

    const userCodeMapping = { 0: "New Customer", 1: "Regular Customer", 2: "Loyal Customer", 3: "Returning Customer", 4: "Infrequent Customer" };

    const getFilteredCampaigns = () => {
        if (!campaignData) return [];
        switch (state.activeButton) {
            case "Active": return campaignData.active || [];
            case "Scheduled": return campaignData.scheduled || [];
            case "InActive": return campaignData.inactive || [];
            case "Completed": return campaignData.completedCampaigns || [];
            default: return [];
        }
    };

    const campaigns = getFilteredCampaigns();

    const toggleOfferVisibility = (campaignId) => {
        setExpandedOffers(prevState => ({
            ...prevState,
            [campaignId]: !prevState[campaignId]
        }));
    };

    const handleTabSelection = (tab) => {
        setState(prevState => ({
            ...prevState,
            createOffer: tab === "createOffer",
            trackOffer: tab === "trackOffer",
            locationTab: tab === "locationTab",
        }));
    };

    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();

        // Function to add ordinal suffix (st, nd, rd, th)
        const getOrdinalSuffix = (day) => {
            if (day > 3 && day < 21) return "th"; // Covers 11th to 19th
            switch (day % 10) {
                case 1: return "st";
                case 2: return "nd";
                case 3: return "rd";
                default: return "th";
            }
        };

        return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
    };

    const handleStopCampaign = async (campaignId) => {
        try {
            const payload = {
                restaurantId: state.restaurantId, // Get from state
                campaignId: [campaignId], // API expects an array
            };

            console.log("Stopping Campaign with Payload:", payload); // Debugging log

            await stopCampaign(payload); // Call API

            alert("Campaign stopped successfully!"); // Notify user

            setState((prevState) => ({
                ...prevState,
                stopOfferBtn: false, // Close popup after stopping campaign
            }));

            getCampaigns(); // Refresh campaigns list
        } catch (error) {
            console.error("Failed to stop campaign:", error);
            alert("Failed to stop campaign. Please try again."); // Error message
        }
    };

    const handleStartCampaign = async (campaignId) => {
        try {
            const payload = {
                restaurantId: state.restaurantId, // Get from state
                campaignId: [campaignId], // API expects an array
            };

            console.log("Stopping Campaign with Payload:", payload); // Debugging log

            await startCampaign(payload); // Call API

            alert("Campaign stopped successfully!"); // Notify user

            setState((prevState) => ({
                ...prevState,
                stopOfferBtn: false, // Close popup after stopping campaign
            }));

            getCampaigns(); // Refresh campaigns list
        } catch (error) {
            console.error("Failed to stop campaign:", error);
            alert("Failed to stop campaign. Please try again."); // Error message
        }
    };


    function closeOfferPopup() {
        setState(prevState => ({
            ...prevState,
            stopOfferBtn: false
        }));
    }

    return (
        <div className="trackOffer">
            <RolesAndPermissions />
            <div className="border-b-2 border-gray-300 shadow">
                <h3 className="heading text-xl font-bold p-3">Offers</h3>
            </div>
            <hr className="divider" />
            <div className="offer_headings flex items-center overflow-hidden">
                <p
                    className={`heading font-semibold ms-10 me-14 mt-7 mb-2 ${createOffer && "createOffer_decoration"}`}
                    onClick={() => handleTabSelection("createOffer")}>
                    Create Offers
                </p>
                <p
                    className={`heading font-semibold mt-7 mb-2 ${trackOffer && "track_decoration"}`}
                    onClick={() => handleTabSelection("trackOffer")}>
                    Track Offers
                </p>
                <p
                    className={`heading font-semibold mt-7 mb-2 px-10 ${locationTab && "location_decoration"}`}
                    onClick={() => handleTabSelection("locationTab")}>
                    Location
                </p>
            </div>
            <hr className="divider" />
            {trackOffer ? (
                <>
                    <div className="trackOffer_btns ms-10 mt-7">
                        {['Active', 'Scheduled', 'InActive', 'Completed'].map(button => (
                            <button key={button} className={`rounded-md px-14 py-2 mr-12 ${state.activeButton === button ? "active-btn" : ""}`} onClick={() => setState(prev => ({ ...prev, activeButton: button }))}>
                                {button}
                            </button>
                        ))}
                    </div>
                    {state.trackOffer && campaigns.length > 0 ? campaigns.map((campaign, index) => (
                        <>
                            <div key={index} className="normalOffer bg-white rounded-md flex justify-between mt-4 mx-10 p-4">
                                <div className="left text-black font-normal">
                                    {/* <p className="font-bold">{discountTypeMapping[campaign.Campaigns.discountTypeCode]}</p> */}
                                    <p className="font-bold">{campaign.campaignName}</p>
                                    <p className="date mt-2">Start Date: {new Date(campaign.Campaigns.campaignStartDate).toISOString().split('T')[0].replace(/-/g, '-')}</p>
                                </div>
                                <div className="right font-bold">
                                    <button className="new_user rounded-md text-white px-5 p-1.5 me-2">
                                        {userCodeMapping[campaign.Campaigns.userCode]}
                                    </button>
                                    <button className="active rounded-md px-5 py-1.5">
                                        {state.activeButton}
                                    </button>
                                </div>
                            </div>

                            <div className="offerInfo bg-white rounded-md mx-10 mt-4">
                                <div className="offer_boxes flex justify-between mx-3 mt-2">
                                    <div className="box rounded-md ps-4 py-4">
                                        <p className="font-bold count">₹{campaign.grossSales}</p>
                                        <p className="font-normal count_head mt-1.5">Gross Sales</p>
                                    </div>
                                    <div className="box rounded-md ps-4 py-4">
                                        <p className="font-bold count">{campaign.ordersDelivered}</p>
                                        <p className="font-normal count_head mt-1.5">Orders Delivered</p>
                                    </div>
                                    <div className="box rounded-md ps-4 py-4">
                                        <p className="font-bold count">₹{campaign.discountGiven}</p>
                                        <p className="font-normal count_head mt-1.5">Discount Given</p>
                                    </div>
                                    <div className="box rounded-md ps-4 py-4">
                                        <p className="font-bold count">{campaign.effectiveDiscountPercentage || '0.0'}%</p>
                                        <p className="font-normal count_head mt-1.5">Effective Discount %</p>
                                    </div>
                                </div>
                                {expandedOffers[campaign.Campaigns.campaignId] && (() => {
                                    const { Campaigns } = campaign;
                                    const discountType = Campaigns.discountTypeCode;

                                    // Mapping correct discount details based on discountTypeCode
                                    let discountDetails = null;

                                    if (discountType === 0) {
                                        discountDetails = Campaigns.PercentageDiscountDetails?.[0];
                                    } else if (discountType === 1) {
                                        discountDetails = Campaigns.FlatDiscountDetails?.[0];
                                    } else if (discountType === 2) {
                                        discountDetails = Campaigns.FlatPercentageDiscountDetails?.[0];
                                    } else if (discountType === 3) {
                                        discountDetails = Campaigns.PredefinedDiscountDetails?.[0];
                                    } else if (discountType === 4) {
                                        discountDetails = Campaigns.DeliveryDiscountDetails?.[0];
                                    } else if (discountType === 5) {
                                        discountDetails = Campaigns.CustomCouponDiscountDetails?.[0];
                                    } else if (discountType === 6) {
                                        discountDetails = Campaigns.FreeBieDiscountDetails?.[0];
                                    } else if (discountType === 7) {
                                        discountDetails = Campaigns.BOGODiscountDetails?.[0];
                                    }
                                    return (
                                        <div className="offerDetails" id="ds">
                                            <p className="mt-5 ms-5">
                                                {/* Performance updated till: {new Date(campaign.Campaigns.campaignEndDate).toISOString().split('T')[0].replace(/-/g, '-')} */}
                                                Performance updated till: {(() => {
                                                    const startDate = new Date(campaign.Campaigns.campaignStartDate);
                                                    const endDate = new Date(campaign.Campaigns.campaignEndDate);
                                                    const timeDiff = endDate - startDate;
                                                    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

                                                    if (daysDiff === 1) {
                                                        return "Tomorrow";
                                                    } else if (daysDiff > 1 && daysDiff <= 30) {
                                                        return `${daysDiff} days`;
                                                    } else if (daysDiff > 30 && daysDiff <= 60) {
                                                        return "1 month";
                                                    } else if (daysDiff > 60 && daysDiff <= 90) {
                                                        return "2 months";
                                                    } else {
                                                        return `${Math.floor(daysDiff / 30)} months`;
                                                    }
                                                })()}
                                            </p>
                                            <p className="mt-5 ms-5">
                                                Offer applicable for: {userCodeMapping[campaign.Campaigns.userCode]} on all menu items,excluding
                                                MRP items
                                            </p>
                                            {discountDetails?.minimumOrderValue && (
                                                <p className="mt-5 ms-5">Minimum order value: ₹{discountDetails.minimumOrderValue}</p>
                                            )}

                                            {discountDetails?.discountValue && (
                                                <p className="mt-5 ms-5">Discount Value: ₹{discountDetails.discountValue}</p>
                                            )}

                                            {discountDetails?.maxDiscountValue && (
                                                <p className="mt-5 ms-5">Maximum Discount: ₹{discountDetails.maxDiscountValue}</p>
                                            )}
                                            <p className="mt-5 ms-5">
                                                Valid at: {campaign.ValidAt}
                                            </p>
                                            <p className="mt-5 ms-5">
                                                Offer sharing: {discountDetails.percentage || "0"}% of the discount value is being funded by
                                                you
                                            </p>
                                            {state.activeButton !== "Completed" && campaign.Campaigns.isStopped === false && (
                                                <button className="rounded-md w-full text-center mt-5 ms-5 py-1" onClick={() => setState({ ...state, stopOfferBtn: true })}>
                                                    Stop Offer
                                                </button>
                                            )}
                                        </div>)
                                })()}
                                <hr className="divider my-2 " />
                                <div className="flex items-center justify-center mb-2 cursor-pointer" onClick={() => toggleOfferVisibility(campaign.Campaigns.campaignId)}>
                                    <button>
                                        <FontAwesomeIcon className="font-bold" icon={expandedOffers[campaign.Campaigns.campaignId] ? faAngleUp : faAngleDown} />
                                    </button>
                                </div>
                            </div>
                        </>
                    )) : <p className="text-center mt-5">Nothing to show</p>}

                    {stopOfferBtn && campaigns.map((campaign, index) => (
                        <div className="flex items-center justify-center fixed top-0 left-0 right-0 bottom-0 backdrop-blur-3xl ">
                            <div className="stopOffer bg-white rounded-md text-center">
                                <div className="px-6 pt-5">
                                    <div className="flex justify-center mb-3">
                                        <button className="exclamation">
                                            <FontAwesomeIcon
                                                icon={faExclamation}
                                                className="exclamation-icon"
                                            ></FontAwesomeIcon>
                                        </button>
                                    </div>
                                    <h2 className="font-bold mt-2">
                                        Are you sure you want to stop this Offer?
                                    </h2>
                                    <p className="mt-2">
                                        You have earned ₹{campaign.grossSales} of revenue with {campaign.ordersDelivered} order from this
                                        offer. If you want to stop this offer, it will stop at {formatDate(new Date())}
                                    </p>
                                </div>
                                <div className="mt-3">
                                    <hr className="divider" />
                                    <button className="text-sky-500 my-3" onClick={() => handleStopCampaign(campaign.Campaigns.campaignId)}>Yes, Stop</button>
                                    <hr className="divider" />
                                    <button className="my-3" onClick={closeOfferPopup}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div>
                    </div>
                </>
            ) : createOffer ? (
                <Createoffer />
            ) : locationTab ? (
                <Location />
            ) : <Createoffer />}
        </div>
    );
};

export default TrackOffer;