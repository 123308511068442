import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faUser, faEdit, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/logo1.webp"; // Import the custom logo
import chef from "../assets/chef.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useStore from "../zustand";
import { useNavigate } from "react-router-dom";
import { logoutService } from '../API/LogoutService';
import { fetchMerchantUserPermissions, fetchOnlineStatus, listPortalUsers, restaurantStatusUpdate } from "../API/Api";
import permissionStore from "./PermissionStore";
import { FaCaretDown } from "react-icons/fa"; // Import the dropdown icon

const Head = () => {
  const { setVisible, setNoVisible } = useStore();
  const nameInputRef = useRef(null); // Reference for the name input field
  const phoneInputRef = useRef(null); // Reference for the phone input field
  const [state, setState] = useState({
    isOffline: '',
    isUserMenuOpen: false,
    visibleSlides: [true, true, true],
    userName: '',
    userPhone: '',
    isNameEdit: false,
    isPhoneEdit: false,
    userImage: '',
    notificationVisible: true,
    showLogoutModal: false,
    nameError: "", // Error state for the name field
    phoneError: "", // Error state for the phone field
    imageError: "", // Error state for the image upload
    showTrashIcon: false, // Show the delete icon only after an image is uploaded
    restaurantId: localStorage.getItem("restaurantId"),
    showModal: false,
    nextAvailableAt: '',
    newStatus: '',
    selectedTime: "",
    selectedDate: "",
    isSigningAuthority: permissionStore.getPermission("isSigningAuthority"),
  });
  const navigate = useNavigate();
  const toggleUserMenu = () => {
    setState((prev) => ({
      ...prev,
      isUserMenuOpen: !prev.isUserMenuOpen
    }));
  };

  useEffect(() => {
    fetchAndStoreUserInfo();
  }, [state.restaurantId])

  const fetchAndStoreUserInfo = async () => {
    try {
      // Fetch restaurant details
      const infoResponse = await listPortalUsers(state.restaurantId);

      // Get stored phone number from localStorage
      const storedPhoneNumber = localStorage.getItem("phoneNumber");

      if (!storedPhoneNumber) {
        console.error("Phone number not found in localStorage");
        return;
      }

      // Find user whose phoneNumber matches the storedPhoneNumber
      const matchedUser = infoResponse.find(
        (user) => user.phoneNumber === storedPhoneNumber
      );

      if (matchedUser) {
        setState((prev) => ({
          ...prev,
          userName: matchedUser.name,
          userPhone: matchedUser.phoneNumber
        }))
        // console.log("User details stored:", matchedUser);
      } else {
        console.warn("No matching user found for the stored phone number.");
      }
    } catch (error) {
      console.error("Error fetching outlet info:", error);
    }
  };

  useEffect(() => {
    const isSigningAuthority = permissionStore.getPermission("isSigningAuthority");
    setState((prev) => ({ ...prev, isSigningAuthority }));
    setState((prev) => ({ ...prev, userImage: localStorage.getItem("userProfilePic") }))
  }, []);

  const handleClose = (index) => {
    const newVisibleSlides = [...state.visibleSlides];
    newVisibleSlides[index] = false;
    setState((prev) => ({
      ...prev,
      visibleSlides: newVisibleSlides
    }));
  };
  useEffect(() => {
    if (!state.visibleSlides[0] && !state.visibleSlides[1] && !state.visibleSlides[2]) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [state.visibleSlides]);

  // console.log(visible)
  const settings = {
    dots: true,
    infinite: false, // Ensure the slider stops at the end
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false, // Turn off autoplay for better control
    appendDots: (dots) => (
      <div style={{ position: "absolute", bottom: "1px", color: "white" }}>
        <ul> {dots} </ul>
      </div>
    ),
  };

  const allSlidesClosed = state.visibleSlides.every((slide) => !slide);
  // notification code start
  const handleCloseNotification = () => {
    setState((prev) => ({
      ...prev,
      notificationVisible: false
    }));
    setNoVisible(true);
  };

  const camelCaseToNormal = (text) => {
    return text
      .replace(/([A-Z])/g, ' $1') // Add a space before each uppercase letter
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  };

  // Image validation function
  const validateImage = (file) => {
    const validImageTypes = ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'];
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB limit

    if (!file) {
      return "No file selected.";
    }

    if (!validImageTypes.includes(file.type)) {
      return "Only JPEG, PNG, WEBP, and PDF files are allowed.";
    }
    if (file.size > maxSizeInBytes) {
      return "Image size cannot exceed 5MB.";
    }

    if (file.size === 0) {
      return "File is empty.";
    }

    return ""; // No error
  };

  // Image upload handler
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const error = validateImage(file);

    if (error) {
      setState((prev) => ({
        ...prev,
        imageError: error,
      }));
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setState((prev) => ({
        ...prev,
        userImage: reader.result,
        imageError: "", // Clear error if the image is valid
        showTrashIcon: true, // Show the trash icon after successful upload
      }));
      alert('Image uploaded successfully!'); // Success message
    };

    // Handle corrupted file reading
    reader.onerror = () => {
      setState((prev) => ({
        ...prev,
        imageError: "Failed to upload. The file may be corrupted.",
      }));
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // Delete uploaded image
  const handleDeleteImage = () => {
    setState((prev) => ({
      ...prev,
      userImage: "", // Reset to placeholder image
      imageError: "", // Clear error if any
      showTrashIcon: false, // Hide the trash icon after deletion
    }));
    alert('Image deleted successfully!');
  };
  useEffect(() => {
    if (state.isPhoneEdit && phoneInputRef.current) {
      phoneInputRef.current.focus();
    }
    if (state.isNameEdit && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [state.isPhoneEdit, state.isNameEdit]);

  // Name change handler with input restriction
  const handleNameChange = (event) => {
    const newValue = event.target.value;
    const validValue = newValue.replace(/[^a-zA-Z\s]/g, ''); // Replace invalid characters
    validateName(validValue); // Validate the name input
    setState((prev) => ({
      ...prev,
      userName: validValue,
    }));
  };

  // Validation function for name with max length
  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]*$/; // This regex allows only alphabetic characters and spaces

    if (!name) {
      setState((prev) => ({
        ...prev,
        nameError: "Name cannot be empty",
      }));
      return false;
    }
    setState((prev) => ({
      ...prev,
      nameError: "", // Clear error if valid
    }));
    return true;
  };
  // Toggle name edit mode
  const toggleNameEdit = () => {
    // If the phone is empty or invalid, show an alert and do not close edit mode
    if (!validateName(state.userName)) {
      alert("Please enter your name before closing the edit mode.");
      return;
    }
    // If user is done editing, toggle the state without extra validation
    setState((prev) => ({
      ...prev,
      isNameEdit: !prev.isNameEdit,
    }));
  };

  // Phone change handler
  const handlePhoneChange = (event) => {
    const newValue = event.target.value;

    // Remove +91- from the new value and allow only digits
    const phoneDigits = newValue.replace(/[^\d]/g, "");

    // Allow only up to 10 digits after +91-
    if (phoneDigits.length <= 10) {
      setState((prev) => ({
        ...prev,
        userPhone: phoneDigits, // Store only the digits, not +91-
      }));
      validatePhone(phoneDigits); // Validate the phone number input
    }
  };

  // Validation function for phone number
  const validatePhone = (phone) => {
    if (phone.length === 0) {
      setState((prev) => ({
        ...prev,
        phoneError: "Phone number cannot be empty",
      }));
      return false;
    }

    if (phone.length < 10) {
      setState((prev) => ({
        ...prev,
        phoneError: "Phone number must be exactly 10 digits",
      }));
      return false;
    }
    setState((prev) => ({
      ...prev,
      phoneError: "",
    }));
    return true;
  };

  // Toggle phone edit mode
  const togglePhoneEdit = () => {
    // If the phone is empty or invalid, show an alert and do not close edit mode
    if (!validatePhone(state.userPhone)) {
      alert("Please enter a valid 10-digit phone number before closing the edit mode.");
      return;
    }
    setState((prev) => ({
      ...prev,
      isPhoneEdit: !prev.isPhoneEdit,
    }));
  };

  // profile code end

  const logoutFromThisDevice = () => {
    sessionStorage.clear();
    navigate('/')
  }

  const confirmLogout = () => {
    setState((prev) => ({
      ...prev,
      showLogoutModal: false
    }));
    logoutService('Session');
  };

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await fetchOnlineStatus(state.restaurantId);
        if (response.status) {
          setState((prev) => ({ ...prev, isOffline: response.status }));
        }
      } catch (error) {
        console.error("Error fetching status:", error);
      }
    };

    fetchStatus();
  }, [state.restaurantId]);

  const handleChange = (event) => {
    setState((prev) => ({
      ...prev,
      newStatus: event.target.value,
      selectedTime: "",
      selectedDate: "",
    }));
  };

  const handleInputChange = (key, value) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const handleUpdateStatus = async () => {
    const now = new Date();
    let payload = {
      restaurantId: state.restaurantId,
      newStatus: state.newStatus,
    };

    if (["Offline", "Busy"].includes(state.newStatus)) {
      payload.nextAvailableAt = `${now.toISOString().split("T")[0]} ${state.selectedTime}:00`;
    } else if (state.newStatus === "TemporarilyClosed") {
      payload.nextAvailableAt = `${state.selectedDate} ${state.selectedTime}:00`;
    } else if (state.isOffline === "TemporarilyClosed" && state.newStatus === "Online") {
      const nextAvailableAt = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(
        now.getDate()
      ).padStart(2, "0")} ${String(now.getHours()).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}:00`;

      payload.nextAvailableAt = nextAvailableAt;
    }

    try {
      await restaurantStatusUpdate(payload);
      alert("Status updated successfully!");
      setState((prev) => ({
        ...prev,
        isOffline: state.newStatus,
        showModal: false,
        newStatus: "",
      }));
    } catch (error) {
      console.error("Error updating status:", error);
      alert("Failed to update status. Please try again.");
    }
  };

  const renderInput = () => {
    if (["Offline", "Busy"].includes(state.newStatus)) {
      return (
        <input
          type="time"
          value={state.selectedTime}
          onChange={(e) => handleInputChange("selectedTime", e.target.value)}
          className="block mt-2 w-64 px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      );
    }

    if (state.newStatus === "TemporarilyClosed") {
      return (
        <div>
          <input
            type="date"
            value={state.selectedDate}
            min={new Date().toISOString().split("T")[0]}
            max={new Date(new Date().setMonth(new Date().getMonth() + 2))
              .toISOString()
              .split("T")[0]}
            onChange={(e) => handleInputChange("selectedDate", e.target.value)}
            className="block mt-2 w-64 px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="time"
            value={state.selectedTime}
            onChange={(e) => handleInputChange("selectedTime", e.target.value)}
            className="block mt-2 w-64 px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      );
    }

    return null;
  };

  const [restaurantData, setRestaurantData] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem("restaurantData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setRestaurantData(parsedData);

      if (parsedData.length === 1) {
        // If only one restaurant exists, auto-select it
        setSelectedRestaurant(parsedData[0]);
        localStorage.setItem("restaurantId", parsedData[0].id);
      } else {
        // Load persisted selection
        const savedRestaurant = localStorage.getItem("selectedRestaurant");
        if (savedRestaurant) {
          setSelectedRestaurant(JSON.parse(savedRestaurant));
        }
      }
    }
  }, []);

  useEffect(() => {
    const storedRestaurant = localStorage.getItem("selectedRestaurant");
    if (storedRestaurant) {
      try {
        setSelectedRestaurant(JSON.parse(storedRestaurant)); // ✅ Convert back to object
      } catch (error) {
        console.error("Error parsing selectedRestaurant:", error);
      }
    }
  }, []);


  const handleRestaurantSelection = (restaurant) => {
    setSelectedRestaurant(restaurant);
    localStorage.setItem("selectedRestaurant", JSON.stringify(restaurant));
    localStorage.setItem("restaurantId", restaurant.id); // Update the restaurant ID
    getPermissions();
    setIsDropdownOpen(false);
    
  };

  const getPermissions = async () => {
    const restaurantId = localStorage.getItem("restaurantId");
    try {
      const data = await fetchMerchantUserPermissions(restaurantId);

      // ✅ Store `manageOrders` separately
      sessionStorage.setItem("manageOrders", JSON.stringify(Boolean(data["manageOrders"])));
console.log('from session',sessionStorage.getItem("manageOrders"));

      const filteredPermissions = Object.keys(data || {}).reduce((acc, key) => {
        acc[key] = Boolean(data[key]);
        return acc;
      }, {});

      sessionStorage.setItem("permissions", JSON.stringify(filteredPermissions));
      console.log("Filtered Permissions:", filteredPermissions);
      navigate('/');
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  return (
    <>
      <header className="bg-white">
        <div className="container mx-auto px-4 py-2 flex items-center justify-between">
          <div className="flex items-center">
            <img className="h-12 md:h-16" src={logo} alt="Logo" />
          </div>
          {restaurantData.length === 1 ? '' :
            <div className="relative border border-green ml-16 rounded-md w-80 mx-auto mt-2">
              <div
                className="border-green px-4 py-2 rounded-md flex justify-between items-center bg-white cursor-pointer shadow-md"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <div className="font-bold flex-1 text-center">
                  {selectedRestaurant ? `${selectedRestaurant.name} - ${selectedRestaurant.location}` : "Select a Restaurant"}
                </div>
                <FaCaretDown className="text-green-500" />
              </div>

              {isDropdownOpen && restaurantData.length > 1 && (
                <div className="absolute top-full mt-1 left-0 w-full bg-white border border-green rounded-md shadow-lg z-10 max-h-60 overflow-y-auto">
                  <ul className="py-2">
                    {restaurantData.map((restaurant) => (
                      <li
                        key={restaurant.id}
                        className="px-4 py-2 cursor-pointer transition duration-200 hover:bg-green hover:text-white"
                        onClick={() => handleRestaurantSelection(restaurant)}
                      >
                        <div className="font-bold">{restaurant.name} - {restaurant.location}</div>
                        <div className="text-sm">Role: {restaurant.role}</div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          }
          <nav className="ml-auto">
            <ul className="flex items-center space-x-4">
              <li className="relative">
                <a href="">
                  <div className="absolute -top-1 right-0 bg-yellow-400 text-xs font-bold px-1 py-0.5 rounded-sm">
                    5
                  </div>
                  <FontAwesomeIcon
                    className="h-5 lg:h-6 p-2 text-black"
                    icon={faBell}
                  />
                </a>
              </li>
              <li className="relative">
                <a href="#">
                  <FontAwesomeIcon
                    className="h-5 lg:h-6 p-2 text-black"
                    icon={faCog}
                  />
                </a>
              </li>

              <li className="relative">
                <div className="flex items-center justify-center my-4">
                  <button
                    className="border bg-green font-bold text-white p-2 rounded"
                    onClick={() => setState((prev) => ({ ...prev, showModal: true }))}
                  >
                    {state.isOffline}
                  </button>
                </div>

                {state.showModal && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 w-full max-w-md">
                      <h2 className="text-lg text-center font-bold mb-4">Update Status</h2>
                      {state.isOffline === "TemporarilyClosed" ? (
                        <select
                          name="status"
                          value={state.newStatus || ""}
                          id="status-select"
                          className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                          onChange={(e) =>
                            setState((prev) => ({ ...prev, newStatus: e.target.value }))
                          }
                        >
                          <option value="" disabled>
                            -- Select Status --
                          </option>
                          <option value="Online">Online</option>
                        </select>
                      ) : (
                        <select
                          value={state.newStatus}
                          onChange={handleChange}
                          className="block w-64 px-3 mx-16 py-2 border border-gray-700 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          <option value="" disabled>
                            -- Select Status --
                          </option>
                          <option value="Offline">Offline</option>
                          <option value="Busy">Busy</option>
                          {state.isSigningAuthority === true && <option value="TemporarilyClosed">Temporarily Closed</option>}
                          {state.isSigningAuthority === true && <option value="PermanentlyClosed">Permanently Closed</option>}
                        </select>
                      )}

                      {renderInput()}

                      <div className="flex items-center justify-evenly mt-4">
                        <button
                          onClick={handleUpdateStatus}
                          disabled={
                            (["Offline", "Busy"].includes(state.newStatus) && !state.selectedTime) ||
                            (state.newStatus === "TemporarilyClosed" &&
                              (!state.selectedDate || !state.selectedTime))
                          }
                          className="border bg-green text-white p-2 rounded disabled:opacity-50"
                        >
                          Update
                        </button>
                        <button
                          className="border bg-gray-400 text-black p-2 rounded"
                          onClick={() => setState((prev) => ({ ...prev, showModal: false, newStatus: "" }))}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </li>

              <li className="relative">
                <div className="user-login inline-block text-left">
                  <button
                    type="button"
                    className={`inline-flex justify-center w-20 md:w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-${state.isOffline ? "white" : "green-600"} text-sm font-medium text-${state.isOffline ? "gray-700" : "white"} hover:bg-${state.isOffline ? "gray-50" : "red-700"} focus:outline-none`}
                    onClick={toggleUserMenu}
                    style={{ backgroundColor: "#2CA721" }} // Background color
                  >
                    <div className="flex items-center">
                      <img
                        src={`${state.userImage || faUser}`}
                        alt="User"
                        className="w-6 h-6 rounded-full object-cover"
                      />
                      <span className="ml-2 text-white">{state.userName}</span>
                    </div>
                  </button>
                  {state.isUserMenuOpen && (
                    <div className="origin-top-right absolute right-0 mt-2 w-72 z-50 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="p-4 text-center">
                        <div className="flex justify-center mb-1">
                          <div className="w-20 h-20 rounded-full border border-gray-300 overflow-hidden relative">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageUpload}
                              className="opacity-0 w-full h-full absolute inset-0 cursor-pointer"
                            />
                            <img
                              src={state.userImage}
                              alt="User"
                              className="w-full h-full object-cover"
                            />
                            <div className="absolute top-2 right-2">
                              {state.showTrashIcon && (
                                <button
                                  onClick={handleDeleteImage}
                                  className="text-blue-500 hover:text-red-700"
                                >
                                  <FontAwesomeIcon icon={faTrash} className="h-4" /> {/* Smaller icon */}
                                </button>
                              )}
                            </div>
                            <div className="absolute bottom-2 right-2">
                              <button
                                onClick={() => document.querySelector("input[type=file]").click()}
                                className="text-blue-500 hover:text-blue-700"
                              >
                                <FontAwesomeIcon icon={faEdit} className="h-4" /> {/* Smaller icon */}
                              </button>
                            </div>
                          </div>
                        </div>
                        {state.imageError && (
                          <p className="text-red text-xs mb-2">{state.imageError}</p>
                        )}
                        <div className="flex flex-col items-center justify-center font-bold text-lg">
                          {state.isNameEdit ? (
                            <>
                              <div className="flex items-center">
                                <input
                                  type="text"
                                  value={state.userName}
                                  onChange={handleNameChange}
                                  ref={nameInputRef} // Set input field reference
                                  maxLength={50} // Limit input to 50 characters
                                  className="text-center focus:outline-none border border-gray-300 mr-2"
                                />
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="text-blue-500 cursor-pointer"
                                  onClick={toggleNameEdit}
                                />
                              </div>
                              {state.nameError && (
                                <p className="text-red text-xs">{state.nameError}</p> // Error message below input
                              )}
                            </>
                          ) : (
                            <div className="flex items-center">
                              <span>{state.userName}</span>
                              <FontAwesomeIcon
                                icon={faEdit}
                                className="text-blue-500 ml-2 cursor-pointer"
                                onClick={toggleNameEdit}
                              />
                            </div>
                          )}
                        </div>
                        <div className="text-gray-500 mb-4 mt-2">
                          {state.isPhoneEdit ? (
                            <>
                              <div className="flex items-center justify-center">
                                <input
                                  type="tel"
                                  value={state.userPhone} // Only show the digits part, prefix is static
                                  onChange={handlePhoneChange}
                                  ref={phoneInputRef} // Focus reference
                                  maxLength={14} // +91- followed by 10 digits
                                  className="text-center text-black font-bold focus:outline-none border border-gray-300 mr-2"
                                />
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="text-blue-500 cursor-pointer"
                                  onClick={togglePhoneEdit}
                                />
                              </div>
                              {state.phoneError && (
                                <p className="text-red text-xs">{state.phoneError}</p>
                              )}
                            </>
                          ) : (
                            <div className="flex items-center justify-center">
                              <span className="font-bold text-black">
                                {state.userPhone.startsWith("+91")
                                  ? `+91 ${state.userPhone.slice(3).trim()}`
                                  : state.userPhone}
                              </span> {/* Combine the +91- prefix and the digits */}
                              <FontAwesomeIcon
                                icon={faEdit}
                                className="text-blue-500 ml-2 cursor-pointer"
                                onClick={togglePhoneEdit}
                              />
                            </div>
                          )}
                        </div>
                        <button className="w-full bg-red text-white py-2 rounded-md mb-2" onClick={confirmLogout}>
                          Logout
                        </button>
                        <button className="w-full border border-red-500 text-red-500 py-2 rounded-md" onClick={logoutFromThisDevice}>
                          Logout from all devices
                        </button>
                        <div className="mt-4 text-gray-500 text-sm">
                          <a href="#" className="hover:underline">
                            Terms of Service
                          </a>{" "}
                          |
                          <a href="#" className="hover:underline">
                            {" "}
                            Privacy Policy
                          </a>{" "}
                          |
                          <a href="#" className="hover:underline">
                            {" "}
                            Code of Conduct
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      {!state.visibleSlides.every((slide) => !slide) && (
        <section className="slider-section py-2 overflow-hidden">
          <div className="container mx-auto">
            <Slider {...settings}>
              {state.visibleSlides.map((visible, index) =>
                visible ? (
                  <div
                    key={index}
                    className="slider1"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <img src={chef} alt="Chef" className="h-28 mr-4" />
                    </div>
                    <div className="content-center">
                      <h3 className="text-white text-lg font-bold">
                        Introducing restaurant services
                      </h3>
                      <p className="text-white text-sm ">
                        Fulfill your staffing FSSAI license and trademark needs
                      </p>
                    </div>
                    <div className="flex items-center">
                      <button className="bg-white text-black px-4 py-2 rounded mr-2">
                        Explore
                      </button>
                      <button
                        onClick={() => handleClose(index)}
                        className="text-white text-2xl mx-8"
                      >
                        &times;
                      </button>
                    </div>
                  </div>
                ) : null
              )}
            </Slider>
          </div>
        </section>
      )}
      <section>
        {state.notificationVisible && (
          <div className="notifi text-white px-4 py-2 flex items-center justify-between bg-red-600">
            <div className="flex-1 text-center">
              <FontAwesomeIcon icon={faBell} className="mr-2" />
              <span className="">
                Please enable notifications for a better experience.{" "}
                <a href="#" className="underline">
                  Click here to know more
                </a>
              </span>
            </div>
            <div className="">
              <button
                onClick={handleCloseNotification}
                className="text-white text-xl mx-8"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Head;
