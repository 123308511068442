import React, { useState, useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Circle, useMapEvents, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-control-geocoder';
import 'leaflet-draw';  // Import leaflet-draw for polygon drawing
import 'leaflet-draw/dist/leaflet.draw.css';  // Import leaflet-draw styles
import { createTargetLocation, deleteTargetLocation, fetchOutletInfo, fetchTargetLocation } from '../API/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

function Location() {
    const [state, setState] = useState({
        isDropdownOpen: false,        // Track dropdown visibility
        selectedOption: '',            // Track selected option
        radius: 1,                     // Default radius in kilometers
        showRadiusInput: false,        // Show input when "Radius" is selected
        isMapModalOpen: false,         // Show map popup modal
        selectedPosition: null,        // Track selected location on the map
        searchInput: '',               // Track search input for the geocoder
        suggestions: [],
        finalRadius: null, // Stores final radius when user clicks "Create"
        finalPosition: null, // Stores final lat/lng when user clicks "Create"
        polygonCoords: [],
        targetLocations: [], // Stores fetched target locations
        restLat: 0.0,
        restLong: 0.0,
        outletName: '',
    });
    const mapRef = useRef();  // Ref for the map instance
    const restaurantId = localStorage.getItem("restaurantId");

    useEffect(() => {
        fetchExistingLocations();
        fetchLatLong();
    }, []);

    const fetchLatLong = async () => {
        try {
            const data = await fetchOutletInfo(restaurantId);
            console.log("Outlet Info", data);
            setState((prev) => ({
                ...prev,
                restLat: data.latitude,
                restLong: data.longitude,
                outletName: data.outletName,
            }))
        } catch (error) {
            throw error;
        }   
    }

    const fetchExistingLocations = async () => {
        try {
            const data = await fetchTargetLocation(restaurantId);
            setState(prevState => ({ ...prevState, targetLocations: data.targetLocations }));
        } catch (error) {
            console.error("Error fetching target locations:", error);
        }
    };

    const handleDeleteLocation = async (targetLocationId) => {
        try {
            const payload = {
                restaurantId, targetLocationId
            }
            await deleteTargetLocation(payload);
            fetchExistingLocations(); // Refresh the list
        } catch (error) {
            console.error("Error deleting target location:", error);
        }
    };

    const handleCreateLocation = async () => {
        if (state.selectedOption === 'Radius' && state.selectedPosition && state.radius) {
            const payload = {
                restaurantId,
                targetLocationAlias: state.outletName,
                locationTargetType: "pointer",
                payload: {
                    latitude: state.selectedPosition[0],
                    longitude: state.selectedPosition[1],
                    offset: state.radius * 1000 // Convert km to meters
                }
            };
            await createTargetLocation(payload);
            setState(prevState => ({
                ...prevState,
                isMapModalOpen: false,
                finalRadius: state.radius,
                finalPosition: state.selectedPosition,
                polygonCoords: []
            }));
        } else if (state.selectedOption === 'Polygon' && state.polygonCoords.length > 0) {
            const payload = {
                restaurantId,
                targetLocationAlias: "Polygon Target",
                locationTargetType: "zone",
                payload: { coordinates: state.polygonCoords }
            };
            await createTargetLocation(payload);
            setState(prevState => ({
                ...prevState,
                isMapModalOpen: false,
                finalRadius: null,
                finalPosition: null
            }));
        }
        fetchExistingLocations();
    };

    // Toggle dropdown open/close
    const toggleDropdown = () => {
        setState(prevState => ({
            ...prevState,
            isDropdownOpen: !prevState.isDropdownOpen
        }));
    };

    // Handle selecting an option
    const handleOptionSelect = (option) => {
        setState(prevState => ({
            ...prevState,
            selectedOption: option,
            isDropdownOpen: false,
            showRadiusInput: option === 'Radius',
            isMapModalOpen: true
        }));
    };

    const handleRadiusChange = (e) => {
        const newRadius = parseFloat(e.target.value);
        if (!isNaN(newRadius) && newRadius > 0) {
            setState(prevState => ({
                ...prevState,
                radius: newRadius
            }));
        }
    };

    // Open Map Modal for location search
    const handleSearchLocation = (option) => {
        setState(prevState => ({
            ...prevState,
            selectedOption: option,
            isDropdownOpen: false,
            showRadiusInput: option === 'Radius',
            isMapModalOpen: true,
            ...(option === 'Polygon' ? { selectedPosition: null, finalRadius: null, finalPosition: null } : {}),
            ...(option === 'Radius' ? { polygonCoords: [] } : {})
        }));
    };
    // Close Map Modal
    const closeMapModal = () => {
        setState(prevState => ({
            ...prevState,
            isMapModalOpen: false,
            polygonCoords: []
        }));
    };

    // const handleDone = () => {
    //     if (state.selectedOption === 'Radius' && state.selectedPosition && state.radius) {
    //         setState(prevState => ({
    //             ...prevState,
    //             isMapModalOpen: false,
    //             finalRadius: state.radius,
    //             finalPosition: state.selectedPosition,
    //             polygonCoords: []
    //         }));
    //     } else if (state.selectedOption === 'Polygon' && state.polygonCoords.length > 0) {
    //         setState(prevState => ({
    //             ...prevState,
    //             isMapModalOpen: false,
    //             finalRadius: null,
    //             finalPosition: null
    //         }));
    //     }
    // };

    // Fetch location suggestions using OpenStreetMap via Leaflet-Control-Geocoder
    const fetchLocationSuggestions = (query) => {
        if (!query) {
            setState(prevState => ({ ...prevState, suggestions: [] }));
            return;
        }

        const geocoder = L.Control.Geocoder.nominatim();
        geocoder.geocode(query, (results) => {
            setState(prevState => ({ ...prevState, suggestions: results }));
        });
    };

    // Handle input change and fetch suggestions
    const handleSearchInputChange = (e) => {
        const query = e.target.value;
        setState(prevState => ({
            ...prevState,
            searchInput: query
        }));
        fetchLocationSuggestions(query);
    };

    // Handle location selection from suggestions
    const handleSuggestionClick = (suggestion) => {
        const { center } = suggestion;
        setState(prevState => ({
            ...prevState,
            selectedPosition: [center.lat, center.lng],
            searchInput: suggestion.name,
            suggestions: []
        }));
    };

    // Add polygon drawing functionality using leaflet-draw
    const addPolygonDrawHandler = () => {
        const map = mapRef.current;
        if (!map) return;

        const drawnItems = new L.FeatureGroup();
        map.addLayer(drawnItems);
        const drawControl = new L.Control.Draw({
            draw: {
                polygon: true,
                marker: false,
                polyline: false,
                rectangle: false,
                circle: false,
                circlemarker: false
            },
            edit: {
                featureGroup: drawnItems
            }
        });
        map.addControl(drawControl);
        map.on(L.Draw.Event.CREATED, (event) => {
            const layer = event.layer;
            const polygonLatLngs = layer.getLatLngs()[0].map((latlng) => [latlng.lat, latlng.lng]);
            setState(prevState => ({
                ...prevState,
                polygonCoords: polygonLatLngs
            }));
            drawnItems.addLayer(layer);
        });
    };

    useEffect(() => {
        if (state.isMapModalOpen && state.selectedOption === 'Polygon') {
            setTimeout(() => {
                addPolygonDrawHandler();
            }, 1000); // Delay to allow modal and map to render before adding controls
        }
    }, [state.isMapModalOpen, state.selectedOption]);

    function LocationMarker() {
        useMapEvents({
            click(e) {
                const { lat, lng } = e.latlng;
                setState(prevState => ({
                    ...prevState,
                    selectedPosition: [lat, lng]
                }));
            },
        });

        return state.selectedPosition ? (
            <Marker position={state.selectedPosition}>
                <Popup>
                    <div>
                        <label>Set Radius (in km): </label>
                        <input
                            type="number"
                            value={state.radius}
                            onChange={handleRadiusChange}
                            style={{ width: '50px', padding: '5px' }}
                        />
                    </div>
                </Popup>
            </Marker>
        ) : null;
    }

    return (
        <div className="relative p-10 px-80 mt-10">
            <button className="bg-green text-white px-4 py-2 rounded-md" onClick={toggleDropdown}>
                Choose Location
            </button>
            {state.isDropdownOpen && (
                <div className="absolute mt-2 w-48 bg-white shadow-lg rounded-md">
                    <ul>
                        <li className="py-2 px-4 hover:bg-gray-200 cursor-pointer" onClick={() => handleOptionSelect('Radius')}>
                            Radius
                        </li>
                        <li className="py-2 px-4 hover:bg-gray-200 cursor-pointer" onClick={() => handleOptionSelect('Polygon')}>
                            Polygon
                        </li>
                    </ul>
                </div>
            )}
            {state.selectedOption && (
                <div className="mt-4 text-gray-700">
                    You have selected: <span className="font-bold">{state.selectedOption}</span>
                </div>
            )}
            {state.showRadiusInput && (
                <div className="mt-4">
                    <div className="mb-4">
                        <label htmlFor="radius" className="block text-gray-700 font-semibold mb-2">
                            Set Radius (in Kilometers):
                        </label>
                        <input
                            type="number"
                            id="radius"
                            className="w-full px-4 py-2 border border-gray-300 rounded-md"
                            placeholder="Enter radius in kilometers"
                            value={state.radius}
                            onChange={handleRadiusChange}
                        />
                    </div>
                    <button className="bg-green text-white px-4 py-2 rounded-md" onClick={handleSearchLocation}>
                        Search Location
                    </button>
                </div>
            )}
            {/* Map Modal */}
            {state.isMapModalOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg w-full max-w-3xl p-6 relative">
                        <h2 className="text-xl font-bold mb-4">Select Location on Map</h2>
                        {/* Search Input above the Map */}
                        <div className="mb-4">
                            <input
                                type="text"
                                className="w-full px-4 py-2 border border-gray-300 rounded-md"
                                placeholder="Search location..."
                                value={state.searchInput}
                                onChange={handleSearchInputChange}
                            />
                            {/* Suggestions List */}
                            {state.suggestions.length > 0 && (
                                <ul className="bg-white border border-gray-300 mt-2 rounded-md max-h-60 overflow-auto shadow-lg">
                                    {state.suggestions.map((suggestion, index) => (
                                        <li
                                            key={index}
                                            className="py-2 px-4 hover:bg-gray-200 cursor-pointer"
                                            onClick={() => handleSuggestionClick(suggestion)}
                                        >
                                            {suggestion.name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        {/* Map Container */}
                        <MapContainer
                            center={[13.0827, 80.2707]} // Initially centered on Chennai
                            zoom={10}
                            scrollWheelZoom={true}
                            style={{ height: '400px', width: '100%' }}
                            ref={mapRef}
                        >
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            {state.selectedOption === 'Radius' && state.selectedPosition && (
                                <>
                                    <Marker position={state.selectedPosition}>
                                        <Popup>
                                            <div>
                                                <label>Set Radius (in km): </label>
                                                <input
                                                    type="number"
                                                    value={state.radius}
                                                    onChange={handleRadiusChange}
                                                    style={{ width: '50px', padding: '5px' }}
                                                />
                                            </div>
                                        </Popup>
                                    </Marker>
                                    <Circle
                                        center={state.selectedPosition}
                                        radius={state.radius * 1000} // Convert km to meters
                                        color="green"
                                    />
                                </>
                            )}
                            {state.selectedOption === 'Radius' && <LocationMarker />}
                        </MapContainer>
                        {/* Action Buttons */}
                        <div className="flex justify-between mt-6">
                            <button className="bg-gray-500 text-white px-4 py-2 rounded-md" onClick={closeMapModal}>
                                Close
                            </button>
                            <button
                                className="bg-green text-white px-4 py-2 rounded-md"
                                onClick={handleCreateLocation}
                                // disabled={(state.selectedOption === 'Radius' && (!state.selectedPosition || !state.radius)) || (state.selectedOption === 'Polygon' && state.polygonCoords.length === 0)}
                                // disabled={!state.selectedPosition || !state.radius}
                                disabled={(state.selectedOption === 'Radius' && (!state.selectedPosition || !state.radius)) ||
                                    (state.selectedOption === 'Polygon' && state.polygonCoords.length === 0)}
                            >
                                Create
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {/* Display Polygon Coordinates */}
            {state.polygonCoords.length > 0 && (
                <div className="mt-6">
                    <h3 className="font-bold">Polygon Coordinates:</h3>
                    <textarea
                        className="w-full p-2 border border-gray-300 rounded-md"
                        rows="5"
                        value={state.polygonCoords.map(coord => `Lat: ${coord[0]}, Lng: ${coord[1]}`).join('\n')}
                        readOnly
                    />
                </div>
            )}
            {state.finalRadius && (
                <div className="mt-6">
                    <h3 className="font-bold">Final Selection:</h3>
                    <p>Latitude: {state.finalPosition?.[0]}</p>
                    <p>Longitude: {state.finalPosition?.[1]}</p>
                    <p>Selected Radius: {state.finalRadius} km</p>
                </div>
            )}
            {/* Display Existing Locations */}
            {state.targetLocations?.length > 0 && (
                <div className="mt-6">
                    <h3 className="font-bold">Existing Target Locations:</h3>
                    <ul className="list-disc pl-1 mt-3">
                        {state.targetLocations.map((location) => (
                            <li key={location.id} className="flex justify-between items-center border p-2">
                                <span>{location.targetLocationAlias}</span>
                                <button
                                    className="text-red-500 text-xl text-red"
                                    onClick={() => handleDeleteLocation(location.id)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}
export default Location;