import React, { useEffect, useRef, useState } from "react";
import "../pages/Orderhistory.css";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import { IoClose } from "react-icons/io5";
import { IoDownloadOutline } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import noshow from '../assets/no-food.webp';
// import { set } from "date-fns/set";
import { fetchOrderHistory } from "../API/Api";

function Orderhistory() {
  const [state, setState] = useState({
    openDate: false,
    date: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
    appliedDateRange: {
      startDate: format(new Date(), "MMM dd yyyy"),
      endDate: format(new Date(), "MMM dd yyyy"),
    },
    isOpen: false,
    activeTab: "Order Status",
    orderStatus: [],
    rating: "",
    searchResult: null,
    restaurantId: localStorage.getItem("restaurantId"),
    orderHistory: [], // Store the fetched order history
    permissions: JSON.parse(localStorage.getItem("permissions")) || {},
  });

  const { permissions } = state;
  const handleChange = (ranges) => {
    setState((prevState) => ({
      ...prevState,
      date: {
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        key: "selection",
      },
    }));
  };

  const applyPreset = (preset) => {
    const now = new Date();
    let startDate, endDate;

    if (preset === "Today") {
      startDate = endDate = new Date();
    } else if (preset === "Yesterday") {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      startDate = endDate = yesterday;
    } else if (preset === "This Week") {
      const firstDayOfWeek = new Date(now);
      firstDayOfWeek.setDate(now.getDate() - now.getDay());
      startDate = firstDayOfWeek;
      endDate = now;
    } else if (preset === "Last Week") {
      const firstDayOfLastWeek = new Date(now);
      firstDayOfLastWeek.setDate(now.getDate() - now.getDay() - 7);
      const lastDayOfLastWeek = new Date(firstDayOfLastWeek);
      lastDayOfLastWeek.setDate(firstDayOfLastWeek.getDate() + 6);
      startDate = firstDayOfLastWeek;
      endDate = lastDayOfLastWeek;
    }

    setState((prevState) => ({
      ...prevState,
      date: {
        startDate,
        endDate,
        key: "selection",
      },
    }));
  };

  const datePickerRef = useRef(null);

  // Close the date picker on mouse out (click outside)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setState((prevState) => ({
          ...prevState,
          openDate: false,
        }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleApplyDateRange = async () => {
    try {
      const { startDate, endDate } = state.date;
      const formattedStartDate = format(startDate, "yyyy-MM-dd");
      const formattedEndDate = format(endDate, "yyyy-MM-dd");

      const history = await fetchOrderHistory({
        restaurantId: state.restaurantId,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        days: 10,
        queryStyle: "byDay",
      });

      setState((prevState) => ({
        ...prevState,
        appliedDateRange: {
          startDate: format(startDate, "MMM dd yyyy"),
          endDate: format(endDate, "MMM dd yyyy"),
        },
        openDate: false,
        // Show only the newly fetched order history
        orderHistory: history.orderHistory || [],
      }));
    } catch (error) {
      console.error("Error fetching order history:", error.message);
    }
  };

  const handleClick = () => {
    setState((prevState) => ({
      ...prevState,
      openDate: !prevState.openDate
    }))
  };

  const togglePopup = () => {
    setState((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      orderStatus: checked
        ? [...prevState.orderStatus, name]
        : prevState.orderStatus.filter((status) => status !== name),
    }));
  };

  const handleOrderTypeChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      orderType: event.target.value,
    }));

  };

  const handleRatingChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      rating: event.target.value,
    }));
  };

  const handleClearAll = () => {
    setState((prevState) => ({
      ...prevState,
      orderStatus: [],
      orderType: "",
      rating: "",
    }));
  };

  const handleApply = () => {
    console.log("Order Status:", state.orderStatus);
    console.log("Order Type:", state.orderType);
    console.log("Rating:", state.rating);
    togglePopup();
  };

  const renderActiveTabContent = () => {
    switch (state.activeTab) {
      case "Order Status":
        return (
          <div>
            {["Preparing", "Ready", "Pickup", "Delivered", "Rejected"].map(
              (status) => (
                <div key={status} className="mt-5 text-xl">
                  <input
                    type="checkbox"
                    id={status}
                    name={status}
                    checked={state.orderStatus.includes(status)}
                    onChange={handleCheckboxChange}
                    className="mr-2"
                  />
                  <label htmlFor={status}>{status}</label>
                </div>
              )
            )}
          </div>
        );
      case "Order Type":
        return (
          <div>
            <div className="mt-5 text-xl">
              <input
                type="radio"
                id="online"
                name="orderType"
                value="Online"
                checked={state.orderType === "Online"}
                onChange={handleOrderTypeChange}
                className="mr-2"
              />
              <label htmlFor="online">Online</label>
            </div>
            <div className="mt-5 text-xl">
              <input
                type="radio"
                id="offline"
                name="orderType"
                value="Offline"
                checked={state.orderType === "Offline"}
                onChange={handleOrderTypeChange}
                className="mr-2"
              />
              <label htmlFor="offline">Offline</label>
            </div>
          </div>
        );
      case "Ratings":
        return (
          <div>
            {[1, 2, 3, 4, 5].map((ratingValue) => (
              <div key={ratingValue} className="mt-5 text-xl">
                <input
                  type="radio"
                  id={`rating-${ratingValue}`}
                  name="rating"
                  value={ratingValue}
                  checked={state.rating === ratingValue.toString()}
                  onChange={handleRatingChange}
                  className="mr-2"
                />
                <label htmlFor={`rating-${ratingValue}`}>
                  {ratingValue} Stars
                </label>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 7) {
      // Allow only numbers and limit input to 6 digits
      setState((prevState) => ({
        ...prevState,
        orderId: value,
      }));
    }
  };

  const handleSearch = async () => {
    try {
      const allHistory = await fetchOrderHistory({
        restaurantId: state.restaurantId, // Ensure this value is not null or undefined
        startDate: "2022-01-01", // Adjust the date range as needed
        endDate: format(new Date(), "yyyy-MM-dd"),
        days: 3650, // Fetching up to 10 years of data
        queryStyle: "byDay",
      });

      if (allHistory && allHistory.orderHistory) {
        const matchedOrders = allHistory.orderHistory.filter(
          (order) => order.orderId === state.orderId
        );
        if (matchedOrders.length > 0) {
          setState((prevState) => ({
            ...prevState,
            searchResult: matchedOrders,
            orderHistory: matchedOrders,
          }));
        } else {
          alert("No orders found with the provided Order ID.");
        }
      } else {
        alert("Failed to fetch order history. Please check the data.");
      }
    } catch (error) {
      console.error("Error fetching order history:", error.message);
      alert("An error occurred while fetching order history. Please try again.");
    }
  };

  const handleDownloadCSV = () => {
    // Check if there is any order history data to export
    if (state.orderHistory.length === 0) {
      alert("No data available to download.");
      return;
    }

    // Prepare CSV headers
    const headers = [
      "Order #",
      "Order ID",
      "User Name",
      "Date",
      "Total Amount",
      "Paid",
      "Status",
      "Type",
      "Discount",
      "Order Value",
    ];

    // Map through the orderHistory to create CSV rows
    const rows = state.orderHistory.map((order, index) => [
      index + 1,
      order.orderId,
      order.userName || "Unknown",
      format(new Date(order.createdAt), "MMM dd yyyy"),
      `Rs.${order.totalAmount}`,
      order.isPaid ? "Yes" : "No",
      order.isFulfilled ? "Fulfilled" : "Pending",
      order.orderType,
      `Rs.${order.discountOffered}`,
      `Rs.${order.orderTotalValue}`,
    ]);

    // Combine headers and rows into a single CSV string
    const csvContent = [
      headers.join(","), // Add the headers as the first row
      ...rows.map((row) => row.join(",")), // Add each row as a new line
    ].join("\n");

    // Create a Blob object with the CSV data
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a download link and trigger the download
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Order_History.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <>
      <div className="">
        <div className="flex justify-between items-center mb-4 border-b-2 p-3 border-gray-300 shadow-md">
          <h3 className="text-xl font-bold">Order History</h3>
          <div className="flex space-x-4 items-center">
            <div className="relative">
              <span onClick={handleClick} className="cursor-pointer calender flex items-center">
                {`${state.appliedDateRange.startDate} to ${state.appliedDateRange.endDate}`}
                <MdOutlineArrowDropDown className="text-xl ml-1" />
              </span>
               {state.openDate && (
                <div ref={datePickerRef} className="absolute top-full mt-2 z-10">
                  <DateRangePicker
                    ranges={[state.date]}
                    onChange={handleChange}
                    showSelectionPreview={true}
                  />
                  <div className="flex justify-end p-4">
                    <button
                      className="bg-green text-white py-2 px-4 rounded"
                      onClick={handleApplyDateRange}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              )}
            </div>

            <button
              onClick={togglePopup}
              className="bg-green hover:bg-green-700 text-white text-lg h-12 w-40 rounded"
            >
              Filter
            </button>
            {state.isOpen && (
              <div className="fixed inset-0 bg-gray-600 top-0 left-0 right-0 bottom-0 bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-6 rounded shadow-lg relative">
                  <button
                    onClick={togglePopup}
                    className="text-red-500 absolute top-4 right-4"
                  >
                    <IoClose className="text-xl" />
                  </button>
                  <div className="flex">
                    <div className="bg-gray-100 p-4">
                      <button
                        onClick={() => setState((prevState) => ({ ...prevState, activeTab: "Order Status" }))}
                        className={`w-full text-left text-xl mb-2 ${state.activeTab === "Order Status"
                          ? "font-bold"
                          : "text-green"
                          }`}
                      >
                        Order Status
                      </button>
                      <button
                        onClick={() => setState((prevState) => ({ ...prevState, activeTab: "Order Type" }))}
                        className={`w-full text-left text-xl mb-2 ${state.activeTab === "Order Type"
                          ? "font-bold"
                          : "text-green"
                          }`}
                      >
                        Order Type
                      </button>
                      <button
                        onClick={() => setState((prevState) => ({ ...prevState, activeTab: "Rating" }))}
                        className={`w-full text-xl text-left ${state.activeTab === "Ratings"
                          ? "font-bold"
                          : "text-green"
                          }`}
                      >
                        Ratings
                      </button>
                    </div>
                    <div className="flex-grow p-4">
                      {renderActiveTabContent()}
                      <div className="mt-auto flex justify-between pt-4">
                        <button
                          onClick={handleClearAll}
                          className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                        >
                          Clear All
                        </button>
                        <button
                          onClick={handleApply}
                          className="bg-green text-white px-4 py-2 rounded"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <button onClick={handleDownloadCSV} className="bg-green hover:bg-green-700 text-white text-lg h-12 w-40 rounded flex items-center justify-center">
              <IoDownloadOutline className="text-2xl mr-2" /> Download CSV
            </button>
          </div>
        </div>

        <div className="mb-4 p-2">
          <div className="flex items-center border border-gray-300 rounded-md overflow-hidden w-2/5">
            <input
              type="text"
              value={state.orderId}
              onChange={handleInputChange}
              className="px-4 py-2 flex-grow focus:outline-none"
              placeholder="Search Order ID (7 digits)"
            />
            <button
              onClick={handleSearch}
              className="bg-green hover:bg-green-700 text-white px-4 py-2 flex items-center justify-center focus:outline-none"
            >
              <FaSearch className="mr-2" /> Search
            </button>
          </div>
          <div className="mt-8">
            {state.orderHistory.length > 0 ? (
              <table className="table-auto w-full border-collapse border border-gray-300">
                <thead>
                  <tr>
                    <th className="border border-gray-300 px-4 py-2">Order #</th>
                    <th className="border border-gray-300 px-4 py-2">Order ID</th>
                    <th className="border border-gray-300 px-4 py-2">User Name</th>
                    <th className="border border-gray-300 px-4 py-2">Date</th>
                    <th className="border border-gray-300 px-4 py-2">Total Amount</th>
                    <th className="border border-gray-300 px-4 py-2">Paid</th>
                    <th className="border border-gray-300 px-4 py-2">Status</th>
                    <th className="border border-gray-300 px-4 py-2">Type</th>
                    <th className="border border-gray-300 px-4 py-2">Discount</th>
                    <th className="border border-gray-300 px-4 py-2">Order Value</th>
                  </tr>
                </thead>
                <tbody>
                  {state.orderHistory.map((order, index) => (
                    <tr key={order.orderId} className={index % 2 === 0 ? "bg-gray-100" : ""}>
                      <td className="border border-gray-300 px-4 py-2">{index + 1}</td>
                      <td className="border border-gray-300 px-4 py-2">{order.orderId}</td>
                      <td className="border border-gray-300 px-4 py-2">{order.userName || "Unknown"}</td>
                      <td className="border border-gray-300 px-4 py-2">{format(new Date(order.createdAt), "MMM dd yyyy")}</td>
                      <td className="border border-gray-300 px-4 py-2">₹{order.totalAmount}</td>
                      <td className="border border-gray-300 px-4 py-2">{order.isPaid ? "Yes" : "No"}</td>
                      <td className="border border-gray-300 px-4 py-2">{order.isFulfilled ? "Fulfilled" : "Pending"}</td>
                      <td className="border border-gray-300 px-4 py-2">{order.orderType}</td>
                      <td className="border border-gray-300 px-4 py-2">₹{order.discountOffered}</td>
                      <td className="border border-gray-300 px-4 py-2">₹{order.orderTotalValue}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>
                <div className="text-red flex justify-center mt-8">
                  <img src={noshow} alt="no-food" className="w-56 h-56" />
                </div>
                <p className="text-red flex justify-center mt-3 text-lg">No orders to show</p>
              </div>
            )}
          </div>

        </div>
      </div>
    </>
  );
}

export default Orderhistory;