import React, { useState, useEffect } from "react";
import { FaCaretDown } from "react-icons/fa";
import { fetchMerchantUserPermissions } from "../API/Api";
import { useNavigate } from "react-router-dom";

const MultipleRestaurants = ({ onSelect = () => { } }) => {
  const navigate = useNavigate();
  const [restaurantData, setRestaurantData] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem("restaurantData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setRestaurantData(parsedData);
    }
  }, []);

  const handleRestaurantSelection = (restaurant) => {
    if (typeof onSelect !== "function") {
      console.error("❌ Error: onSelect is not defined or not a function.");
      return;
    }
    setSelectedRestaurant(restaurant);
    // ✅ Store restaurant as an object in localStorage
    localStorage.setItem("selectedRestaurant", JSON.stringify(restaurant));
    localStorage.setItem("restaurantId", restaurant.id);
    if (restaurant) {
      getPermissions();
      setIsDropdownOpen(false);
      onSelect(restaurant); // Navigate after selection
    }
  };

  const getPermissions = async () => {
    const restaurantId = localStorage.getItem("restaurantId");
    try {
      const data = await fetchMerchantUserPermissions(restaurantId);

      console.log("🔹 Full API Response:", data); // ✅ Log full API response

      if (data.hasOwnProperty("manageOrders")) {
        console.log("🔹 Type of manageOrders:", typeof data.manageOrders, " | Value:", data.manageOrders);
      } else {
        console.log("❌ manageOrders key NOT found in API response");
      }

      // ✅ Store `manageOrders` separately
      sessionStorage.setItem("manageOrders", JSON.stringify(Boolean(data["manageOrders"])));

      const filteredPermissions = Object.keys(data || {}).reduce((acc, key) => {
        acc[key] = Boolean(data[key]);
        return acc;
      }, {});

      console.log("✅ Filtered Permissions Before Storing:", filteredPermissions);

      sessionStorage.setItem("permissions", JSON.stringify(filteredPermissions));

      console.log("✅ Stored Permissions in SessionStorage:", sessionStorage.getItem("permissions"));

      navigate('/');
    } catch (error) {
      console.error("❌ Error fetching permissions:", error);
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100 p-5">
      <div className="bg-white w-full max-w-3xl shadow-md rounded-lg p-4 mt-4 text-center">
        <p className="text-gray-700">
          You are associated with multiple restaurants. Please select one to continue.
        </p>
      </div>
      {restaurantData.length > 1 && (
        <div className="relative border border-green rounded-md w-80 mx-auto mt-4">
          <div
            className="border-green px-4 py-2 rounded-md flex justify-between items-center bg-white cursor-pointer shadow-md"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <div className="font-bold flex-1 text-center">
              {selectedRestaurant
                ? `${selectedRestaurant.name} - ${selectedRestaurant.location}`
                : "Select a Restaurant"}
            </div>
            <FaCaretDown className="text-green-500" />
          </div>

          {isDropdownOpen && (
            <div className="absolute top-full mt-1 left-0 w-full bg-white border border-green rounded-md shadow-lg z-10 max-h-60 overflow-y-auto">
              <ul className="py-2">
                {restaurantData.map((restaurant) => (
                  <li
                    key={restaurant.id}
                    className="px-4 py-2 cursor-pointer hover:bg-green hover:text-white"
                    onClick={() => handleRestaurantSelection(restaurant)}
                  >
                    <div className="font-bold">
                      {restaurant.name} - {restaurant.location}
                    </div>
                    <div className="text-sm">Role: {restaurant.role}</div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MultipleRestaurants;
