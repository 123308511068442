// import { useState, useEffect, useCallback, useRef } from "react";
// import { io } from "socket.io-client";

// export function useWebSocket() {
//   const [isConnected, setIsConnected] = useState(false);
//   const socketRef = useRef(null); // Use useRef to keep a single socket instance

//   useEffect(() => {
//     const token = localStorage.getItem("accessToken");
//     const restaurantId = localStorage.getItem("restaurantId");

//     // Function to get the latest base URL from localStorage
//     const getBaseUrl = () => {
//       const isSecure = process.env.REACT_APP_API_ENDPOINT_IS_SECURE ?? 'true';
//       const apiEndPoint = process.env.REACT_APP_API_ENDPOINT ?? "api.thegenie.in";
//       const endpoint = localStorage.getItem("endpoint") ?? "api";
//       if (isSecure === 'true') {
//         return `https://${apiEndPoint}/${endpoint}`;
//       } else {
//         return `http://${apiEndPoint}/${endpoint}`;
//       }
//     };

//     if (token && restaurantId) {
//       if (socketRef.current) {
//         socketRef.current.disconnect();
//         console.log("🛑 Previous WebSocket Disconnected");
//       }

//       socketRef.current = io(`${getBaseUrl}/sockets/restaurants`, {
//         query: { restaurantId },
//         transports: ["websocket"],
//         auth: { authorization: token },
//         reconnectionAttempts: 5,
//         reconnectionDelay: 3000,
//       });

//       socketRef.current.on("connect", () => {
//         setIsConnected(true);
//         console.log("✅ WebSocket Connected");
//       });

//       socketRef.current.on("disconnect", (reason) => {
//         setIsConnected(false);
//         console.log("❌ WebSocket Disconnected:", reason);
//       });

//       socketRef.current.on("connect_error", (error) => {
//         console.error("⚠️ WebSocket Connection Error:", error);
//       });

//       return () => {
//         if (socketRef.current) {
//           socketRef.current.disconnect();
//           console.log("🛑 Cleanup: WebSocket Disconnected");
//         }
//       };
//     }
//   }, []);

//   const connectWebSocket = useCallback(() => {
//     if (socketRef.current) {
//       socketRef.current.connect();
//       console.log("🔗 Connecting to WebSocket...");
//     }
//   }, []);

//   const disconnectWebSocket = useCallback(() => {
//     if (socketRef.current) {
//       socketRef.current.disconnect();
//       console.log("🛑 WebSocket Disconnected Manually");
//     }
//   }, []);

//   return { socket: socketRef.current, connectWebSocket, disconnectWebSocket, isConnected };
// }




import { useState, useEffect, useCallback, useRef } from "react";
import { io } from "socket.io-client";

export function useWebSocket() {
  const [isConnected, setIsConnected] = useState(false);
  const socketRef = useRef(null); // Keep a persistent socket instance
  const reconnectAttemptsRef = useRef(0);

  const getBaseUrl = () => {
    const isSecure = process.env.REACT_APP_API_ENDPOINT_IS_SECURE ?? 'true';
    const apiEndPoint = process.env.REACT_APP_API_ENDPOINT ?? "api.thegenie.in";
    const endpoint = localStorage.getItem("endpoint") ?? "api";
    return isSecure === 'true' ? `https://${apiEndPoint}/${endpoint}` : `http://${apiEndPoint}/${endpoint}`;
  };

  const connectWebSocket = useCallback(() => {
    const token = localStorage.getItem("accessToken");
    const restaurantId = localStorage.getItem("restaurantId");

    if (!token || !restaurantId) {
      console.warn("⚠️ Missing token or restaurantId, skipping WebSocket connection.");
      return;
    }

    if (socketRef.current) {
      socketRef.current.disconnect();
      console.log("🛑 Closing previous WebSocket connection...");
    }

    console.log("🔗 Attempting to connect WebSocket...");
    socketRef.current = io(`${getBaseUrl()}/sockets/restaurants`, {
      query: { restaurantId },
      transports: ["websocket"],
      auth: { authorization: token },
      reconnection: true,
      reconnectionAttempts: Infinity, // Try reconnecting indefinitely
      reconnectionDelay: 3000, // Wait 3 seconds before retrying
    });

    socketRef.current.on("connect", () => {
      setIsConnected(true);
      reconnectAttemptsRef.current = 0; // Reset attempts on success
      console.log("✅ WebSocket Connected");
    });

    socketRef.current.on("disconnect", (reason) => {
      setIsConnected(false);
      console.warn("❌ WebSocket Disconnected:", reason);
    });

    socketRef.current.on("connect_error", (error) => {
      console.error("⚠️ WebSocket Connection Error:", error);
      reconnectAttemptsRef.current++;
      if (reconnectAttemptsRef.current > 5) {
        console.warn("🚨 Too many reconnection attempts, stopping...");
      }
    });
  }, []);

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        console.log("🛑 Cleanup: WebSocket Disconnected");
      }
    };
  }, [connectWebSocket]);

  return { socket: socketRef.current, connectWebSocket, isConnected };
}
