// services/api.js
import axios from "axios";
import { logoutService } from "./LogoutService";
import { refreshTokenAPI } from "./AuthApi";
import { io } from "socket.io-client";

// Function to get the latest base URL from localStorage
const getBaseUrl = () => {
  const isSecure = process.env.REACT_APP_API_ENDPOINT_IS_SECURE ?? 'true';
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT ?? "api.thegenie.in";
  const endpoint = localStorage.getItem("endpoint") ?? "api";
  if(isSecure === 'true') {
  return `https://${apiEndPoint}/${endpoint}`;
  } else {
    return `http://${apiEndPoint}/${endpoint}`;
  }
};


// Create Axios instance with a dynamic base URL
const ApiService = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});

// Function to update the base URL when the endpoint changes
export const updateApiBaseUrl = () => {
  ApiService.defaults.baseURL = getBaseUrl();
  console.log("Updated ApiService baseURL:", ApiService.defaults.baseURL);
};

// Helper function to parse the expiry time string and return a Date object
const parseExpiryTime = (expiryString) => {
  return new Date(expiryString.replace(" ", "T"));
};

let currentTimeIntervalId;
let tokenRefreshIntervalId;

// Update current time in localStorage every second
currentTimeIntervalId = setInterval(() => {
  const now = new Date();
  localStorage.setItem("currentTime", now.getTime().toString()); // Store the timestamp in milliseconds
}, 1000);

// Background function to refresh token periodically
const startTokenRefreshTimer = () => {
  const interval = 60000; // 1 minute interval for checking

  tokenRefreshIntervalId = setInterval(async () => {
    const accessTokenExpiry = localStorage.getItem("accessTokenExpiry");
    const refreshToken = localStorage.getItem("refreshToken");
    const refreshTokenExpiry = localStorage.getItem("refreshTokenExpiry");

    const accessTokenExpiryDate = accessTokenExpiry
      ? parseExpiryTime(accessTokenExpiry)
      : null;
    const refreshTokenExpiryDate = refreshTokenExpiry
      ? parseExpiryTime(refreshTokenExpiry)
      : null;

    const now = new Date(parseInt(localStorage.getItem("currentTime")));

    if (accessTokenExpiryDate && accessTokenExpiryDate - now < 65000) {
      // If access token is about to expire
      if (refreshToken && refreshTokenExpiryDate > now) {
        try {
          const response = await refreshTokenAPI(refreshToken);
          if (response && response.accessToken) {
            localStorage.setItem("accessToken", response.accessToken);
            localStorage.setItem("refreshToken", response.refreshToken);
            localStorage.setItem(
              "accessTokenExpiry",
              response.accessTokenExpiry
            );
            localStorage.setItem(
              "refreshTokenExpiry",
              response.refreshTokenExpiry
            );

            // Update ApiService default header with new access token
            ApiService.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${response.accessToken}`;

            console.log("New Access Token set", response.accessToken);

            // Stop all intervals after successful token refresh
            clearInterval(currentTimeIntervalId);
            clearInterval(tokenRefreshIntervalId);
          } else {
            logoutService("Session");
          }
        } catch (error) {
          logoutService("Session");
        }
      } else {
        // Refresh token expired
        logoutService("Session");
      }
    }
  }, interval);
};

// Initialize background refresh timer
startTokenRefreshTimer();

// Axios interceptor to set Authorization header
ApiService.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const restaurantId = localStorage.getItem("restaurantId");

export const fetchMenuItems = async () => {
  try {
    const response = await ApiService.post("restaurants/basic_details", {
      restaurantId: restaurantId,
      mode: "Delivery",
      userLat: 12.892296478812135,
      userLng: 77.64241154926422,
      role: "restaurant_admin",
      user: localStorage.getItem("userId"),
      context: "res_context",
    });
    console.log("API Response:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Error in fetchMenuItems:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const getPredefinedCampaigns = async (restaurantId) => {
  try {
    const response = await ApiService.get("campaign/predefined-campaigns", {
      params: { restaurantId: restaurantId },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching predefined campaigns:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const getCampaignPermissions = async () => {
  try {
    const response = await ApiService.get("campaign/campaign-permissions", {
      params: { restaurantId: restaurantId },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching campaign permissions:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const createCampaign = async (payload) => {
  try {
    const response = await ApiService.post("campaign/create", payload);
    return response.data;
  } catch (error) {
    console.error(
      "Error creating campaign:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Log-out Service

export const logout = async (logoutType) => {
  try {
    const customBaseUrl = `${process.env.REACT_APP_API_ENDPOINT}/api`;
    const response = await axios.post(`${customBaseUrl}/auth/logout`, {
      logoutType,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Define the function to fetch merchant user permissions
export const fetchMerchantUserPermissions = async (restaurantId) => {
  try {
    console.log("Rest Id", restaurantId);

    // Make the GET request using ApiService
    const response = await ApiService.get(
      `/restaurants/permissions?restaurantId=${restaurantId}`
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      const { statusCode, message } = error.response.data;
      throw new Error(`${message} (Status Code: ${statusCode})`);
    } else {
      throw new Error("An error occurred while fetching the permissions.");
    }
  }
};

// ----------------------- User Management API's Start ---------------------------

// List Portal Users
export const listPortalUsers = async (restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/merchant-user", {
      params: { restaurantId: restaurantId },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error listing portal users:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// List Custom Roles
export const listCustomRoles = async (restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/custom-roles", {
      params: { restaurantId: restaurantId },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error listing custom roles:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Create Custom Role
export const createCustomRole = async (payload) => {
  try {
    const response = await ApiService.post(
      "/restaurants/custom-roles",
      payload
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error creating custom role:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Delete Custom Role
export const deleteCustomRole = async (payload) => {
  try {
    const response = await ApiService.delete("/restaurants/custom-roles", {
      data: payload,
    });
    return response;
  } catch (error) {
    console.error(
      "Error deleting custom role:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// List Predefined Roles
export const listPredefinedRoles = async (restaurantId) => {
  try {
    const response = await ApiService.get(
      "/restaurants/list-predefined-roles",
      {
        params: { restaurantId: restaurantId },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error listing predefined roles:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// List Customizable Permissions
export const listCustomisablePermissions = async (restaurantId) => {
  try {
    const response = await ApiService.get(
      "/restaurants/list-customisable-permission",
      {
        params: { restaurantId: restaurantId },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error listing customizable permissions:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Associate User
export const associateUser = async (payload) => {
  try {
    const response = await ApiService.post(
      "/restaurants/merchant-user",
      payload
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error associating user:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Remove User
export const removeUser = async (payload) => {
  try {
    const response = await ApiService.delete("/restaurants/merchant-user", {
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error removing user:",
      error.response?.data || error.message
    );
    throw error.response.data.message.message;
  }
};

// Re-associate User
export const reAssociateUser = async (payload) => {
  try {
    const response = await ApiService.patch(
      "/restaurants/merchant-user",
      payload
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error re-associating user:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Create Custom Role
export const getUserDetails = async (payload) => {
  try {
    const response = await ApiService.get(
      "/customers/manage-customer-data",
      payload
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error creating custom role:",
      error.response?.data || error.message
    );
    throw error;
  }
};

//  ------------------------ User Management API's End ---------------------

// Fetch Order History
export const fetchOrderHistory = async ({
  restaurantId,
  startDate,
  endDate,
  date,
  days = 10,
  queryStyle = "byDay",
}) => {
  try {
    const response = await ApiService.get(
      `/restaurants/dashboard/fetch-order-history`,
      {
        params: {
          restaurantId: restaurantId,
          startDate,
          endDate,
          date,
          days,
          queryStyle,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching order history:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Function to fetch outlet info
export const fetchOutletInfo = async (restaurantId) => {
  try {
    const response = await ApiService.get(
      `/restaurants/dashboard/fetch-outlet-info?restaurantId=${restaurantId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching outlet info:", error);
    throw error;
  }
};

// Menu Items

export const createMenuItem = async (payload) => {
  try {
    const response = await ApiService.post(
      "/restaurants/menu-item-ops",
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error in createOrUpdateMenuItem API:", error);
    throw error;
  }
};

export const menuCategoriesCreate = async (payload) => {
  try {
    const response = await ApiService.post(
      "/restaurants/menu-categories",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const menuCategoriesUpdate = async (payload) => {
  try {
    const response = await ApiService.patch(
      `/restaurants/menu-categories`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const menuCategoriesDelete = async (payload) => {
  try {
    const response = await ApiService.delete(`/restaurants/menu-categories`, {
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const menuSubCategoriesCreate = async (payload) => {
  try {
    const response = await ApiService.post(
      `/restaurants/menu-sub-categories`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const menuSubCategoriesUpdate = async (payload) => {
  try {
    const response = await ApiService.patch(
      `/restaurants/menu-sub-categories`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const menuSubCategoriesDelete = async (payload) => {
  try {
    const response = await ApiService.delete(
      `/restaurants/menu-sub-categories`,
      {
        data: payload,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const menuItemsCreate = async (payload) => {
  try {
    const response = await ApiService.post(
      `/restaurants/menu-item-ops`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const menuItemsUpdate = async (payload) => {
  try {
    const response = await ApiService.patch(
      `/restaurants/menu-item-ops`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const menuItemsDelete = async (payload) => {
  try {
    const response = await ApiService.delete(`/restaurants/menu-item-ops`, {
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchMenuDetails = async (restaurantId) => {
  try {
    const response = await ApiService.get(
      `/restaurants/dashboard/fetch-menu-details`,
      {
        params: {
          restaurantId: restaurantId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const approveChanges = async (restaurantId) => {
  try {
    const response = await ApiService.post(`/restaurants/approve-changes`, {
      restaurantId: restaurantId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// New API's

// Fetch Payout Cycle Information
export const fetchPayoutCycleInformation = (restaurantId, offset, limit) => {
  return ApiService.get("/restaurants/dashboard/fetch-payouts", {
    params: { restaurantId, offset, limit },
  });
};

// Fetch UTR Reports
export const fetchUTRReports = (restaurantId, offset, limit) => {
  return ApiService.get("/restaurants/dashboard/fetch-utr-reports", {
    params: { restaurantId, offset, limit },
  });
};

// Fetch Invoice Categories
export const fetchInvoiceCategories = (restaurantId) => {
  return ApiService.get("/restaurants/dashboard/fetch-invoice-categories", {
    params: { restaurantId },
  });
};

// Fetch Invoices
export const fetchInvoices = (restaurantId, invoiceCategory, offset, limit) => {
  return ApiService.get("/restaurants/dashboard/fetch-invoices", {
    params: { restaurantId, invoiceCategory, offset, limit },
  });
};

// Fetch TDS Documents
export const fetchTDSDocuments = (restaurantId, offset, limit) => {
  return ApiService.get("/restaurants/dashboard/fetch-tds-documents", {
    params: { restaurantId, offset, limit },
  });
};

// Learning Center Videos

export const fetchLearningCenterVideos = async (language, restaurantId) => {
  try {
    const response = await ApiService.get(
      "/restaurants/dashboard/learning-center-videos",
      {
        params: {
          language,
          restaurantId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching learning center videos:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Online/Offline Status Update and Fetch

export const fetchOnlineStatus = async (restaurantId) => {
  try {
    const response = await ApiService.get(
      `/restaurants/dashboard/restaurant-status`,
      {
        params: { restaurantId },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching Restaurant Status:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const restaurantStatusUpdate = async (payload) => {
  try {
    const response = await ApiService.patch(
      `/restaurants/dashboard/restaurant-status`,
      payload
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error Updating Restaurant Status:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Target Location set
export const createTargetLocation = async (payload) => {
  try {
    const response = await ApiService.post(
      `/location/target-location`,
      payload
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error Creating Target Location:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const fetchTargetLocation = async (restaurantId) => {
  try {
    const response = await ApiService.get(`/location/target-location`, {
      params: { restaurantId: restaurantId },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error Fetching Target Location:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const deleteTargetLocation = async (payload) => {
  try {
    const response = await ApiService.delete(`/location/target-location`, {
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error Deleting Target Location:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const fetchHashtags = async (restaurantId) => {
  try {
    const response = await ApiService.get(`/menu-items/tags`, {
      params: {
        restaurantId: restaurantId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Campaign Start & Stop & Campaign Insights
export const getCampaignInsights = async (restaurantId) => {
  try {
    const response = await ApiService.get(`/campaign/insights`, 
      {
      params: { restaurantId: restaurantId },
    }
  );
    return response.data;
  } catch (error) {
    console.error(
      "Error Fetching Target Location:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Start Campaign
export const startCampaign = async (payload) => {
  try {
    const response = await ApiService.post(
      `/campaign/insights/start-campaign`,
      payload
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error Creating Target Location:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Stop Campaign
export const stopCampaign = async (payload) => {
  try {
    const response = await ApiService.post(
      `/campaign/insights/stop-campaign`,
      payload
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error Creating Target Location:",
      error.response?.data || error.message
    );
    throw error;
  }
};
