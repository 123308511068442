import React, { useEffect, useState } from "react";
import { fetchMerchantUserPermissions, listPortalUsers } from "../API/Api";
import logo from "../assets/logo1.webp"; // Import the custom logo
import { useWebSocket } from "../utils/useWebSocket";

function OrderMangement() {
  const [state, setState] = useState({
    errorMessage: "",
    restaurantId: localStorage.getItem("restaurantId"),
    accessToken: localStorage.getItem("accessToken"),
    manageOrders: sessionStorage.getItem("manageOrders"),
  });

  // State for online/offline toggle
  const [isOnline, setIsOnline] = useState(true);

  // State for WebSocket messages
  const [messages, setMessages] = useState([]);

  // Using the custom WebSocket hook
  const { connectWebSocket, disconnectWebSocket, isConnected, socket } = useWebSocket();

  useEffect(() => {
    if (isConnected) {
      console.log("WebSocket is connected. Listening to channels...");
      listenToChannel("order-created-channel", (message) => {
        console.log("📩 Received message from order-created-channel:", message);
        setMessages((prevMessages) => [...prevMessages, { channel: "order-created-channel", message }]);
      });

      listenToChannel("order-accepted-channel", (message) => {
        console.log("📩 Received message from order-accepted-channel:", message);
        setMessages((prevMessages) => [...prevMessages, { channel: "order-accepted-channel", message }]);
      });

      listenToChannel("order-ready-for-pickup-channel", (message) => {
        console.log("📩 Received message from order-ready-for-pickup-channel:", message);
        setMessages((prevMessages) => [...prevMessages, { channel: "order-ready-for-pickup-channel", message }]);
      });
    }
  }, [isConnected]);

  // Function to listen to WebSocket channels
  const listenToChannel = (channelName, callback) => {
    // This should come from your custom hook or WebSocket utility
    console.log(`Listening to channel: ${channelName}`);
    if (socket) {
      socket.on(channelName, (message) => {
        callback(message);
      });
    }
  };

  // Function to send messages to the WebSocket
  const sendMessageToChannel = (channelName, messageData) => {
    if (socket && isConnected) {
      socket.emit(channelName, messageData);
      console.log(`📤 Sent message to ${channelName}:`, messageData);
    } else {
      console.error("❌ Socket is not connected, cannot send message.");
    }
  };

  // const alarmSound = new Audio("/sounds/alarm.mp3"); // Local path in your project

  // State for active tab
  const [activeTab, setActiveTab] = useState("running");

  // State for orders and popup
  const [orders, setOrders] = useState([]); // Orders list
  const [popupOrder, setPopupOrder] = useState(null); // Popup for new order
  const [orderReadyTime, setOrderReadyTime] = useState(10); // Default ready time

  // Mock data for running, completed, and dispatched orders
  const runningOrders = [
    {
      customerName: "Bhuvanesan S",
      orderNumber: "#12345",
      orderId: "ORD001",
      preparationTimeLeft: "15:30",
      pickedUpOrders: "2",
    },
    {
      customerName: "Koushik Raj",
      orderNumber: "#12346",
      orderId: "ORD002",
      preparationTimeLeft: "10:20",
      pickedUpOrders: "1",
    },
  ];

  const completedOrders = [
    { pickedUpOrders: "3", details: "Completed on 2024-11-20" },
    { pickedUpOrders: "5", details: "Completed on 2024-11-19" },
  ];

  const dispatchedOrders = [
    { deliveredOrders: "4", details: "Delivered on 2024-11-18" },
    { deliveredOrders: "2", details: "Delivered on 2024-11-17" },
  ];

  // Simulate new order arrival
  useEffect(() => {
    const timer = setTimeout(() => {
      const newOrder = {
        id: orders.length + 1,
        itemName: "Pizza Margherita",
        orderValue: 599,
        packagingCharge: 50,
      };
      setOrders((prevOrders) => [...prevOrders, newOrder]);
      playAlarm(); // Play alarm when the order arrives
      setPopupOrder(newOrder); // Open the popup for the new order
    }, 5000); // Order arrives in 5 seconds
    return () => clearTimeout(timer);
  }, [orders]);

  // // Play alarm sound
  // const playAlarm = () => {
  //   alarmSound.play();
  // };

  // Close popup
  const closePopup = () => {
    setPopupOrder(null);
  };

  // Increment ready time
  const incrementReadyTime = () => {
    setOrderReadyTime((prevTime) => prevTime + 1);
  };

  // Decrement ready time
  const decrementReadyTime = () => {
    if (orderReadyTime > 1) {
      setOrderReadyTime((prevTime) => prevTime - 1);
    }
  };

  const alarmSound = new Audio(
    "https://www.soundjay.com/button/sounds/button-09.mp3" // Replace with your working sound URL
  );
  alarmSound.load(); // Preload the audio

  const playAlarm = () => {
    if (alarmSound.canPlayType("audio/mpeg")) {
      // alarmSound.play().catch((error) => console.error("Audio playback failed:", error));
    } else {
      console.error("Audio format not supported in this browser.");
    }
  };

  //accept order code

  const [preparationTime, setPreparationTime] = useState(10);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isDeclineModalVisible, setIsDeclineModalVisible] = useState(false);

  const orderDetails = {
    items: [
      {
        name: "Chicken Dominator Pizza",
        variant: "Regular",
        addOn: "Extra Cheese",
        qty: 2,
        price: 569,
      },
      {
        name: "Chicken Dominator Pizza",
        variant: "Regular",
        addOn: "Chicken Sauce",
        qty: 1,
        price: 569,
      },
      {
        name: "Veggie Paradise Pizza",
        variant: "Medium",
        addOn: "Chicken Tikka",
        qty: 3,
        price: 399,
      },
      {
        name: "Paneer Tikka Pizza",
        variant: "Large",
        addOn: "Chicken Sauce",
        qty: 1,
        price: 499,
      },
      {
        name: "Garlic Breadsticks",
        variant: "Regular",
        addOn: "Red Pepper",
        qty: 2,
        price: 199,
      },
      {
        name: "Choco Lava Cake",
        variant: "Single",
        addOn: "Grilled Mushrooms",
        qty: 4,
        price: 99,
      },
      {
        name: "Choco Lava Cake",
        variant: "Single",
        addOn: "Grilled Mushrooms",
        qty: 4,
        price: 99,
      },
    ],
    customer: {
      orderCount: "18th Order From Your Restaurant",
      name: "Bhuvanesan S",
      location: "Hosa Road",
    },
    campaign: { name: "Pongal Special", discount: 129 },
    notes:
      "I need a Spicy Chicken Biryani, Andhra Style with Extra Raitha and Curd and Sweet Popcorn, Biscuits and with 10 Eggs I need",
  };

  const calculateTotalPrice = () => {
    const itemsTotal = orderDetails.items.reduce(
      (sum, item) => sum + item.qty * item.price,
      0
    );
    return itemsTotal - orderDetails.campaign.discount;
  };

  const incrementTime = () => setPreparationTime(preparationTime + 1);
  const decrementTime = () => {
    if (preparationTime > 1) setPreparationTime(preparationTime - 1);
  };

  const handleAccept = () => {
    alert("Order Accepted!");
    setIsPopupVisible(false);
  };

  const handleDecline = () => {
    setIsDeclineModalVisible(true);
  };

  const confirmDecline = () => {
    alert("Order Declined!");
    setIsDeclineModalVisible(false);
    setIsPopupVisible(false);
  };

  const rowColors = [
    "bg-gray-100",
    "bg-white-100",
  ];

  // ----------------- multiple restaurnats code------------------------

  const restaurantData = [
    {
      id: 1,
      name: "Genies Cafe",
      location: "Bangalore",
      role: "Owner",
    },
    {
      id: 2,
      name: "Madurai Mess",
      location: "Chennai",
      role: "Manager",
    },
    {
      id: 3,
      name: "Meghna Briyani",
      location: "Coimbatore",
      role: "Regional Manager",
    },
    {
      id: 4,
      name: "Suvai Mess",
      location: "Madurai",
      role: "Assistant Manager",
    },
    {
      id: 5,
      name: "Gourmet Palace",
      location: "Gokarna",
      role: "Owner",
    },
  ];

  // Handle when new order is created
  const handleOrderCreated = (data) => {
    console.log("🚀 Handling new order:", data);
    setOrders((prevOrders) => [...prevOrders, data]);
    playAlarm();
    setPopupOrder(data);
    setIsPopupVisible(true);
  };

  // Handle when order is accepted
  const handleOrderAccepted = (data) => {
    console.log("🚀 Handling order accepted:", data);
    // Add your logic here if needed
  };

  // Handle when order is ready for pickup
  const handleOrderReadyForPickup = (data) => {
    console.log("🚀 Handling order ready for pickup:", data);
    // Add your logic here if needed
  };


  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [isMultiplePopupVisible, setMultipleIsPopupVisible] = useState(false);

  // Load selected restaurant from localStorage on component mount
  useEffect(() => {
    const savedRestaurant = localStorage.getItem("selectedRestaurant");
    if (savedRestaurant) {
      setSelectedRestaurant(JSON.parse(savedRestaurant));
    }
  }, []);

  // Handle restaurant selection and persist to localStorage
  const handleRestaurantSelection = (restaurant) => {
    setSelectedRestaurant(restaurant);
    localStorage.setItem("selectedRestaurant", JSON.stringify(restaurant)); // Persist selection
    setMultipleIsPopupVisible(false); // Close the popup after selecting a restaurant
  };

  return (
    <div className="container mx-auto p-6">
      {/* Online/Offline Toggle */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-700">
          Order Management
        </h1>
        <button
          className={`px-4 py-2 rounded ${isOnline ? "bg-green text-white" : "bg-red text-white"
            }`}
          onClick={() => setIsOnline(!isOnline)}
        >
          {isOnline ? "Online Since" : "Offline Since"}
        </button>
      </div>

      {/* Tabs */}
      <div className="tabs mb-4 border-b border-gray-200">
        <button
          className={`px-4 py-2 ${activeTab === "running" ? "border-b-2 border-green" : ""
            }`}
          onClick={() => setActiveTab("running")}
        >
          Running Orders
        </button>
        <button
          className={`px-4 py-2 ${activeTab === "completed" ? "border-b-2 border-green" : ""
            }`}
          onClick={() => setActiveTab("completed")}
        >
          Picked Up Orders
        </button>
        <button
          className={`px-4 py-2 ${activeTab === "dispatched" ? "border-b-2 border-green" : ""
            }`}
          onClick={() => setActiveTab("dispatched")}
        >
          Delivered Orders
        </button>
        <button
          className={`px-4 py-2 ${activeTab === "accepted" ? "border-b-2 border-green" : ""
            }`}
          onClick={() => setActiveTab("accepted")}
        >
          Accept Order
        </button>
        <button
          className={`px-4 py-2 ${activeTab === "accepted" ? "border-b-2 border-green" : ""
            }`}
          onClick={() => setActiveTab("multiple")}
        >
          Multiple Restaurnats
        </button>
      </div>

      {/* Tab Content */}
      <div>
        {activeTab === "running" && (
          <div>
            <h2 className="text-xl font-medium text-gray-700 mb-4">
              Running Orders
            </h2>
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 border-b">Customer Name</th>
                  <th className="px-4 py-2 border-b">Order Number</th>
                  <th className="px-4 py-2 border-b">Order ID</th>
                  <th className="px-4 py-2 border-b">Preparation Time Left</th>
                  <th className="px-4 py-2 border-b">Picked Up Orders</th>
                </tr>
              </thead>
              <tbody>
                {runningOrders.map((order, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2 border-b">{order.customerName}</td>
                    <td className="px-4 py-2 border-b">{order.orderNumber}</td>
                    <td className="px-4 py-2 border-b">{order.orderId}</td>
                    <td className="px-4 py-2 border-b">
                      {order.preparationTimeLeft}
                    </td>
                    <td className="px-4 py-2 border-b">
                      {order.pickedUpOrders}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {activeTab === "completed" && (
          <div>
            <h2 className="text-xl font-medium text-gray-700 mb-4">
              Picked Up Orders
            </h2>
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 border-b">Picked Up Orders</th>
                  <th className="px-4 py-2 border-b">Details</th>
                </tr>
              </thead>
              <tbody>
                {completedOrders.map((order, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2 border-b">
                      {order.pickedUpOrders}
                    </td>
                    <td className="px-4 py-2 border-b">{order.details}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {activeTab === "dispatched" && (
          <div>
            <h2 className="text-xl font-medium text-gray-700 mb-4">
              Delivered Orders
            </h2>
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 border-b">Delivered Orders</th>
                  <th className="px-4 py-2 border-b">Details</th>
                </tr>
              </thead>
              <tbody>
                {dispatchedOrders.map((order, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2 border-b">
                      {order.deliveredOrders}
                    </td>
                    <td className="px-4 py-2 border-b">{order.details}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {activeTab === "accepted" && (
          <div>
            <button
              className="bg-green ml-10 mt-5 text-white rounded-lg w-32 h-10 justify-center items-center"
              onClick={() => setIsPopupVisible(true)}
            >
              Order Accept
            </button>
          </div>
        )}

        {activeTab === "multiple" && (
          <div>
            <button
              className="bg-green ml-10 mt-5 text-white rounded-lg w-56 h-10 justify-center items-center"
              onClick={() => setMultipleIsPopupVisible(true)}
            >
              Multiple Restaurants
            </button>
          </div>
        )}

        {isPopupVisible && (
          <div className="fixed inset-0 flex justify-center items-center bg-gray-100">
            <div className="bg-white rounded-lg shadow-lg p-2 border border-green w-[1300px] h-[630px] relative">
              <h1 className="text-center text-2xl font-bold">New Order</h1>
              <div className="flex justify-between gap-3">
                <div className="w-2/3 rounded-md">
                  <h2 className="text-xl font-bold mb-1">Order Description</h2>

                  <div className="border border-green p-4 rounded-md relative">
                    <div className="flex justify-between items-center border-b pb-2 bg-white sticky top-0 z-10">
                      <h3 className="font-bold text-lg w-2/5">Item Details</h3>
                      <h3 className="font-bold text-lg w-1/5 text-center">
                        Quantity
                      </h3>
                      <h3 className="font-bold text-lg w-1/5 text-center">
                        Price
                      </h3>
                    </div>

                    <div
                      className={
                        orderDetails.items.length > 5
                          ? "h-60 overflow-y-auto"
                          : ""
                      }
                    >
                      <ul className="mt-2">
                        {orderDetails.items.map((item, index) => (
                          <li
                            key={index}
                            className={`flex justify-between items-center py-2 px-2 ${rowColors[index % rowColors.length]
                              }`}
                          >
                            <div className="w-2/5">
                              <span className="font-bold">{item.name}</span>
                              <p className="text-sm text-gray-700">
                                {item.variant}
                              </p>
                              <p className="text-base font-bold text-gray-700">
                                Add-on:
                              </p>{" "}
                              <p className="text-sm">{item.addOn}</p>
                            </div>
                            <span className="w-1/5 text-center">
                              {item.qty}
                            </span>
                            <span className="w-1/5 text-center">
                              ₹{item.price}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="flex justify-end mr-10 items-end p-2 rounded-md mt-2">
                    <h3 className="font-bold justify-end flex mr-3 text-lg mb-2">
                      Order Total:
                    </h3>
                    <div className="border border-green p-2 rounded-md">
                      <p className="text-xl font-bold">
                        ₹{calculateTotalPrice()}/-
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-1/3 mt-5">
                  <h3 className="text-xl font-bold mb-1">Customer Details</h3>
                  <div className="border border-green p-4 rounded-md mb-4">
                    <p>{orderDetails.customer.orderCount}</p>
                    <p className="font-semibold">
                      Order Person Name:{" "}
                      <span>{orderDetails.customer.name}</span>
                    </p>
                    <p className="font-semibold">
                      Order Location: {orderDetails.customer.location}
                    </p>
                  </div>
                  <h3 className="text-xl font-semibold mb-1">Campaign</h3>
                  <div className="border border-green p-4 rounded-md">
                    <p>
                      <strong>Name:</strong>{" "}
                      <span className="border border-green rounded-md text-center p-1 px-5">
                        {orderDetails.campaign.name}
                      </span>
                    </p>
                    <p className="mt-3">
                      <strong>
                        Discount Applied:{" "}
                        <span className="border border-green rounded-md text-center gap-3 p-1 px-5">
                          {" "}
                          ₹{orderDetails.campaign.discount}/-
                        </span>
                      </strong>
                    </p>
                  </div>
                </div>
              </div>

              <h3 className="text-xl font-bold ml-20 mb-2">Order Notes:</h3>
              <p className="border p-2 rounded-md ml-20">
                {orderDetails.notes}
              </p>

              {/* Preparation Time Section */}
              <div className="flex justify-between items-center mt-5">
                <div className="ml-20 flex">
                  <h3 className="text-xl font-semibold mt-3">
                    Preparation Time{" "}
                  </h3>

                  <div className="flex items-center border-1 border-green rounded-md mt-2 px-1">
                    {/* Decrement Button (-) */}
                    <div
                      className="bg-green  text-white w-16 h-10 flex items-center justify-center rounded-l-md cursor-pointer"
                      onClick={decrementTime}
                    >
                      <span className="text-4xl font-bold mb-2">-</span>
                    </div>

                    {/* Preparation Time Display */}
                    <div className="bg-white text-black w-24 h-10 border-green flex items-center justify-center border-t-2 border-b-2">
                      <span className="text-xl">{preparationTime} Mins</span>
                    </div>

                    {/* Increment Button (+) */}
                    <div
                      className="bg-green font-bold rounded-r-md text-white w-16 h-10 flex items-center justify-center cursor-pointer"
                      onClick={incrementTime}
                    >
                      <span className="text-4xl font-bold mb-2">+</span>
                    </div>
                  </div>
                </div>

                {/* Accept & Decline Buttons */}
                <div className="flex gap-4 mr-10">
                  <button
                    className="bg-red text-white px-6 py-2 rounded-md"
                    onClick={handleDecline}
                  >
                    Decline
                  </button>
                  <button
                    className="bg-green text-white px-6 py-2 rounded-md"
                    onClick={handleAccept}
                  >
                    Accept
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Decline Confirmation Modal */}
        {isDeclineModalVisible && (
          <div className="fixed inset-0 bg-gray-800 border border-green bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded-lg shadow-lg border border-green p-6 max-w-sm text-center">
              <h2 className="text-lg font-semibold mb-4">
                Are you sure you want to decline this order?
              </h2>
              <div className="flex justify-around">
                <button
                  className="bg-green text-white px-4 py-2 rounded"
                  onClick={() => setIsDeclineModalVisible(false)}
                >
                  No
                </button>
                <button
                  className="bg-red text-white px-4 py-2 rounded"
                  onClick={confirmDecline}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Restaurant Selection Popup */}
        {isMultiplePopupVisible && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white w-full max-w-md rounded-lg shadow-lg p-6 relative">
              {/* Close Button */}
              <img src={logo} alt="logo" className="w-40 h-16 mx-auto" />
              <button
                className="absolute top-3 right-3 text-gray-600 hover:text-gray-900 text-xl"
                onClick={() => setMultipleIsPopupVisible(false)}
              >
                &times;
              </button>

              {/* Popup Header */}
              <div className="text-center mb-4">
                <h2 className="text-xl font-semibold mt-8 text-gray-800">
                  You are associated with multiple restaurants, please select one
                </h2>
                <p className="text-gray-600">Choose one to continue</p>
              </div>

              {/* Restaurant Selection Cards (Stacked Vertically) */}
              <div className="flex flex-col space-y-2">
                {restaurantData.map((restaurant) => (
                  <div
                    key={restaurant.id}
                    className={`bg-white rounded-lg shadow-md cursor-pointer transform transition-all duration-300 p-4 w-full flex justify-between items-center
                    ${selectedRestaurant?.id === restaurant.id
                        ? "border-2 border-green-500 scale-105"
                        : "border border-gray-300"
                      }
                  `}
                    onClick={() => handleRestaurantSelection(restaurant)}
                  >
                    <div>
                      <h3 className="text-lg font-semibold text-gray-800">
                        {restaurant.name} - {restaurant.location}
                      </h3>
                      <p className="text-gray-600 text-sm">Role: {restaurant.role}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* WebSocket Messages Display */}
      <div>
        <h2>WebSocket Messages:</h2>
        <ul>
          {messages.map((msg, index) => (
            <li key={index}>
              <strong>{msg.channel}:</strong> {JSON.stringify(msg.message)}
            </li>
          ))}
        </ul>
      </div>

      {/* Send Message Form */}
      <div>
        <button onClick={() => sendMessageToChannel("order-created-channel", { orderId: "ORD123", status: "Created" })}>
          Send Order Created Message
        </button>
      </div>

    </div>
  );
}

export default OrderMangement;