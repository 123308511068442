class MenuItemDetails {
    constructor() {
      if (!MenuItemDetails.instance) {
        this.menuData = {}; // Store menu data in { key: [array of objects] } format
        MenuItemDetails.instance = this;
      }
      return MenuItemDetails.instance;
    }
  
    // CREATE: Store API Response
    createMenuData(key, data) {
      if (!this.menuData[key]) {
        this.menuData[key] = data;
        console.log(`✅ Menu Data Created for key: ${key}`);
      } else {
        console.warn(`⚠️ Menu Data already exists for key: ${key}`);
      }
    }
  
    // FETCH: Retrieve Menu Data
    getMenuData(key) {
      return this.menuData[key] || null;
    }
  
    // UPDATE: Modify Existing Menu Data
    updateMenuData(key, newData) {
      if (this.menuData[key]) {
        this.menuData[key] = newData;
        console.log(`✅ Menu Data Updated for key: ${key}`);
      } else {
        console.warn(`⚠️ No existing menu data found for key: ${key}`);
      }
    }
  
    // DELETE: Remove Specific Menu Data
    deleteMenuData(key) {
      if (this.menuData[key]) {
        delete this.menuData[key];
        console.log(`✅ Menu Data Deleted for key: ${key}`);
      } else {
        console.warn(`⚠️ No menu data found for key: ${key}`);
      }
    }
  }
  
  // Create a Singleton Instance
  const menuItemDetails = new MenuItemDetails();
  Object.freeze(menuItemDetails); // Ensure it remains a singleton
  
  export default menuItemDetails;  