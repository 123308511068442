import React, { useEffect, useState } from 'react';
import { fetchMenuDetails, menuCategoriesCreate, menuCategoriesUpdate, menuCategoriesDelete, menuSubCategoriesCreate, menuSubCategoriesUpdate, menuSubCategoriesDelete, approveChanges, fetchHashtags } from '../../API/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';

import { useLocation, useNavigate } from 'react-router-dom';
import ToggleSwitch from './ToggleSwitch';
import { FaTimes } from 'react-icons/fa';
// import Nested from './Nested';
import MenuDetails from './MenuDetails';
import placeHolder from '../../assets/placeholderimages.png'
import menuItemDetails from '../menuItemDetails';

export function DeliveryMenu({ setMenuDataForApi, setDeletedItems, onItemClick }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuData, setMenuData] = useState([]);
  // const { setSelectedMenuItem } = useMenu();
  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const [activeSubCategoryId, setActiveSubCategoryId] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState(new Set());
  const [expandedSubCategories, setExpandedSubCategories] = useState(new Set());
  const [expandedMenuItems, setExpandedMenuItems] = useState(new Set());
  const [expandedOptionGroups, setExpandedOptionGroups] = useState(new Set());
  const [expandedOptions, setExpandedOptions] = useState(new Set());
  const [expandedVariantGroups, setExpandedVariantGroups] = useState(new Set());
  const [expandedVariants, setExpandedVariants] = useState(new Set());
  const [expandedAddonGroups, setExpandedAddonGroups] = useState(new Set());
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newSubCategoryName, setNewSubCategoryName] = useState('');
  const [selectedCategories, setSelectedCategories] = useState(new Set());
  const [selectedSubCategories, setSelectedSubCategories] = useState(new Set());
  const restaurantId = localStorage.getItem('restaurantId');
  const [toggleStates, setToggleStates] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);


  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    getHashTags();
  })

  const getHashTags = async () => {
    try {
      const response = await fetchHashtags(restaurantId);
      console.log(response);
    } catch (error) {
      throw error;
    }
  }
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);
  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const data = await fetchMenuDetails(restaurantId);
        setMenuData(Array.isArray(data.menuCategories) ? data.menuCategories : []);
        console.log("Menu Data:", data.menuCategories);
        navigate(location.pathname, { state: data });
      } catch (error) {
        console.error("Error fetching menu details:", error.response?.data || error.message);
      }
    };
    fetchMenu();
  }, [navigate, location.pathname]);

  // const handleCategoryClick = (categoryId) => {
  //   setActiveCategoryId(activeCategoryId === categoryId ? null : categoryId);
  //   setExpandedCategories((prev) => {
  //     const newSet = new Set(prev);
  //     newSet.has(categoryId) ? newSet.delete(categoryId) : newSet.add(categoryId);
  //     return newSet;
  //   });
  // };

  // const handleSubCategoryClick = (subCategoryId) => {
  //   setActiveSubCategoryId(activeSubCategoryId === subCategoryId ? null : subCategoryId);
  //   setExpandedSubCategories((prev) => {
  //     const newSet = new Set(prev);
  //     newSet.has(subCategoryId) ? newSet.delete(subCategoryId) : newSet.add(subCategoryId);
  //     return newSet;
  //   });
  // };

  const toggleExpand = (id, type) => {
    let setter;
    switch (type) {
      case 'category':
        setter = setExpandedCategories;
        break;
      case 'subcategory':
        setter = setExpandedSubCategories;
        break;
      case 'menuItem':
        setter = setExpandedMenuItems;
        break;
      case 'optionGroup':
        setter = setExpandedOptionGroups;
        break;
      case 'option':
        setter = setExpandedOptions;
        break;
      case 'variantGroup':
        setter = setExpandedVariantGroups;
        break;
      case 'variant':
        setter = setExpandedVariants;
        break;
      case 'addonGroup':
        setter = setExpandedAddonGroups;
        break;
      default:
        return;
    }
    setter((prev) => {
      const newSet = new Set(prev);
      newSet.has(id) ? newSet.delete(id) : newSet.add(id);
      return newSet;
    });
  };

  const handleAddCategory = async () => {
    if (!newCategoryName.trim()) return; // Exit if the input is empty
    try {
      const payload = {
        categories: [
          {
            restaurantId, // Include the restaurant ID
            categoryData: {
              name: newCategoryName.trim(), // Trim any extra spaces
              sortOrder: menuData.length + 1, // Calculate sort order dynamically
              description: '', // Optional description (set as empty for now)
            },
          },
        ],
      };
      await menuCategoriesCreate(payload);
      await approveChanges(restaurantId);
      const updatedMenu = await fetchMenuDetails(restaurantId);
      setMenuData(Array.isArray(updatedMenu.menuCategories) ? updatedMenu.menuCategories : []); // Update the menu data state
      setNewCategoryName(''); // Clear the input field
    } catch (error) {
      console.error('Error creating category:', error.response?.data || error.message);
    }
  };

  const handleUpdateCategory = async (categoryId, updatedName) => {
    try {
      const payload =
      {
        categories: [
          {
            restaurantId,
            menuCategoryId: categoryId,
            categoryData: {
              description: "",
              name: updatedName,
            }
          }
        ]
      }
      await menuCategoriesUpdate(payload);
      await approveChanges(restaurantId);
      const updatedCategories = menuData.map((category) =>
        category.id === categoryId ? { ...category, name: updatedName } : category
      );
      setMenuData(Array.isArray(updatedCategories.menuCategories) ? updatedCategories.menuCategories : []);
    } catch (error) {
      console.error('Error updating category:', error.response?.data || error.message);
    }
  };

  const handleAddSubCategory = async (activeCategoryId) => {
    if (!newSubCategoryName.trim() || !activeCategoryId) return;

    // // Find the active category and count its subcategories
    // const activeCategory = menuData.find((category) => category.id === activeCategoryId);
    // const currentSubCategoryCount = activeCategory?.MenuSubCategory?.length || 0;

    try {
      const payload = {
        subCategories:
          [
            {
              restaurantId,
              menuCategoryId: activeCategoryId,
              subCategoryData:
              {
                name: newSubCategoryName,
                // sortOrder: currentSubCategoryCount + 1, // Increment based on current count
              }
            }
          ]
      }
      await menuSubCategoriesCreate(payload);
      console.log("menusub");

      await approveChanges(restaurantId);
      const updatedMenuData = await fetchMenuDetails(restaurantId);
      setMenuData(Array.isArray(updatedMenuData.menuCategories) ? updatedMenuData.menuCategories : []);
      setNewSubCategoryName('');
    } catch (error) {
      console.error('Error creating subcategory:', error.response?.data || error.message);
    }
  };

  const handleUpdateSubCategory = async (subCategoryId, categoryId, updatedName) => {
    try {
      const payload = {
        subCategories: [
          {
            restaurantId,
            menuSubCategoryId: subCategoryId,
            data: {
              menuCategoryId: categoryId,
              description: "",
              name: updatedName,
            }
          }
        ]
      }
      await menuSubCategoriesUpdate(payload);
      await approveChanges(restaurantId);
      const updatedMenuData = await fetchMenuDetails(restaurantId);
      setMenuData(Array.isArray(updatedMenuData.menuCategories) ? updatedMenuData.menuCategories : []);
    } catch (error) {
      console.error('Error updating subcategory:', error.response?.data || error.message);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      const payload = {
        restaurantId,
        menuCategoryIds: [categoryId],
      };
      console.log(payload);

      await menuCategoriesDelete(payload);
      // await approveChanges(restaurantId);
      const updatedCategories = await fetchMenuDetails(restaurantId);
      setMenuData(Array.isArray(updatedCategories.menuCategories) ? updatedCategories.menuCategories : []);
    } catch (error) {
      console.error('Error deleting category:', error.response?.data || error.message);
    }
  };

  const handleDeleteSubCategory = async (subCategoryId) => {
    try {
      const payload = {
        restaurantId,
        menuSubCategoryIds: [subCategoryId],
      };
      await menuSubCategoriesDelete(payload);
      // await approveChanges(restaurantId);
      const updatedCategories = await fetchMenuDetails(restaurantId)
      setMenuData(Array.isArray(updatedCategories.menuCategories) ? updatedCategories.menuCategories : []);
    } catch (error) {
      console.error('Error deleting subcategory:', error.response?.data || error.message);
    }
  };

  const handleAddItemClick = (subCategoryId) => {
    if (!subCategoryId) {
      console.error("Invalid subCategoryId:", subCategoryId);
      return;
    }
    // Store subCategoryId in localStorage
    localStorage.setItem("subCategoryId", subCategoryId);
    console.log("Navigating to /menuhead?steps=MenuDetails&step1=1", subCategoryId);
    // setSelectedMenuItem(menuItem); // Store selected item before navigating
    navigate(`/menuhead?steps=MenuDetails&step1=1&subCategoryId=${subCategoryId}`);  // Ensure proper navigation
  };

  const handleUpdateMenuItem = (subCategoryId) => {
    if (!subCategoryId) {
      console.error("Invalid subCategoryId:", subCategoryId);
      return;
    }
    // Store subCategoryId in localStorage
    localStorage.setItem("subCategoryId", subCategoryId);
    console.log("Navigating to /menuhead?steps=MenuDetails&step1=1", subCategoryId);
    // setSelectedMenuItem(menuItem); // Store selected item before navigating
    navigate(`/menuhead?steps=MenuDetails&step1=1&subCategoryId=${subCategoryId}`);  // Ensure proper navigation
  };

  // const handleDeleteMenuItem = async (subCategoryId) => {
  //   try {
  //     const payload = {
  //       restaurantId,
  //       menuSubCategoryIds: [subCategoryId],
  //     };
  //     await menuSubCategoriesDelete(payload);
  //     // await approveChanges(restaurantId);
  //     const updatedCategories = await fetchMenuDetails(restaurantId)
  //     setMenuData(Array.isArray(updatedCategories.menuCategories) ? updatedCategories.menuCategories : []);
  //   } catch (error) {
  //     console.error('Error deleting subcategory:', error.response?.data || error.message);
  //   }
  // };

  const handleToggle = (itemId) => {
    setToggleStates((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
    if (!toggleStates[itemId]) {
      setSelectedItem(itemId);
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const handleMouseEnter = (itemId) => {
    setHoveredItemId(itemId);
  };

  const handleMouseLeave = () => {
    setHoveredItemId(null);
  };

  return (
    <div className="flex flex-col">
      <div className="items-center my-2 mx-3">
        {Array.isArray(menuData) && menuData && menuData.map((category) => (
          <div key={category.id} className="border-b border-gray-200">
            <div className="flex items-center p-3 hover:bg-gray-100">
              <button
                onClick={() => toggleExpand(category.id, 'category')}
                className="text-left flex items-center py-2 px-1 w-full gap-4"
              >
                {expandedCategories.has(category.id) ? (
                  <FontAwesomeIcon icon={faCaretDown} className="text-2xl" />
                ) : (
                  <FontAwesomeIcon icon={faCaretRight} className="text-2xl" />
                )}
                {category.name}
              </button>
              <div className="ml-2 flex items-center gap-4">
                <button
                  onClick={() => handleUpdateCategory(category.id, prompt('Enter new category name', category.name))}
                  className="text-blue-500"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button onClick={() => handleDeleteCategory(category.id)} className="text-red">
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
            {expandedCategories.has(category.id) && (
              <div className="ml-4">
                {category.MenuSubCategory.map((subCategory) => (
                  <div key={subCategory.id}>
                    <div className="flex items-center p-3 hover:bg-gray-100">
                      <button
                        onClick={() => toggleExpand(subCategory.id, 'subcategory')}
                        className="text-left flex items-center py-2 w-full gap-4"
                      >
                        {expandedSubCategories.has(subCategory.id) ? (
                          <FontAwesomeIcon icon={faCaretDown} className="text-2xl" />
                        ) : (
                          <FontAwesomeIcon icon={faCaretRight} className="text-2xl" />
                        )}
                        {subCategory.name || 'Unnamed Subcategory'}
                      </button>

                      <div className="ml-2 flex items-center gap-4">
                        <button
                          onClick={() =>
                            handleUpdateSubCategory(
                              subCategory.id,
                              category.id,
                              prompt('Enter new subcategory name', subCategory.name)
                            )
                          }
                          className="text-blue-500"
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button onClick={() => handleDeleteSubCategory(subCategory.id)} className="text-red">
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </div>
                    </div>
                    {expandedSubCategories.has(subCategory.id) && (
                      <div className="ml-4">
                        {subCategory.MenuItemSubCategoryLookup.map((itemLookup) => {
                          const menuItem = itemLookup.MenuItem; // Extract the MenuItem object
                          const activeRevision = menuItem?.MenuItemRevision?.find(
                            (revision) => revision.isActiveRevision
                          ); // Get the active revision

                          if (!activeRevision) return null; // Skip if no active revision

                          return (
                            <div key={menuItem.id} className="p-2 border-b" onMouseEnter={() => handleMouseEnter(menuItem.id)}
                              onMouseLeave={handleMouseLeave}>
                              <div className="flex justify-between items-center">
                                <button onClick={() => toggleExpand(menuItem.id, 'menuItem')} className="flex items-center w-full gap-4 py-2">
                                  <FontAwesomeIcon icon={expandedMenuItems.has(menuItem.id) ? faCaretDown : faCaretRight} className="text-2xl" />
                                  <div className='flex items-center'>
                                    <img
                                      src={activeRevision.imageUrl || placeHolder}
                                      alt={activeRevision.name}
                                      className="mt-2 w-10 h-10 object-cover rounded-md"
                                    />
                                    <span className="text-base mx-5 font-medium">{activeRevision.name}</span>
                                  </div>
                                </button>
                                <div> <td className="p-3">
                                  {hoveredItemId === itemLookup.id && (
                                    <ToggleSwitch
                                      isOn={toggleStates[itemLookup.id] || false}
                                      onToggle={() => handleToggle(itemLookup.id)}
                                    />
                                  )}
                                </td></div>
                                <div className="flex items-center gap-4">
                                  <span className="text-green-600 font-bold">
                                    ₹{activeRevision.basePrice}
                                  </span>
                                  <button
                                    // onClick={() => handleEditItem(menuItem.id)} // Edit functionality
                                    // onClick={handleUpdateMenuItem(subCategory.id)}
                                    className="text-blue-500 mx-2"
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </button>
                                  <button
                                    // onClick={() => handleDeleteItem(menuItem.id)} // Delete functionality
                                    className="text-red"
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                </div>
                              </div>
                              {/* {itemLookup.MenuItem?.MenuItemRevision?.map((revision) => (
                                <div key={revision.id}> */}
                              {expandedMenuItems.has(menuItem.id) && activeRevision.MenuItemOptionsGroup?.map((optionGroup) => (
                                <div key={optionGroup.id} className="ml-8 border-l pl-4">
                                  <button onClick={() => toggleExpand(optionGroup.id, 'optionGroup')} className="flex items-center w-full gap-4 py-2">
                                    <FontAwesomeIcon icon={expandedOptionGroups.has(optionGroup.id) ? faCaretDown : faCaretRight} className="text-2xl" />
                                    {optionGroup.optionGroupName}
                                  </button>
                                  {expandedOptionGroups.has(optionGroup.id) && optionGroup.MenuItemOption.map((option) => (
                                    <div key={option.id} className="ml-10 border-l pl-4">
                                      <div className='flex items-center'>
                                        <button onClick={() => toggleExpand(option.id, 'option')} className="flex items-center w-full gap-4 py-2">
                                          <FontAwesomeIcon icon={expandedOptions.has(option.id) ? faCaretDown : faCaretRight} className="text-2xl" />
                                          <img src={option.optionImageUrl || placeHolder} className="mt-2 w-10 h-10 object-cover rounded-md" alt="" />
                                          {option.name}
                                        </button>
                                        <div className="flex items-center gap-4">
                                          <span className="text-green-600 font-bold">
                                            ₹{option.additionalPrice}
                                          </span>
                                        </div>
                                      </div>
                                      {expandedOptions.has(option.id) && option.MenuItemVariantGroup?.map((variantGroup) => (
                                        <div key={variantGroup.id} className="ml-12 border-l pl-4">
                                          <button onClick={() => toggleExpand(variantGroup.id, 'variantGroup')} className="flex items-center w-full gap-4 py-2">
                                            <FontAwesomeIcon icon={expandedVariantGroups.has(variantGroup.id) ? faCaretDown : faCaretRight} className="text-2xl" />
                                            {variantGroup.variantGroupName}
                                          </button>
                                          {expandedVariantGroups.has(variantGroup.id) && variantGroup.MenuItemVariantItem?.map((variant) => (
                                            <div key={variant.id} className="ml-14 border-l pl-4">
                                              <div className='flex items-center'>
                                                <button onClick={() => toggleExpand(variant.id, 'variant')} className="flex items-center w-full gap-4 py-2">
                                                  <FontAwesomeIcon icon={expandedVariants.has(variant.id) ? faCaretDown : faCaretRight} className="text-2xl" />
                                                  <img src={variant.variantImageUrl || placeHolder} className="mt-2 w-10 h-10 object-cover rounded-md" alt="" />
                                                  {variant.variantName}
                                                </button>
                                                <div className="flex items-center gap-4">
                                                  <span className="text-green-600 font-bold">
                                                    ₹{variant.additionalPrice}
                                                  </span>
                                                </div>
                                              </div>
                                              {expandedVariants.has(variant.id) && variant.MenuItemAddonsGroup?.map((addOnGroup) => (
                                                <div key={addOnGroup.id} className="ml-16 border-l pl-4">
                                                  <button onClick={() => toggleExpand(addOnGroup.id, 'addonGroup')} className="flex items-center w-full gap-4 py-2">
                                                    <FontAwesomeIcon icon={expandedAddonGroups.has(addOnGroup.id) ? faCaretDown : faCaretRight} className="text-2xl" />
                                                    {addOnGroup.addOnGroupName}
                                                  </button>
                                                  {expandedAddonGroups.has(addOnGroup.id) && addOnGroup.MenuItemAddOnsItem?.map((addOn) => (
                                                    <div className='flex items-center'>
                                                      <button key={addOn.id} className="flex items-center w-full gap-4 py-2 pl-10">
                                                        <img src={addOn.addonsImageUrl || placeHolder} className="mt-2 w-10 h-10 object-cover rounded-md" alt="" />
                                                        <strong className='text-md fw-bold'> → </strong>
                                                        {addOn.addOnName}
                                                      </button>
                                                      <div className="flex items-center gap-4">
                                                        <span className="text-green-600 font-bold">
                                                          ₹{addOn.addOnPrice}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </div>
                                              ))}
                                            </div>
                                          ))}
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                </div>
                              ))}
                              {/* </div>
                              ))} */}
                            </div>
                          );
                        })}
                        <button
                          onClick={() => handleAddItemClick(subCategory.id)}
                          className="bg-green text-white px-3 py-1 rounded-md ml-auto mt-2 flex items-center"
                        >
                          <FontAwesomeIcon icon={faPlus} className="mr-1" />
                          Add Item
                        </button>
                      </div>


                    )}

                  </div>

                ))}

                <div className="mt-2">
                  <input
                    type="text"
                    placeholder="New SubCategory Name"
                    value={newSubCategoryName}
                    onChange={(e) => setNewSubCategoryName(e.target.value)}
                    className="mr-2 p-2 border border-gray-300 rounded"
                  />
                  <button
                    onClick={() => handleAddSubCategory(category.id)}
                    className="bg-green text-white px-3 py-2 rounded-md"
                  >
                    Add SubCategory
                  </button>
                </div>
              </div>

            )}
          </div>
        ))}
        <div className="mt-4">
          <input
            type="text"
            placeholder="New Category Name"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            className="mr-2 p-2 border border-gray-300 rounded"
          />
          <button onClick={handleAddCategory} className="bg-green text-white px-3 py-2 rounded-md">
            Add Category
          </button>
        </div>
      </div>
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg">
            <div className="flex justify-between items-center border-b pb-2 mb-4 p-6">
              <h3 className="text-lg font-semibold">Turn-on time for selected items</h3>
              <button onClick={closeModal} className="text-gray-500 hover:text-gray-800">
                <FaTimes />
              </button>
            </div>
            <p className="mb-4 text-lg font-semibold px-4">Auto turn on items</p>
            <div className="mb-4 ">
              <label className="flex items-center mb-2 px-4 pb-3 border-b border-gray-300">
                <input type="radio" name="auto-turn-on" value="option1" className="mr-2" />
                2 Hours
              </label>
              <label className="flex items-center mb-2 px-4 pb-3 border-b border-gray-300">
                <input type="radio" name="auto-turn-on" value="option2" className="mr-2" />
                4 Hours
              </label>
              <label className="flex items-center mb-2 px-4 pb-3 border-b border-gray-300">
                <input type="radio" name="auto-turn-on" value="option3" className="mr-2" />
                Next business day
              </label>
              <label className="flex items-center mb-2 px-4 pb-5 border-b-8 border-gray-300">
                <input type="radio" name="auto-turn-on" value="option4" className="mr-2" />
                Custom date & time (upto 7 days)
              </label>
            </div>
            <p className="mb-4 text-lg font-semibold px-4">Manually turn on</p>
            <label className="flex items-center mb-2 px-4 font-medium text-base">
              <input type="radio" name="auto-turn-on" value="option4" className="mr-2" />
              I will turn it on myself
            </label>
            <p className='px-4 text-gray-500 text-sm pb-4'>this item will not be visible to customer on the Genie app till you switch on it.</p>
            <div className='text-center'>
              <button onClick={closeModal} className="bg-green text-white w-96 mb-4 py-2 px-4 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300">CONFIRM</button>
            </div>
          </div>
        </div>
      )}
    </div>

  );
}