// import React, { useState } from 'react';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

//    const IndependentPricing = ({ formData, setFormData }) => {
//      const [options, setOptions] = useState([{ value: '', additionalPrice: '' }]);
//      const [variants, setVariants] = useState([{ value: '', additionalPrice: '' }]);
//      const [addons, setAddons] = useState([{ value: '', additionalPrice: '' }]);

//      const handleInputChange = (e, index, type) => {
//        const { name, value } = e.target;
//        const list = type === 'options' ? options : type === 'variants' ? variants : addons;
//        list[index][name] = value;
//        type === 'options' ? setOptions([...list]) : type === 'variants' ? setVariants([...list]) : setAddons([...list]);
//      };

//      const handleAddField = (type) => {
//        const newField = { value: '', additionalPrice: '' };
//        type === 'options' ? setOptions([...options, newField])
//          : type === 'variants' ? setVariants([...variants, newField])
//          : setAddons([...addons, newField]);
//      };

//      const handleSubmit = (e) => {
//        e.preventDefault();
//        setFormData({ ...formData, options, variants, addons });
//        toast.success('Independent Pricing data submitted!');
//      };

//      return (
//        <div className="mb-4">
//          <h2 className="text-xl font-bold">Independent Pricing Structure</h2>

//          <label className="block text-gray-700">Create Options</label>
//          {options.map((option, index) => (
//            <div key={index} className="flex mb-2">
//              <input
//                type="text"
//                name="value"
//                value={option.value}
//                onChange={(e) => handleInputChange(e, index, 'options')}
//                className="w-full p-2 border border-gray-300 rounded mr-2"
//                placeholder="Option"
//                required
//              />
//              <input
//                type="number"
//                name="additionalPrice"
//                value={option.additionalPrice}
//                onChange={(e) => handleInputChange(e, index, 'options')}
//                className="w-full p-2 border border-gray-300 rounded"
//                placeholder="Additional Price"
//                required
//              />
//            </div>
//          ))}
//          <button
//            type="button"
//            onClick={() => handleAddField('options')}
//            className="bg-green text-white p-2 rounded mb-4"
//          >
//            Add Another Option
//          </button>

//          <label className="block text-gray-700">Create Variants</label>
//          {variants.map((variant, index) => (
//            <div key={index} className="flex mb-2">
//              <input
//                type="text"
//                name="value"
//                value={variant.value}
//                onChange={(e) => handleInputChange(e, index, 'variants')}
//                className="w-full p-2 border border-gray-300 rounded mr-2"
//                placeholder="Variant"
//                required
//              />
//              <input
//                type="number"
//                name="additionalPrice"
//                value={variant.additionalPrice}
//                onChange={(e) => handleInputChange(e, index, 'variants')}
//                className="w-full p-2 border border-gray-300 rounded"
//                placeholder="Additional Price"
//                required
//              />
//            </div>
//          ))}
//          <button
//            type="button"
//            onClick={() => handleAddField('variants')}
//            className="bg-green text-white p-2 rounded mb-4"
//          >
//            Add Another Variant
//          </button>

//          <label className="block text-gray-700">Create Add-Ons</label>
//          {addons.map((addon, index) => (
//            <div key={index} className="flex mb-2">
//              <input
//                type="text"
//                name="value"
//                value={addon.value}
//                onChange={(e) => handleInputChange(e, index, 'addons')}
//                className="w-full p-2 border border-gray-300 rounded mr-2"
//                placeholder="Add-On"
//                required
//              />
//              <input
//                type="number"
//                name="additionalPrice"
//                value={addon.additionalPrice}
//                onChange={(e) => handleInputChange(e, index, 'addons')}
//                className="w-full p-2 border border-gray-300 rounded"
//                placeholder="Additional Price"
//                required
//              />
//            </div>
//          ))}
//          <button
//            type="button"
//            onClick={() => handleAddField('addons')}
//            className="bg-green text-white p-2 rounded"
//          >
//            Add Another Add-On
//          </button>

//          <button
//            type="button"
//            onClick={handleSubmit}
//            className="mt-4 bg-green-500 text-white p-2 rounded"
//          >
//            Complete
//          </button>
//        </div>
//      );
//    };

//    export default IndependentPricing;


import React, { useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const IndependentPricing = ({ itemName, subCategoryId, restId, menuBasePrice }) => {
  const [pricingData, setPricingData] = useState({
    options: [{ value: '', additionalPrice: '', isSaved: false }],
    variants: [{ value: '', additionalPrice: '', isSaved: false }],
    addons: [{ value: '', additionalPrice: '', isSaved: false }]
  });

  const [error, setError] = useState('');

  const basePrice = menuBasePrice;
  const menuItemName = itemName;
  const menuSubCategoryId = subCategoryId;
  const restaurantId = restId;
  const navigate = useNavigate();

  const handleInputChange = (e, index, type) => {
    const { name, value } = e.target;
    setPricingData(prev => {
      const updatedList = [...prev[type]];
      updatedList[index] = { ...updatedList[index], [name]: value };
      return { ...prev, [type]: updatedList };
    });
  };

  const handleSave = (index, type) => {
    if (!pricingData[type][index].value.trim() || !pricingData[type][index].additionalPrice.trim()) {
      setError('Both fields are required!');
      return;
    }
    setError('');
    setPricingData(prev => {
      const updatedList = [...prev[type]];
      updatedList[index].isSaved = true;
      return { ...prev, [type]: updatedList };
    });
    // setFormData(prev => ({ ...prev, [type]: pricingData[type] }));
    toast.success(`${type.charAt(0).toUpperCase() + type.slice(1)} saved successfully!`);
  };

  const handleEdit = (index, type) => {
    setPricingData(prev => {
      const updatedList = [...prev[type]];
      updatedList[index].isSaved = false;
      return { ...prev, [type]: updatedList };
    });
  };

  const handleDelete = (index, type) => {
    setPricingData(prev => ({
      ...prev,
      [type]: prev[type].filter((_, i) => i !== index)
    }));
    // setFormData(prev => ({
    //   ...prev,
    //   [type]: pricingData[type].filter((_, i) => i !== index)
    // }));
    toast.error(`${type.charAt(0).toUpperCase() + type.slice(1)} deleted!`);
  };

  const handleAddField = (type) => {
    setPricingData(prev => ({
      ...prev,
      [type]: [...prev[type], { value: '', additionalPrice: '', isSaved: false }]
    }));
  };

  // Handle Back Button
  const handleBackClick = () => {
    navigate('/menuhead') // Call the parent back function if needed
  };


  const renderInputFields = (type, label) => (
    <div className="mb-3 mx-5">
      <h2 className="text-xl font-bold mb-3">{label}</h2>
      {pricingData[type].map((item, index) => (
        <div key={index} className="flex items-center mb-2">
          {item.isSaved ? (
            <div className="flex items-center space-x-2 w-full ml-4">
              <span className="w-64 px-3 py-1 border border-gray-300 rounded text-left">{item.value}</span>
              <span className="w-20 px-3 py-1 border border-gray-300 rounded text-center">{item.additionalPrice}</span>
              <FaEdit className="text-green text-lg cursor-pointer" onClick={() => handleEdit(index, type)} />
              <FaTrash className="text-red text-lg cursor-pointer" onClick={() => handleDelete(index, type)} />
            </div>
          ) : (
            <>
              <input
                type="text"
                name="value"
                value={item.value}
                onChange={(e) => handleInputChange(e, index, type)}
                className="w-64 p-2 border border-gray-300 rounded ml-4 mr-2"
                placeholder={label}
                required
              />
              <input
                type="number"
                name="additionalPrice"
                value={item.additionalPrice}
                onChange={(e) => handleInputChange(e, index, type)}
                className="w-48 p-2 border border-gray-300 rounded ml-4"
                placeholder="Additional Price"
                required
              />
              <button onClick={() => handleSave(index, type)} className="bg-green text-white p-2 rounded ml-2">Save</button>
            </>
          )}
        </div>
      ))}
      {error && <p className="text-red-500">{error}</p>}
      <button type="button" onClick={() => handleAddField(type)} className="bg-green text-white p-2 rounded ml-4 mt-1">
        Add Another {label}
      </button>
    </div>
  );

  return (
    <div className="">
      <div className='mx-4'>
        <div className="mb-3 flex items-center justify-between mt-2">
          <h1 className="text-2xl font-bold mb-4">The Genie - Menu Creation Tool</h1>
        </div>

        {/* Base Price Input */}
        <div className="flex items-center">
          <BsArrowLeft onClick={handleBackClick} className="text-green text-2xl cursor-pointer mx-2" />
          <span className="font-semibold">Set Your Delivery Pricing</span>
        </div>
        <div className="mb-2 flex items-center justify-between mt-3">
          <div>
            <h2 className="text-lg flex-grow font-bold my-4 ml-2">{menuItemName}</h2>
          </div>
          <div className='flex items-center'>
            <label className="block text-base font-medium text-gray-700 mb-1">Base Price</label>
            <input type="text" className="border border-gray-300 rounded-md text-center p-1 cursor-pointer ml-5" value={basePrice} readOnly />
          </div>
        </div>
      </div>
      {renderInputFields('options', 'Option')}
      {renderInputFields('variants', 'Variant')}
      {renderInputFields('addons', 'Add-On')}
      <div className='text-center mb-4'>
        <button
          type="button"
          onClick={() => toast.success('Independent Pricing data submitted!')}
          className="mt-4 bg-green w-1/4 text-center text-white p-2 rounded"
        >
          Complete
        </button>
      </div>
    </div>
  );
};

export default IndependentPricing;
