import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RelationalPricing = ({ formData, setFormData }) => {
  const [state, setState] = useState({
    options: [{ value: "", additionalPrice: "" }],
    variants: [{ value: "", additionalPrice: "" }],
    addons: [{ value: "", additionalPrice: "" }],
  });

  // Handle input change for options, variants, and add-ons
  const handleInputChange = (e, index, type) => {
    const { name, value } = e.target;
    const list = state[type];
    list[index][name] = value;
    setState({ ...state, [type]: [...list] });
  };

  // Add a new field for options, variants, or add-ons
  const handleAddField = (type) => {
    const newField = { value: "", additionalPrice: "" };
    setState({ ...state, [type]: [...state[type], newField] });
  };

     const handleSubmit = (e) => {
       e.preventDefault();
       setFormData({
        ...formData,
        options: state.options,
        variants: state.variants,
        addons: state.addons,
      });
       toast.success('Relational Pricing data submitted!');
     };

     return (
       <div className="mb-4">
         <h2 className="text-xl font-bold">Relational Pricing Structure</h2>
         <label className="block text-gray-700">Base Price</label>
         <input
           type="number"
           name="basePrice"
           value={formData.basePrice || ''}
           onChange={(e) => setFormData({ ...formData, basePrice: e.target.value })}
           className="w-full p-2 border border-gray-300 rounded mb-4"
           required
         />

         <label className="block text-gray-700">Create Options</label>
         {state.options.map((option, index) => (
           <div key={index} className="flex mb-2">
             <input
               type="text"
               name="value"
               value={option.value}
               onChange={(e) => handleInputChange(e, index, 'options')}
               className="w-full p-2 border border-gray-300 rounded mr-2"
               placeholder="Option"
               required
             />
             <input
               type="number"
               name="additionalPrice"
               value={option.additionalPrice}
               onChange={(e) => handleInputChange(e, index, 'options')}
               className="w-full p-2 border border-gray-300 rounded"
               placeholder="Additional Price"
               required
             />
           </div>
         ))}
         <button
           type="button"
           onClick={() => handleAddField('options')}
           className="bg-green text-white p-2 rounded mb-4"
         >
           Add Another Option
         </button>

         <label className="block text-gray-700">Create Variants</label>
         {state.variants.map((variant, index) => (
           <div key={index} className="flex mb-2">
             <input
               type="text"
               name="value"
               value={variant.value}
               onChange={(e) => handleInputChange(e, index, 'variants')}
               className="w-full p-2 border border-gray-300 rounded mr-2"
               placeholder="Variant"
               required
             />
             <input
               type="number"
               name="additionalPrice"
               value={variant.additionalPrice}
               onChange={(e) => handleInputChange(e, index, 'variants')}
               className="w-full p-2 border border-gray-300 rounded"
               placeholder="Additional Price"
               required
             />
           </div>
         ))}
         <button
           type="button"
           onClick={() => handleAddField('variants')}
           className="bg-green text-white p-2 rounded mb-4"
         >
           Add Another Variant
         </button>

         <label className="block text-gray-700">Create Add-Ons</label>
         {state.addons.map((addon, index) => (
           <div key={index} className="flex mb-2">
             <input
               type="text"
               name="value"
               value={addon.value}
               onChange={(e) => handleInputChange(e, index, 'addons')}
               className="w-full p-2 border border-gray-300 rounded mr-2"
               placeholder="Add-On"
               required
             />
             <input
               type="number"
               name="additionalPrice"
               value={addon.additionalPrice}
               onChange={(e) => handleInputChange(e, index, 'addons')}
               className="w-full p-2 border border-gray-300 rounded"
               placeholder="Additional Price"
               required
             />
           </div>
         ))}
         <button
           type="button"
           onClick={() => handleAddField('addons')}
           className="bg-green text-white p-2 rounded"
         >
           Add Another Add-On
         </button>

         <button
           type="button"
           onClick={handleSubmit}
           className="mt-4 bg-green-500 text-white p-2 rounded"
         >
           Complete
         </button>
       </div>
     );
   };

export default RelationalPricing;
