// import React, { useEffect, useState } from 'react';
// import { listPortalUsers, removeUser } from '../../API/Api';
// import { FaTrash } from 'react-icons/fa';

// // Modal Component
// const Modal = ({ message, onClose }) => (
//     <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
//         <div className="bg-white rounded-lg p-6 max-w-md mx-auto shadow-lg">
//             <h3 className="text-lg font-semibold mb-4">{message}</h3>
//             <button
//                 onClick={onClose}
//                 className="mt-4 w-full py-2 px-4 bg-green text-white rounded-md hover:bg-green-700"
//             >
//                 Close
//             </button>
//         </div>
//     </div>
// );

// const PortalUsersList = () => {
//     const [state, setState] = useState({
//         userList: [],
//         restaurantId: localStorage.getItem("restaurantId"),
//         showModal: false,
//         modalMessage: "",
//     });

//     useEffect(() => {
//         const usersList = async () => {
//             try {
//                 const response = await listPortalUsers(state.restaurantId);
//                 setState(prevState => ({
//                     ...prevState,
//                     userList: response || [],
//                 }));
//             } catch (error) {
//                 console.error("Error fetching users:", error);
//             }
//         };
//         usersList();
//     }, [state.restaurantId]);

//     // Handle storing userId in localStorage when a User Id is clicked
//     const handleUserClick = (userId) => {
//         localStorage.setItem("selectedUserId", userId);
//         console.log(`User ID ${userId} stored in localStorage`);
//     };

//     // Handle deleting a role using the deleteCustomRole API
//     const handleDeleteUser = async (userId) => {
//         const payload = {
//             restaurantId: state.restaurantId,
//             userId: userId // Use the roleId passed as an argument
//         };

//         try {
//             await removeUser(payload); // Call the API to delete the role
//             setState(prevState => ({
//                 ...prevState,
//                 userList: prevState.userList.filter(user => user.userId !== userId)
//             }));
//             console.log(`Role ID ${userId} removed from list and deleted from server`);

//         } catch (error) {
//             const errorMessage = error.response?.message?.message;
//             console.log("Error Message",errorMessage);

//             if (errorMessage && errorMessage.includes("Cannot delete the Authorized User.")) {
//                 setState(prevState => ({
//                     ...prevState,
//                     showModal: true,
//                     modalMessage: "Cannot delete the Authorized User."
//                 }))
//                 console.log("Modal Message",state.modalMessage);

//             } else {
//                 console.error("Error deleting user:", error);
//             }
//         }
//     };

//     return (
//         <div className="p-4">
//             <h2 className="text-xl font-semibold mb-4">Portal Users</h2>
//             <div className="overflow-auto">
//                 <table className="min-w-full bg-white border border-gray-300">
//                     <thead>
//                         <tr className="border-b border-gray-300">
//                             <th className="px-4 py-2 text-left font-semibold text-gray-700">Name</th>
//                             <th className="px-4 py-2 text-left font-semibold text-gray-700">Role</th>
//                             <th className="px-4 py-2 text-left font-semibold text-gray-700">Phone Number</th>
//                             <th className="px-4 py-2 text-center font-semibold text-gray-700">Actions</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {state.userList.map((user) => (
//                             <tr key={user.userId} className="border-b border-gray-200 hover:bg-gray-100">
//                                 <td
//                                     onClick={() => handleUserClick(user.userId)}
//                                     className="px-4 py-2 cursor-pointer text-gray-700"
//                                 >
//                                     {user.name || '-------'}
//                                 </td>
//                                 <td
//                                     onClick={() => handleUserClick(user.userId)}
//                                     className="px-4 py-2 cursor-pointer text-gray-700"
//                                 >
//                                     {user.role || '-------'}
//                                 </td>
//                                 <td
//                                     onClick={() => handleUserClick(user.userId)}
//                                     className="px-4 py-2 cursor-pointer text-gray-700"
//                                 >
//                                     {user.phoneNumber || '-------'}
//                                 </td>
//                                 <td className="px-4 py-2 flex justify-center">
//                                     <button
//                                         onClick={() => handleDeleteUser(user.userId)}
//                                         className="text-red hover:text-red-700 flex items-center justify-center"
//                                     >
//                                         <FaTrash />
//                                     </button>
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>
//             {/* Modal */}
//             {state.showModal && <Modal message={state.modalMessage} onClose={() => { setState(prevState => ({ ...prevState, showModal: false })) }} />}
//         </div>
//     );
// };

// export default PortalUsersList;





// import React, { useEffect, useState } from 'react';
// import { listPortalUsers, removeUser } from '../../API/Api';
// import { FaTrash } from 'react-icons/fa';

// // Modal Component
// const Modal = ({ message, onClose }) => (
//     <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
//         <div className="bg-white rounded-lg p-6 max-w-md mx-auto shadow-lg">
//             <h3 className="text-lg font-semibold mb-4">{message}</h3>
//             <button
//                 onClick={onClose}
//                 className="mt-4 w-full py-2 px-4 bg-green text-white rounded-md hover:bg-green-700"
//             >
//                 Close
//             </button>
//         </div>
//     </div>
// );



// const PortalUsersList = () => {
//     const [state, setState] = useState({
//         userList: [],
//         restaurantId: localStorage.getItem("restaurantId"),
//         showModal: false,
//         modalMessage: "",
//     });

//     useEffect(() => {
//         const usersList = async () => {
//             try {
//                 const response = await listPortalUsers(state.restaurantId);
//                 setState(prevState => ({
//                     ...prevState,
//                     userList: response || [],
//                 }));
//             } catch (error) {
//                 console.error("Error fetching users:", error);
//             }
//         };
//         usersList();
//     }, [state.restaurantId]);

//     // Handle storing userId in localStorage when a User Id is clicked
//     const handleUserClick = (userId) => {
//         localStorage.setItem("selectedUserId", userId);
//         console.log(`User ID ${userId} stored in localStorage`);
//     };

//     // Handle deleting a role using the deleteCustomRole API
//     const handleDeleteUser = async (userId) => {
//         const payload = {
//             restaurantId: state.restaurantId,
//             userId: userId // Use the roleId passed as an argument
//         };

//         try {
//             await removeUser(payload); // Call the API to delete the role
//             setState(prevState => ({
//                 ...prevState,
//                 userList: prevState.userList.filter(user => user.userId !== userId)
//             }));
//             console.log(`Role ID ${userId} removed from list and deleted from server`);

//         } catch (error) {
//             const errorMessage = error.response?.data?.message?.message || "An error occurred.";
//             console.log("Error Message:", errorMessage);

//             if (errorMessage.includes("Cannot delete the Authorized User.")) {
//                 setState(prevState => ({
//                     ...prevState,
//                     showModal: true,
//                     modalMessage: "Cannot delete the Authorized User."
//                 }));
//             } else {
//                 console.error("Error deleting user:", error);
//             }
//         }
//     };

//     return (
//         <div className="p-4">
//             <h2 className="text-xl font-semibold mb-4">Portal Users</h2>
//             <div className="overflow-auto">
//                 <table className="min-w-full bg-white border border-gray-300">
//                     <thead>
//                         <tr className="border-b border-gray-300">
//                             <th className="px-4 py-2 text-left font-semibold text-gray-700">Name</th>
//                             <th className="px-4 py-2 text-left font-semibold text-gray-700">Role</th>
//                             <th className="px-4 py-2 text-left font-semibold text-gray-700">Phone Number</th>
//                             <th className="px-4 py-2 text-center font-semibold text-gray-700">Actions</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {state.userList.map((user) => (
//                             <tr key={user.userId} className="border-b border-gray-200 hover:bg-gray-100">
//                                 <td
//                                     onClick={() => handleUserClick(user.userId)}
//                                     className="px-4 py-2 cursor-pointer text-gray-700"
//                                 >
//                                     {user.name || '-------'}
//                                 </td>
//                                 <td
//                                     onClick={() => handleUserClick(user.userId)}
//                                     className="px-4 py-2 cursor-pointer text-gray-700"
//                                 >
//                                     {user.role || '-------'}
//                                 </td>
//                                 <td
//                                     onClick={() => handleUserClick(user.userId)}
//                                     className="px-4 py-2 cursor-pointer text-gray-700"
//                                 >
//                                     {user.phoneNumber || '-------'}
//                                 </td>
//                                 <td className="px-4 py-2 flex justify-center">
//                                     <button
//                                         onClick={() => handleDeleteUser(user.userId)}
//                                         className={`flex items-center justify-center ${
//                                             user.isDeleteable ? 'text-red hover:text-red-700' : 'text-gray-400 cursor-not-allowed'
//                                         }`}
//                                         disabled={!user.isDeleteable}
//                                     >
//                                         <FaTrash />
//                                     </button>
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>
//             {/* Modal */}
//             {state.showModal && <Modal message={state.modalMessage} onClose={() => setState(prevState => ({ ...prevState, showModal: false }))} />}
//         </div>
//     );
// };

// export default PortalUsersList;


import React, { useEffect, useState } from 'react';
import { listPortalUsers, removeUser } from '../../API/Api';
import { FaTrash } from 'react-icons/fa';

// Modal Component
const ConfirmationModal = ({ message, onConfirm, onCancel }) => (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
        <div className="bg-white rounded-lg p-6 max-w-md mx-auto shadow-lg">
            <h3 className="text-lg font-semibold text-center mb-4">{message}</h3>
            <div className="flex justify-around">
                <button
                    onClick={onCancel}
                    className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
                >
                    Cancel
                </button>
                <button
                    onClick={onConfirm}
                    className="px-4 py-2 bg-red text-white rounded-md hover:bg-red-600"
                >
                    Delete
                </button>
            </div>
        </div>
    </div>
);

const PortalUsersList = () => {
    const [state, setState] = useState({
        userList: [],
        restaurantId: localStorage.getItem("restaurantId"),
        permissions: JSON.parse(localStorage.getItem("permissions")) || {},
    });

    const { permissions } = state;

    const [modalState, setModalState] = useState({
        isVisible: false,
        userId: null,
        userName: '',
    });

    useEffect(() => {
        const usersList = async () => {
            try {
                const response = await listPortalUsers(state.restaurantId);
                setState((prevState) => ({
                    ...prevState,
                    userList: response || [],
                }));
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };
        usersList();
    }, [state.restaurantId]);

    // Handle storing userId in localStorage when a User Id is clicked
    const handleUserClick = (userId) => {
        localStorage.setItem("selectedUserId", userId);
        console.log(`User ID ${userId} stored in localStorage`);
    };

    // Show the confirmation modal
    const handleShowDeleteModal = (userId, userName) => {
        setModalState({
            isVisible: true,
            userId,
            userName,
        });
    };

    // Close the modal
    const handleCancelDelete = () => {
        setModalState({
            isVisible: false,
            userId: null,
            userName: '',
        });
    };

    // Handle confirming deletion
    const handleConfirmDelete = async () => {
        const payload = {
            restaurantId: state.restaurantId,
            userId: modalState.userId,
        };

        try {
            await removeUser(payload); // Call the API to delete the user
            setState((prevState) => ({
                ...prevState,
                userList: prevState.userList.filter(
                    (user) => user.userId !== modalState.userId
                ),
            }));
            console.log(
                `User ID ${modalState.userId} removed from list and deleted from server`
            );
        } catch (error) {
            console.error("Error deleting user:", error);
        } finally {
            handleCancelDelete(); // Close the modal after deletion
        }
    };

    return (
        <div className="p-4">
            <h2 className="text-xl font-semibold mb-4">Portal Users</h2>
            <div className="overflow-auto">
                <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                        <tr className="border-b border-gray-300">
                            <th className="px-4 py-2 text-left font-semibold text-gray-700">
                                Name
                            </th>
                            <th className="px-4 py-2 text-left font-semibold text-gray-700">
                                Role
                            </th>
                            <th className="px-4 py-2 text-left font-semibold text-gray-700">
                                Phone Number
                            </th>
                            {permissions["removeUsers"] === true &&
                                <th className="px-4 py-2 text-center font-semibold text-gray-700">
                                    Actions
                                </th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {state.userList.map((user) => (
                            <tr
                                key={user.userId}
                                className="border-b border-gray-200 hover:bg-gray-100"
                            >
                                <td
                                    onClick={() => handleUserClick(user.userId)}
                                    className="px-4 py-2 cursor-pointer text-gray-700"
                                >
                                    {user.name || "-------"}
                                </td>
                                <td
                                    onClick={() => handleUserClick(user.userId)}
                                    className="px-4 py-2 cursor-pointer text-gray-700"
                                >
                                    {user.role || "-------"}
                                </td>
                                <td
                                    onClick={() => handleUserClick(user.userId)}
                                    className="px-4 py-2 cursor-pointer text-gray-700"
                                >
                                    {user.phoneNumber || "-------"}
                                </td>
                                {permissions["removeUsers"] === true &&
                                    <td className="px-4 py-2 flex justify-center">
                                        <button
                                            onClick={() =>
                                                handleShowDeleteModal(
                                                    user.userId,
                                                    user.name || "this user"
                                                )
                                            }
                                            className={`flex items-center justify-center ${user.isDeleteable
                                                ? "text-red hover:text-red-700"
                                                : "text-gray-400 cursor-not-allowed"
                                                }`}
                                            disabled={!user.isDeleteable}
                                        >
                                            <FaTrash />
                                        </button>
                                    </td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Confirmation Modal */}
            {modalState.isVisible && (
                <ConfirmationModal
                    message={`Are you sure you want to delete ${modalState.userName}?`}
                    onConfirm={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                />
            )}
        </div>
    );
};

export default PortalUsersList;
