
import React, { useState, useRef, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { createCampaign, fetchMenuDetails, getCampaignPermissions } from '../API/Api';
import { formatDate } from 'date-fns';
import menuItemDetails from './menuItemDetails';

const CreateNewOffer = ({ show, onHide, selectedDiscountType }) => {
  const [formData, setFormData] = useState({
    campaignStartDate: '',
    campaignEndDate: '',
    discountTypeCode: 0,
    selectedDays: [],
    exemptedItems: [],
    discountWindows: {},
    selectedOffer: { discount_type_code: 0 },
    couponCode: '',
    nestedDiscountType: null,
    minimumOrderValue: 100,
    percentage: 10,
    maxDiscountValue: 50,
    discountValue: 50,
    userCode: 0,
    maxRedemptionsPerUser: 1,
    redeemEvery: 60, // minutes
    selectedService: "Takeaway",
    isDining: false,
    couponName: '',
    isFlat: false,
    isMaxRadius: false,
    maxDiscountRadius: 0,
    radius: 0,
    offerItems: [],
    maxItems: 1,
    discountItem: 0,
    maxPairs: 1,
    predefinedCouponCode: 0
  });

  const offerCanvasRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [campaignPermissions, setCampaignPermissions] = useState(null);
  const userId = sessionStorage.getItem("userId");
  const restaurantId = localStorage.getItem("restaurantId") || null;
  const allDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const [menuItems, setMenuItems] = useState([]);

  const closeOffCanvas = () => {
    setFormData(prevData => ({
      ...prevData,
      selectedOffer: { discount_type_code: 0 } // Reset the selectedOffer
    }));
  };
  useEffect(() => {
    fetchMenuItemDetails();
    const handleClickOutside = (event) => {
      if (offerCanvasRef.current && !offerCanvasRef.current.contains(event.target)) {
        onHide();
      }

    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onHide]);

  const fetchMenuItemDetails = async () => {
    try {
      const data = await fetchMenuDetails(restaurantId);

      if (!data || !data.menuCategories) {
        console.error("Invalid response structure:", data);
        return;
      }

      // Extract menu item names and IDs
      const extractedItems = data.menuCategories.flatMap(category =>
        category.MenuSubCategory.flatMap(subCategory =>
          subCategory.MenuItemSubCategoryLookup.map(item => ({
            id: item.MenuItem.id,
            name: item.MenuItem.MenuItemRevision[0].name, // Extract menu item name
          }))
        )
      );

      setMenuItems(extractedItems);
      console.log("Extracted Menu Items:", extractedItems);
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  useEffect(() => {
    // Set the discount type when the component mounts or when the prop changes
    const discountTypesMap = {
      "Percentage Discount": 0,
      "Flat Discount": 1,
      "Flat Percentage Discount": 2,
      "Predefined Discount": 3,
      "Delivery Discount": 4,
      "Custom Coupon Discount": 5,
      "Freebie Discount": 6,
      "BOGO Discount": 7,
    };

    setFormData((prevData) => ({
      ...prevData,
      discountTypeCode:
        selectedDiscountType in discountTypesMap
          ? discountTypesMap[selectedDiscountType]
          : 0, // Default to the first option
    }));
  }, [selectedDiscountType]);

  // Toggle selected days
  //  const handleDaySelection = (day) => {
  //   setFormData((prev) => {
  //     let updatedDays;
  //     let updatedWindows = { ...prev.discountWindows };

  //     if (day === "All Days") {
  //       // Select all days or clear selection
  //       updatedDays = prev.selectedDays.length === 7 ? [] : [...allDays];
  //       updatedDays.forEach((d) => {
  //         updatedWindows[d] = prev.discountWindows[d] || [{  windowStart: '', windowEnd: '' }];
  //       });
  //     } else {
  //       updatedDays = prev.selectedDays.includes(day)
  //         ? prev.selectedDays.filter((d) => d !== day)
  //         : [...prev.selectedDays, day];

  //       updatedWindows[day] = prev.discountWindows[day] || [{windowStart: '', windowEnd: ''}];
  //     }

  //     return { ...prev, selectedDays: updatedDays, discountWindows: updatedWindows };
  //   });
  // };
  // Fetch API data when component mounts
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await getCampaignPermissions();
        setCampaignPermissions(response);
      } catch (error) {
        console.error("Failed to fetch campaign permissions:", error);
      }
    };
    fetchPermissions();
  }, []);

  const getDiscountTypeLabel = (code) => {
    const discountTypeMap = {
      0: "Percentage Discount",
      1: "Flat Discount",
      2: "Flat Percentage Discount",
      3: "Predefined Discount",
      4: "Delivery Discount",
      5: "Custom Coupon Discount",
      6: "Freebie Discount",
      7: "BOGO Discount",
    };
    return discountTypeMap[code] || "Unknown Discount Type";
  };

  const getUserTypeLabel = (code) => {
    const userTypeMap = {
      0: "New Customer",
      1: "Regular Customer",
      2: "Loyal Customer",
      3: "Returning Customer",
      4: "Infrequent Customer",
    };
    return userTypeMap[code] || "Unknown User Type";
  };

  const handleDaySelection = (day) => {
    setFormData((prev) => {
      let updatedDays;
      let updatedWindows = { ...prev.discountWindows };

      if (day === "All Days") {
        // If "All Days" is selected, apply time slots to all days
        if (prev.selectedDays.length === 7) {
          // If "All Days" is already selected, clear everything
          updatedDays = [];
          updatedWindows = {}; // Reset all discountWindows
        } else {
          updatedDays = [...allDays];
          allDays.forEach((d) => {
            updatedWindows[d] = prev.discountWindows[d] || [{ windowStart: '', windowEnd: '' }];
          });
        }
      } else {
        // Handling selection/deselection of individual days
        const isDaySelected = prev.selectedDays.includes(day);
        updatedDays = isDaySelected
          ? prev.selectedDays.filter((d) => d !== day)
          : [...prev.selectedDays, day];

        if (!isDaySelected) {
          updatedWindows[day] = prev.discountWindows[day] || [{ windowStart: '', windowEnd: '' }];
        } else {
          delete updatedWindows[day]; // Remove time slots when the day is deselected
        }
      }

      return { ...prev, selectedDays: updatedDays, discountWindows: updatedWindows };
    });
  };
  // Handle time changes per day
  const handleTimeChange = (day, index, field, value) => {
    setFormData((prev) => {
      // Ensure discountWindows structure is properly initialized
      const updatedWindows = { ...prev.discountWindows };
      if (!updatedWindows[day]) {
        updatedWindows[day] = [{ windowStart: '', windowEnd: '' }];
      }
      // Ensure a deep clone of time slots array
      const daySlots = [...updatedWindows[day]];
      // Ensure object reference is new
      daySlots[index] = { ...daySlots[index], [field]: value }; // Convert HH:MM to HH:MM:SS
      // Update state with new time slots
      return {
        ...prev,
        discountWindows: {
          ...updatedWindows,
          [day]: daySlots
        }
      };
    });
  }

  // Add new time slot for a selected day
  const addTimeSlot = (day) => {
    const lastSlot = formData.discountWindows[day].slice(-1)[0];
    if (!lastSlot.windowStart || !lastSlot.windowEnd) {
      alert("Please complete the existing time slot before adding a new one.");
      return;
    }
    setFormData((prev) => ({
      ...prev,
      discountWindows: { ...prev.discountWindows, [day]: [...prev.discountWindows[day], { windowStart: '', windowEnd: '' }] },
    }));
  };

  // Remove a time slot for a selected day
  const removeTimeSlot = (day, index) => {
    setFormData((prev) => {
      const updatedWindows = prev.discountWindows[day].filter((_, i) => i !== index);
      return { ...prev, discountWindows: { ...prev.discountWindows, [day]: updatedWindows } };
    });
  };

  const handleDiscountTypeChange = (e) => {
    const selectedType = parseInt(e.target.value);
    setFormData((prevData) => ({
      ...prevData,
      discountTypeCode: parseInt(e.target.value),
      nestedDiscountType: selectedType
    }));
  };
  const handleDiscountWindowChange = (index, timeIndex, field, value) => {
    const updatedWindows = [...formData.discountWindows];
    updatedWindows[index].discount_time_windows[timeIndex][field] = value;
    setFormData((prevData) => ({
      ...prevData,
      discountWindows: updatedWindows
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.campaignStartDate) newErrors.campaignStartDate = "Campaign Start Date is required.";
    if (!formData.campaignEndDate) newErrors.campaignEndDate = "Campaign End Date is required.";
    if (!formData.discountTypeCode === "") newErrors.discountTypeCode = "Please select a discount type.";
    if (!formData.userCode === '') newErrors.userCode = "User Code is required.";
    if (!formData.redeemEvery) newErrors.redeemEvery = "Redeem Every is required.";
    console.log("redeem Every", formData.redeemEvery);
    if (!formData.maxRedemptionsPerUser) newErrors.maxRedemptionsPerUser = "Max Redemptions Per User is required.";
    if (!formData.selectedService) newErrors.selectedService = "Please select a service.";
    if (formData.discountTypeCode === 0 && !formData.percentage) newErrors.percentage = "Percentage discount is required.";
    if (formData.discountTypeCode === "1" && !formData.discountValue) newErrors.discountValue = "Flat Discount Value is required.";
    if (!formData.minimumOrderValue) newErrors.minimumOrderValue = "Minimum Order Value is required.";
    if (!formData.maxDiscountValue) newErrors.maxDiscountValue = "Max Discount Value is required.";
    if (formData.discountTypeCode === "5" && !formData.couponCode) newErrors.couponCode = "Coupon Code is required.";
    if (formData.selectedDays.length === 0) {
      newErrors.selectedDays = "Please select at least one day for the discount window.";
    } else {
      // Validate time slots for each selected day
      Object.keys(formData.discountWindows).forEach((day) => {
        formData.discountWindows[day]?.forEach((slot, index) => {
          if (!slot.windowStart || !slot.windowEnd) {
            newErrors.discountWindows = newErrors.discountWindows || {};
            newErrors.discountWindows[day] = `Please fill in both Start and End Time for ${day}.`;
          }
        });
      });
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  // Function to handle adding offer items from the dropdown
  const handleOfferItemSelection = (item) => {
    setFormData(prev => {
      const isSelected = prev.offerItems.includes(item.id);
      const updatedItems = isSelected
        ? prev.offerItems.filter(id => id !== item.id)
        : [...prev.offerItems, item.id];

      return { ...prev, offerItems: updatedItems };
    });
  };

  {/* Selection Handler */ }
  const handleExemptedItemSelection = (item) => {
    setFormData((prev) => {
      const isSelected = prev.exemptedItems.some(exempted => exempted.id === item.id);
      const updatedItems = isSelected
        ? prev.exemptedItems.filter((i) => i.id !== item.id)
        : [...prev.exemptedItems, item];

      console.log("Updated Exempted Item IDs:", updatedItems.map(i => i.id));
      return { ...prev, exemptedItems: updatedItems };
    });
  };

  const handleActivateOffer = async () => {
    if (!validateForm()) return;
    try {
      const formatDateTime = (date) => {
        const d = new Date(date);
        return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')} 00:00:00`;
      };

      if (!formData.campaignStartDate || !formData.campaignEndDate) {
        alert("Please select valid start and end dates.");
        return;
      }

      // Extract only the IDs from exemptedItems
      const exemptedItemIds = formData.exemptedItems.map(item => item.id);

      let discountDetails = {
        discountTypeCode: formData.discountTypeCode,
        discountDescription: {},
      };

      switch (formData.discountTypeCode) {
        case 0: // Percentage Discount
          discountDetails.discountDescription = {
            percentage: formData.percentage,
            minimumOrderValue: formData.minimumOrderValue,
            maxDiscountValue: formData.maxDiscountValue,
            ineligibleCartItem: exemptedItemIds
          };
          break;
        case 1: // Flat Discount
          discountDetails.discountDescription = {
            minimumOrderValue: formData.minimumOrderValue,
            discountValue: formData.discountValue,
            ineligibleCartItem: exemptedItemIds
          };
          break;
        case 2: // Flat Percentage Discount
          discountDetails.discountDescription = {
            percentage: formData.percentage,
            minimumOrderValue: formData.minimumOrderValue,
            ineligibleCartItem: exemptedItemIds
          };
          break;
        case 3: // Predefined Discount
          discountDetails.discountDescription = {
            predefinedCouponCode: formData.predefinedCouponCode,
            couponName: formData.couponName,
            couponCode: formData.couponCode,
            ineligibleCartItem: exemptedItemIds
          };
          break;
        case 4: // Delivery Discount
          discountDetails.discountDescription = {
            isFlat: Boolean(formData.isFlat),
            isMaxRadius: Boolean(formData.isMaxRadius),
            maxDiscountValue: formData.maxDiscountRadius,
            minimumOrderValue: formData.minimumOrderValue,
            radius: formData.radius,
            ineligibleCartItem: exemptedItemIds
          };
          break;
        case 5: // Custom Coupon Discount
          discountDetails.discountDescription = {
            couponCode: String(formData.couponCode),
            discountInfo: {
              discountTypeCode: formData.nestedDiscountType,
              discountDescription: {
                percentage: formData.percentage,
                minimumOrderValue: formData.minimumOrderValue,
                maxDiscountValue: formData.maxDiscountValue,
                ineligibleCartItem: exemptedItemIds
              }
            }
          };
          break;
        case 6: // Freebie Discount
          discountDetails.discountDescription = {
            minimumOrderValue: formData.minimumOrderValue,
            offerItems: formData.offerItems,
            maxItems: formData.maxItems,
            ineligibleCartItem: exemptedItemIds
          };
          break;
        case 7: // BOGO Discount
          discountDetails.discountDescription = {
            applicableItems: formData.offerItems,
            discountItem: formData.discountItem,
            minimumOrderValue: formData.minimumOrderValue,
            maxPairs: formData.maxPairs,
            ineligibleCartItem: exemptedItemIds
          };
          break;
        default:
          console.error("Invalid discount type");
          return;
      }

      const discountWindows = Object.keys(formData.discountWindows).map((day) => ({
        campaignDay: day, // Keep the actual day name
        is24hours: false, // Default to false
        discountTimeWindows: formData.discountWindows[day]?.map((slot) => ({
          windowStart: slot.windowStart || "00:00", // Ensure default format
          windowEnd: slot.windowEnd || "23:59", // Ensure default format
        })) || []
      }));

      const payload = {
        campaignStartDate: formatDateTime(formData.campaignStartDate),
        campaignEndDate: formatDateTime(formData.campaignEndDate),
        userCode: formData.userCode,
        maxRedemptionsPerUser: Math.trunc(formData.maxRedemptionsPerUser),
        redeemEvery: parseFloat(formData.redeemEvery),
        isAllDays: formData.selectedDays.length === 7,
        restaurantId: restaurantId ? String(restaurantId) : "",
        isTakeAway: formData.selectedService === "Takeaway",
        isDelivery: formData.selectedService === "Delivery",
        isDineIn: formData.selectedService === "Dining",
        discountWindow: discountWindows,
        discountDetails: discountDetails,
        isTargetted: true
      };

      console.log("Final Payload:", JSON.stringify(payload, null, 2));

      const response = await createCampaign(payload);
      console.log("Campaign created successfully:", response);
      alert("Offer activated successfully!");
    } catch (error) {
      console.error("Error creating campaign:", error.response?.data || error.message);
      alert(`Failed to create offer: ${error.response?.data?.message || "Please check your inputs."}`);
    }
  };
  const renderDiscountTypeDetails = () => {
    switch (formData.discountTypeCode) {
      case 0: // Percentage Discount
        return (
          <div>
            <label htmlFor="percentage" className="block text-sm font-semibold">Percentage:</label>
            <input type="number" value={formData.percentage} onChange={(e) => setFormData((prev) => ({ ...prev, percentage: e.target.value }))} className="mt-3 block w-full border px-2 py-1" />
            {errors.percentage && <p className="text-red text-sm">{errors.percentage}</p>}
            <label htmlFor="minimumOrderValue" className="block text-sm font-semibold mt-3">Minimum Order Value:</label>
            <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: e.target.value }))} className="mt-3 block w-full border px-2 py-1" />
            {errors.minimumOrderValue && <p className="text-red text-sm">{errors.minimumOrderValue}</p>}
            <label htmlFor="maxDiscountValue" className="block text-sm font-semibold mt-3">Max Discount Value:</label>
            <input type="number" value={formData.maxDiscountValue} onChange={(e) => setFormData((prev) => ({ ...prev, maxDiscountValue: e.target.value }))} className="mt-3 block w-full border px-2 py-1" />
            {errors.maxDiscountValue && <p className="text-red text-sm">{errors.maxDiscountValue}</p>}
          </div>
        );
      case 1: // Flat Discount
        return (
          <div className='mb-4'>
            <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
            <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.minimumOrderValue && <p className="text-red text-sm">{errors.minimumOrderValue}</p>}
            <label htmlFor="discountValue" className="block text-sm font-semibold">Discount Value:</label>
            <input type="number" value={formData.discountValue} onChange={(e) => setFormData((prev) => ({ ...prev, discountValue: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.discountValue && <p className="text-red text-sm">{errors.discountValue}</p>}

          </div>
        );
      case 2: // Flat Percentage Discount
        return (
          <div className='mb-4'>
            <label htmlFor="percentage" className="block text-sm font-semibold">Percentage:</label>
            <input type="number" value={formData.percentage} onChange={(e) => setFormData((prev) => ({ ...prev, percentage: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.percentage && <p className="text-red text-sm">{errors.percentage}</p>}
            <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
            <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.minimumOrderValue && <p className="text-red text-sm">{errors.minimumOrderValue}</p>}
          </div>
        );
      case 3: // Predefined Discount
        return (
          <div className='mb-4'>
            <label htmlFor="predefinedCouponCode" className="block text-sm font-semibold">Predefined Coupon Code:</label>
            <input type="text" value={formData.predefinedCouponCode} onChange={(e) => setFormData((prev) => ({ ...prev, predefinedCouponCode: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.predefinedCouponCode && <p className="text-red text-sm">{errors.predefinedCouponCode}</p>}
            <label htmlFor="couponName" className="block text-sm font-semibold">Coupon Name:</label>
            <input type="text" value={formData.couponName} onChange={(e) => setFormData((prev) => ({ ...prev, couponName: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.couponName && <p className="text-red text-sm">{errors.couponName}</p>}
            <label htmlFor="couponCode" className="block text-sm font-semibold">Coupon Code:</label>
            <input type="text" value={formData.couponCode} onChange={(e) => setFormData((prev) => ({ ...prev, couponCode: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.couponCode && <p className="text-red text-sm">{errors.couponCode}</p>}
          </div>
        );
      case 4: // Delivery Discount
        return (
          <div className='mb-4'>
            <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
            <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.minimumOrderValue && <p className="text-red text-sm">{errors.minimumOrderValue}</p>}
            <label htmlFor="maxDiscountRadius" className="block text-sm font-semibold">Max Discount Value:</label>
            <input type="number" value={formData.maxDiscountRadius} onChange={(e) => setFormData((prev) => ({ ...prev, maxDiscountRadius: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.maxDiscountRadius && <p className="text-red text-sm">{errors.maxDiscountRadius}</p>}
            <label htmlFor="radius" className="block text-sm font-semibold">Radius (in meters):</label>
            <input type="number" value={formData.radius} onChange={(e) => setFormData((prev) => ({ ...prev, radius: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.radius && <p className="text-red text-sm">{errors.radius}</p>}
            <div className="mt-2">
              <label htmlFor="isFlat" className="block text-sm font-semibold">Is Flat:</label>
              <input type="checkbox" checked={formData.isFlat} onChange={(e) => setFormData((prev) => ({ ...prev, isFlat: e.target.checked }))} />
              {errors.isFlat && <p className="text-red text-sm">{errors.isFlat}</p>}
            </div>
            <div className="mt-2">
              <label htmlFor="isMaxRadius" className="block text-sm font-semibold">Is Max Radius:</label>
              <input type="checkbox" checked={formData.isMaxRadius} onChange={(e) => setFormData((prev) => ({ ...prev, isMaxRadius: e.target.checked }))} />
              {errors.isMaxRadius && <p className="text-red text-sm">{errors.isMaxRadius}</p>}
            </div>
          </div>
        );
      case 5: // Custom Coupon Discount
        return (
          <div>
            <div className="mb-4">
              <label htmlFor="couponCode" className="block text-sm font-semibold">Coupon Code:</label>
              <input
                type="text"
                value={formData.couponCode}
                onChange={(e) => setFormData((prev) => ({ ...prev, couponCode: e.target.value }))}
                className="mt-3 block w-full border px-2 py-1"
              />
              {errors.couponCode && <p className="text-red text-sm">{errors.couponCode}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="nestedDiscountType" className="block text-sm font-semibold mt-3">Nested Discount Type:</label>
              <select
                value={formData.nestedDiscountType}
                onChange={(e) => setFormData((prev) => ({ ...prev, nestedDiscountType: e.target.value }))}
                className="mt-3 block w-full border px-2 py-1"
              >
                <option value="">Select Discount Type</option>
                <option value="0">Percentage Discount</option>
                <option value="1">Flat Discount</option>
                <option value="2">Flat Percentage Discount</option>
                <option value="3">Predefined Discount</option>
                <option value="4">Delivery Discount</option>
                <option value="6">Freebie Discount</option>
                <option value="7">BOGO Discount</option>
              </select>
              {errors.nestedDiscountType && <p className="text-red text-sm">{errors.nestedDiscountType}</p>}
              {/* Render nested discount details */}
              {renderNestedDiscountDetails()}
            </div>
          </div>
        );
      case 6: // Freebie Discount
        return (
          <div className='mb-4'>
            <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
            <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.minimumOrderValue && <p className="text-red text-sm">{errors.minimumOrderValue}</p>}
            <label htmlFor="offerItems" className="block text-sm font-semibold">Offer Items (comma-separated IDs):</label>
            {/* Display items in a row-wise layout */}
            <div className="border p-3 rounded-lg max-h-[150px] overflow-auto flex flex-wrap gap-2">
              {menuItems.map((item) => (
                <div key={item.id}
                  className={`cursor-pointer text-sm px-3 py-1 rounded-lg border ${formData.offerItems.includes(item.id) ? 'bg-green text-white' : 'bg-gray-100'}`}
                  onClick={() => handleOfferItemSelection(item)}
                >
                  {item.name}
                </div>
              ))}
            </div>
            {/* Selected Items Displayed in Input Field */}
            <input
              type="text"
              value={menuItems.filter(item => formData.offerItems.includes(item.id)).map(item => item.name).join(", ")}
              readOnly
              className="mt-2 block w-full border px-2 py-1 bg-gray-100 cursor-pointer"
              placeholder="Selected items will appear here"
            />
            {errors.offerItems && <p className="text-red text-sm">{errors.offerItems}</p>}
            <label htmlFor="maxItems" className="block text-sm font-semibold">Max Items:</label>
            <input type="number" value={formData.maxItems} onChange={(e) => setFormData((prev) => ({ ...prev, maxItems: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.maxItems && <p className="text-red text-sm">{errors.maxItems}</p>}
          </div>
        );
      case 7: // BOGO Discount
        return (
          <div className='mb-4'>
            <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
            <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.minimumOrderValue && <p className="text-red text-sm">{errors.minimumOrderValue}</p>}
            {/* Offer Items - New UI */}
            <label htmlFor="offerItems" className="block text-sm font-semibold">Offer Items (comma-separated IDs):</label>

            {/* Display items in a row-wise layout */}
            <div className="border p-3 rounded-lg max-h-[150px] overflow-auto flex flex-wrap gap-2">
              {menuItems.map((item) => (
                <div key={item.id}
                  className={`cursor-pointer text-sm px-3 py-1 rounded-lg border ${formData.offerItems.includes(item.id) ? 'bg-green text-white' : 'bg-gray-100'}`}
                  onClick={() => handleOfferItemSelection(item)}
                >
                  {item.name}
                </div>
              ))}
            </div>
            {/* Selected Items Displayed in Input Field */}
            <input
              type="text"
              value={formData.offerItems.join(", ")}
              readOnly
              className="mt-2 block w-full border px-2 py-1 bg-gray-100 cursor-pointer"
              placeholder="Selected items will appear here"
            />
            {errors.offerItems && <p className="text-red text-sm">{errors.offerItems}</p>}


            <label htmlFor="maxItems" className="block text-sm font-semibold">Max Items:</label>
            <input type="number" value={formData.maxItems} onChange={(e) => setFormData((prev) => ({ ...prev, maxItems: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.maxItems && <p className="text-red text-sm">{errors.maxItems}</p>}
          </div>
        );
      default:
        return null;
    }
  };

  // Function to render nested discount details for custom coupon discounts
  const renderNestedDiscountDetails = () => {
    switch (formData.nestedDiscountType) {
      case 0: // Percentage Discount
        return (
          <div className='mb-4'>
            <label htmlFor="percentage" className="block text-sm font-semibold">Percentage:</label>
            <input type="number" value={formData.percentage} onChange={(e) => setFormData((prev) => ({ ...prev, percentage: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.percentage && <p className="text-red text-sm">{errors.percentage}</p>}
            <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
            <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.minimumOrderValue && <p className="text-red text-sm">{errors.minimumOrderValue}</p>}
            <label htmlFor="maxDiscountValue" className="block text-sm font-semibold">Max Discount Value:</label>
            <input type="number" value={formData.maxDiscountValue} onChange={(e) => setFormData((prev) => ({ ...prev, maxDiscountValue: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.maxDiscountValue && <p className="text-red text-sm">{errors.maxDiscountValue}</p>}
          </div>
        );
      case 1: // Flat Discount
        return (
          <div className='mb-4'>
            <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
            <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.minimumOrderValue && <p className="text-red text-sm">{errors.minimumOrderValue}</p>}
            <label htmlFor="discountValue" className="block text-sm font-semibold">Discount Value:</label>
            <input type="number" value={formData.discountValue} onChange={(e) => setFormData((prev) => ({ ...prev, discountValue: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.discountValue && <p className="text-red text-sm">{errors.discountValue}</p>}
          </div>
        );
      case 2: // Flat Percentage Discount
        return (
          <div className='mb-4'>
            <label htmlFor="percentage" className="block text-sm font-semibold">Percentage:</label>
            <input type="number" value={formData.percentage} onChange={(e) => setFormData((prev) => ({ ...prev, percentage: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.percentage && <p className="text-red text-sm">{errors.percentage}</p>}
            <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
            <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.minimumOrderValue && <p className="text-red text-sm">{errors.minimumOrderValue}</p>}
          </div>
        );
      case 3: // Predefined Discount
        return (
          <div className='mb-4'>
            <label htmlFor="predefinedCouponCode" className="block text-sm font-semibold">Predefined Coupon Code:</label>
            <input type="text" value={formData.predefinedCouponCode} onChange={(e) => setFormData((prev) => ({ ...prev, predefinedCouponCode: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.predefinedCouponCode && <p className="text-red text-sm">{errors.predefinedCouponCode}</p>}
            <label htmlFor="couponName" className="block text-sm font-semibold">Coupon Name:</label>
            <input type="text" value={formData.couponName} onChange={(e) => setFormData((prev) => ({ ...prev, couponName: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.couponName && <p className="text-red text-sm">{errors.couponName}</p>}
            <label htmlFor="couponCode" className="block text-sm font-semibold">Coupon Code:</label>
            <input type="text" value={formData.couponCode} onChange={(e) => setFormData((prev) => ({ ...prev, couponCode: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.couponCode && <p className="text-red text-sm">{errors.couponCode}</p>}
          </div>
        );
      case 4: // Delivery Discount
        return (
          <div className='mb-4'>
            <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
            <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.minimumOrderValue && <p className="text-red text-sm">{errors.minimumOrderValue}</p>}
            <label htmlFor="maxDiscountRadius" className="block text-sm font-semibold">Max Discount Value:</label>
            <input type="number" value={formData.maxDiscountRadius} onChange={(e) => setFormData((prev) => ({ ...prev, maxDiscountRadius: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.maxDiscountRadius && <p className="text-red text-sm">{errors.maxDiscountRadius}</p>}
            <label htmlFor="radius" className="block text-sm font-semibold">Radius (in meters):</label>
            <input type="number" value={formData.radius} onChange={(e) => setFormData((prev) => ({ ...prev, radius: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.radius && <p className="text-red-500 text-sm">{errors.radius}</p>}
            <div className="mt-2">
              <label htmlFor="isFlat" className="block text-sm font-semibold">Is Flat:</label>
              <input type="checkbox" checked={formData.isFlat} onChange={(e) => setFormData((prev) => ({ ...prev, isFlat: e.target.checked }))} />
              {errors.isFlat && <p className="text-red text-sm">{errors.isFlat}</p>}
            </div>
            <div className="mt-2">
              <label htmlFor="isMaxRadius" className="block text-sm font-semibold">Is Max Radius:</label>
              <input type="checkbox" checked={formData.isMaxRadius} onChange={(e) => setFormData((prev) => ({ ...prev, isMaxRadius: e.target.checked }))} />
              {errors.isMaxRadius && <p className="text-red text-sm">{errors.isMaxRadius}</p>}
            </div>
          </div>
        );
      case 6: // Freebie Discount
        return (
          <div className='mb-4'>
            <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
            <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.minimumOrderValue && <p className="text-red text-sm">{errors.minimumOrderValue}</p>}
            <label htmlFor="offerItems" className="block text-sm font-semibold">Offer Items (comma-separated IDs):</label>
            <input type="text" value={formData.offerItems} onChange={(e) => setFormData((prev) => ({ ...prev, offerItems: e.target.value.split(','), }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.offerItems && <p className="text-red text-sm">{errors.offerItems}</p>}
            <label htmlFor="maxItems" className="block text-sm font-semibold">Max Items:</label>
            <input type="number" value={formData.maxItems} onChange={(e) => setFormData((prev) => ({ ...prev, maxItems: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.maxItems && <p className="text-red text-sm">{errors.maxItems}</p>}
          </div>
        );
      case 7: // BOGO Discount
        return (
          <div className='mb-4'>
            <label htmlFor="applicableItems" className="block text-sm font-semibold">Applicable Items (comma-separated IDs):</label>
            <input type="text" value={formData.offerItems} onChange={(e) => setFormData((prev) => ({ ...prev, offerItems: e.target.value.split(','), }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.offerItems && <p className="text-red text-sm">{errors.offerItems}</p>}
            <label htmlFor="discountItem" className="block text-sm font-semibold">Discount Item (0 for lowest priced, 1 for highest priced):</label>
            <input type="number" value={formData.discountItem} onChange={(e) => setFormData((prev) => ({ ...prev, discountItem: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.discountItem && <p className="text-red text-sm">{errors.discountItem}</p>}
            <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
            <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.minimumOrderValue && <p className="text-red text-sm">{errors.minimumOrderValue}</p>}
            <label htmlFor="maxPairs" className="block text-sm font-semibold">Max Pairs:</label>
            <input type="number" value={formData.maxPairs} onChange={(e) => setFormData((prev) => ({ ...prev, maxPairs: e.target.value }))} className="mt-1 block w-full border px-2 py-1" />
            {errors.maxPairs && <p className="text-red text-sm">{errors.maxPairs}</p>}
          </div>
        );
    }
  }

  return (
    <div>
      <div className={`fixed inset-0 z-50 offer ${show ? 'block' : 'hidden'}`} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <div ref={offerCanvasRef} className="fixed right-0 top-0 h-full overflow-y-auto bg-white w-1/3 p-4 shadow-lg">
          <div className="flex justify-between items-center mb-2">
            <h5 className="text-lg font-bold">Create New Offer</h5>
            <button type="button" className="text-gray-600" onClick={onHide}><FaTimes /></button>
          </div>
          <div className='flex flex-col gap-4'>
            <div className="grid grid-cols-2 gap-4">
              <div >
                <label htmlFor='startDate' className="block text-sm font-semibold leading-6 text-gray-900">Campaign Start Date</label>

                <input type='date' id='startDate' value={formData.campaignStartDate} onChange={(e) => setFormData((prev) => ({ ...prev, campaignStartDate: e.target.value }))} className="block flex-1  bg-transparent py-1.5 pl-1 text-gray-900 border w-full border px-2 py-1" />
                {errors.campaignStartDate && <p className="text-red text-sm">{errors.campaignStartDate}</p>}
              </div>
              <div>
                <label htmlFor='endDate' className="block text-sm font-semibold leading-6 text-gray-900">Campaign End Date</label>

                <input type='date' id='endDate' value={formData.campaignEndDate} onChange={(e) => setFormData((prev) => ({ ...prev, campaignEndDate: e.target.value }))} className="block flex-1  bg-transparent py-1.5 pl-1 text-gray-900 border w-full border  px-2 py-1" />
                {errors.campaignEndDate && <p className="text-red text-sm">{errors.campaignEndDate}</p>}
              </div>
            </div>

            <p className='text-sm text-gray-500  mt-3 pb-3 border-b-4 border-gray-300'>
              Your offer will start on {formData.campaignStartDate} and end on {formData.campaignEndDate}.
            </p>

            {/* Discount type selection */}
            <div className="grid grid-cols-2 gap-4 mt-3">
              <div>
                <label htmlFor="discountType" className="block text-sm font-semibold">Select Discount Type:</label>
                <select
                  value={formData.discountTypeCode}
                  onChange={handleDiscountTypeChange}
                  className="mt-1 block w-full border px-2 py-1"
                  disabled={!campaignPermissions?.isCouponCreationsPermitted}
                >
                  {campaignPermissions?.discountTypeCode?.map((type) => (
                    <option key={type} value={type}>{getDiscountTypeLabel(type)}</option>
                  ))}
                </select>
                {errors.discountTypeCode && <p className="text-red text-sm">{errors.discountTypeCode}</p>}
              </div>
              {/* New User Code Input */}
              <div>
                <label>User Code:</label>
                <select
                  value={formData.userCode}
                  onChange={(e) => setFormData({ ...formData, userCode: parseInt(e.target.value, 10) })}
                  className="border px-2 py-1 w-full"
                  disabled={!campaignPermissions?.isCouponCreationsPermitted}
                >
                  {campaignPermissions?.typesOfUsers?.map((userType) => (
                    <option key={userType} value={userType}>{getUserTypeLabel(userType)}</option>
                  ))}
                </select>
                {errors.userCode && <p className="text-red text-sm">{errors.userCode}</p>}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <label>Redeem Every:</label>
                <input type="number" step="0.5" min="0" max="4" value={formData.redeemEvery} onChange={(e) => setFormData({ ...formData, redeemEvery: e.target.value })} className="border px-2 py-1 w-full" />
                {errors.redeemEvery && <p className="text-red text-sm">{errors.redeemEvery}</p>}
              </div>
              {/* New Max Redemptions Per User Dropdown */}
              <div>
                <label className="text-sm font-semibold">Max Redemptions Per User:</label>
                <input type='number' min="0" max="4"
                  value={formData.maxRedemptionsPerUser}
                  onChange={(e) => setFormData({ ...formData, maxRedemptionsPerUser: e.target.value })}
                  className="border px-2 py-1 w-full"
                />
                {errors.maxRedemptionsPerUser && <p className="text-red text-sm">{errors.maxRedemptionsPerUser}</p>}
              </div>
            </div>

            {/* New Choose Your Service Dropdown */}
            <label className="text-sm font-semibold">Choose Your Service:</label>
            <select
              value={formData.selectedService}
              onChange={(e) => setFormData({
                ...formData,
                selectedService: e.target.value,
                isDining: e.target.value === "Dining"
              })}
              className="border px-2 py-1"
            >
              <option value="Takeaway">Takeaway</option>
              <option value="Delivery">Delivery</option>
              <option value="Dining">Dining</option>
            </select>

            {/* Render additional details based on discount type */}
            <div className="mt-4">{renderDiscountTypeDetails()}</div>

            <label htmlFor="exemptedItems" className="block text-sm font-semibold">Exempted Items:</label>
            <div className="border p-3 rounded-lg max-h-[150px] overflow-auto flex flex-wrap gap-2">
              {menuItems.map((item) => (
                <div key={item.id}
                  className={`cursor-pointer text-sm px-3 py-1 rounded-lg border ${formData.exemptedItems.some(exempted => exempted.id === item.id) ? 'bg-green text-white' : 'bg-gray-100'}`}
                  onClick={() => handleExemptedItemSelection(item)}
                >
                  {item.name}
                </div>
              ))}
            </div>
            <input
              type="text"
              value={formData.exemptedItems.map(item => item.name).join(", ")}
              readOnly
              className="mt-2 block w-full border px-2 py-1 bg-gray-100 cursor-pointer"
              placeholder="Selected items will appear here"
            />
            {errors.exemptedItems && <p className="text-red text-sm">{errors.exemptedItems}</p>}

            {/* Discount windows */}

            <div className='border-b-2 border-gray-300 pb-3'>
              <h6 className="font-semibold">Discount Window</h6>
              <div className="grid grid-cols-3 gap-2">
                <button className={`border px-3 py-2 rounded-lg text-sm ${formData.selectedDays.length === 7 ? 'bg-green text-white' : 'bg-gray-200'}`} onClick={() => handleDaySelection("All Days")}>All Days</button>
                {allDays.map((day) => (
                  <button key={day} className={`border px-3 py-2 rounded-lg text-sm ${formData.selectedDays.includes(day) ? 'bg-green text-white' : 'bg-gray-200'}`} onClick={() => handleDaySelection(day)}>{day}</button>
                ))}

              </div>
              {errors.selectedDays && <p className="text-red text-sm mt-1">{errors.selectedDays}</p>}
              {/* Time Slots for Selected Days */}
              {formData.selectedDays.length > 0 && (
                <div>
                  <label className="text-sm font-semibold">Set Time Slots:</label>
                  {Object.keys(formData.discountWindows).map((day) => (
                    <div key={day} className="border p-3 rounded-lg mt-2">
                      <h6 className="text-sm font-semibold mb-2">{day} Time Slots:</h6>
                      {formData.discountWindows[day]?.map((slot, index) => (
                        <div key={index} className="flex gap-2 mb-2">
                          <input
                            type="time"
                            value={slot.windowStart}
                            onChange={(e) => handleTimeChange(day, index, 'windowStart', e.target.value)}
                            className="border px-2 py-1 w-1/2"
                          />
                          <input
                            type="time"
                            value={slot.windowEnd}
                            onChange={(e) => handleTimeChange(day, index, 'windowEnd', e.target.value)}
                            className="border px-2 py-1 w-1/2"
                          />
                          <button
                            onClick={() => removeTimeSlot(day, index)}
                            className="text-red-500 text-sm font-bold"
                          >
                            ✖
                          </button>
                        </div>
                      ))}
                      {errors.discountWindows && errors.discountWindows[day] && (
                        <p className="text-red text-sm">{errors.discountWindows[day]}</p>
                      )}
                      {formData.discountWindows[day].length < 4 && (
                        <button
                          onClick={() => addTimeSlot(day)}
                          className="bg-green text-white px-2 py-1 rounded-lg text-sm"
                        >
                          + Add Time Slot
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>


            <button onClick={handleActivateOffer} className='bg-green text-white px-4 py-2 rounded-lg w-full'>Activate Offer</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewOffer;
