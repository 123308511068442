import React, { useEffect, useState, useRef } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { FaCopy, FaPaste } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
import { FaTrash, FaUpload } from 'react-icons/fa';
import { getLocalItem, setLocalItem } from '../../API/Localstorage.helpers';
import { fetchHashtags, menuItemsCreate } from '../../API/Api';

const Nested = ({ onBack, optionsData, variantsData, itemName, isSamePrice, takeaway, delivery, handleToggleChange, handleTakeawayChange, handleDeliveryChange, menuBasePrice, optionsGroupName, subCategoryId, restId }) => {
  const navigate = useNavigate()
  const [options, setOptions] = useState([]);
  const [variants, setVariants] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [addOnItems, setAddOnItems] = useState([{ itemName: "", additionalPrice: "" }]);
  const [selectedOption, setSelectedOption] = useState('');
  const [filteredVariants, setFilteredVariants] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [addOnGroupsForVariant, setAddOnGroupsForVariant] = useState({});
  const [varientDatas, setVarientDatas] = useState([]);
  const [copiedAddOns, setCopiedAddOns] = useState(null); // New state to store copied add-ons
  const basePrice = menuBasePrice;
  const optionName = optionsGroupName;
  const menuItemName = itemName;
  const menuSubCategoryId = subCategoryId;
  const restaurantId = restId;
  const [totalPrice, setTotalPrice] = useState(menuBasePrice || 0);

  // Fetch data passed from MenuDetails
  useEffect(() => {
    // Assuming optionsData and variantsData are passed from MenuDetails
    if (optionsData) {
      setOptions(optionsData);
    }
    if (variantsData) {
      setVariants(variantsData);
    }
  }, [optionsData, variantsData]);

  // Load stored data on mount
  useEffect(() => {
    const storedAddOnGroups = getLocalItem('addOnGroupsForVariant');
    const storedAddOnItems = getLocalItem('addOnItems');

    if (storedAddOnGroups) setAddOnGroupsForVariant(storedAddOnGroups);
    if (storedAddOnItems) setAddOnItems(storedAddOnItems);
  }, []);

  // Save data to local storage whenever changes occur
  useEffect(() => {
    setLocalItem('addOnGroupsForVariant', addOnGroupsForVariant);
    setLocalItem('addOnItems', addOnItems);
  }, [addOnGroupsForVariant, addOnItems]);

  /** ✅ Calculate Total Price */
  useEffect(() => {
    let calculatedTotal = parseFloat(menuBasePrice) || 0;

    options.forEach(option => {
      calculatedTotal += parseFloat(option.additionalPrice) || 0;
    });

    variants.forEach(variant => {
      calculatedTotal += parseFloat(variant.additionalPrice) || 0;
    });

    Object.values(addOnGroupsForVariant).forEach(groups => {
      groups.forEach(group => {
        group.items?.forEach(item => {
          calculatedTotal += parseFloat(item.additionalPrice) || 0;
        });
      });
    });

    setTotalPrice(calculatedTotal.toFixed(2)); // Set formatted total price
  }, [menuBasePrice, options, variants, addOnGroupsForVariant]);

  const handleVariantClick = (variantName) => {
    setSelectedVariant(variantName); // Set selected variant
    // Initialize the add-on group for the selected variant if not already initialized
    if (!addOnGroupsForVariant[variantName]) {
      setAddOnGroupsForVariant((prevState) => ({
        ...prevState,
        [variantName]: [{ name: '' }]
      }));
    }
  };
  /** ✅ Handles Add-On Group Name Change */
  const handleAddOnChange = (e, index, variantName) => {
    setAddOnGroupsForVariant((prev) => {
      const updatedGroups = [...prev[variantName]];
      updatedGroups[index].name = e.target.value;
      return { ...prev, [variantName]: updatedGroups };
    });
  };

  /** ✅ Adds a New Add-On Group */
  const addAddOnGroup = (variantName) => {
    setAddOnGroupsForVariant((prev) => ({
      ...prev,
      [variantName]: [...(prev[variantName] || []), { name: '', items: [] }]
    }));
  };

  /** ✅ Adds a New Add-On Item to a Group */
  /** ✅ Adds a New Add-On Item */
  const handleAddAddOnItem = (addOnName) => {
    setAddOnItems((prev) => {
      const updatedItems = { ...prev };

      // Ensure the add-on name key exists
      if (!updatedItems[addOnName]) {
        updatedItems[addOnName] = [];
      }

      // Add a new empty item
      updatedItems[addOnName] = [
        ...updatedItems[addOnName],
        { itemName: '', additionalPrice: '' }
      ];

      return updatedItems;
    });
  };

  /** ✅ Handles Add-On Item Input Change */
  const handleAddOnItemChange = (addOnName, itemIndex, event) => {
    if (!event || !event.target) {
      console.error("Invalid event passed to handleAddOnItemChange");
      return;
    }

    const { name, value } = event.target;

    setAddOnItems((prev) => {
      const updatedItems = { ...prev };

      // Ensure the add-on name key exists
      if (!updatedItems[addOnName]) {
        updatedItems[addOnName] = [];
      }

      // Ensure the item exists
      if (!updatedItems[addOnName][itemIndex]) {
        updatedItems[addOnName][itemIndex] = { itemName: '', additionalPrice: '' };
      }

      // Update the specific item's field
      updatedItems[addOnName][itemIndex] = {
        ...updatedItems[addOnName][itemIndex],
        [name]: value
      };

      return updatedItems;
    });
  };

  /** ✅ Copy & Paste Add-On Groups */
  const handleCopyAddOns = (fromVariant) => setCopiedAddOns(addOnGroupsForVariant[fromVariant]);
  const handlePasteAddOns = (toVariant) => {
    if (copiedAddOns) {
      setAddOnGroupsForVariant((prev) => ({
        ...prev,
        [toVariant]: copiedAddOns
      }));
    }
  };

  const handledata = (index) => {
    const datas = optionsData[index].varient
    setVarientDatas(datas)
    console.log(datas)
  }

  const handleOptionClick = (optionName) => {
    setSelectedOption(optionName); // Store selected option name

    // Filter variants associated with the selected option
    const associatedVariants = variants.filter(
      (variant) => variant.optionName === optionName
    );
    setFilteredVariants(associatedVariants);
  };

  // Handle Back Button
  const handleBackClick = () => {
    if (currentPage === 6) {
      setCurrentPage(2); // Go back to the fifth nested page (currentPage === 2)
    } else if (currentPage === 2) {
      setCurrentPage(1); // Go back to the first page
    } else {
      navigate('/menuhead?steps=MenuDetails&step1=3') // Call the parent back function if needed
    }
  };

  // Handle Continue Button
  const handleContinueClick = () => {
    setCurrentPage(2); // Move to the 5th Nested page
  };
  const handleCompleteClick = () => {
    setCurrentPage(6);
  };

  // Function to handle click event
  const handleAddOnClick = (addOnName) => {
    if (!selectedAddOns.includes(addOnName)) {
      setSelectedAddOns((prev) => [...prev, addOnName]); // Add the selected add-on group name
      setAddOnItems((prev) => ({
        ...prev,
        [addOnName]: [{ itemName: '', additionalPrice: '' }] // Initialize with one empty add-on item for the new group
      }));
    }
  };

  const [uploadedImage, setUploadedImage] = useState(null);
  const [compressedImage, setCompressedImage] = useState(null);
  const fileInputRef = useRef(null);

  /** ✅ Compress and Convert to WebP before Upload */
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        Resizer.imageFileResizer(
          file,
          800, // Max width
          800, // Max height
          "WEBP", // Convert to WebP
          80, // Quality (0 to 100)
          0, // Rotation
          (uri) => {
            setCompressedImage(uri);
            setUploadedImage(URL.createObjectURL(file)); // Preview original image
          },
          "blob" // Output format as Blob
        );
      } catch (err) {
        console.error("Image processing error:", err);
      }
    }
  };

  /** ✅ Delete Image */
  const handleDeleteImage = () => {
    setUploadedImage(null);
    setCompressedImage(null);
  };

  /** ✅ Trigger File Input */
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  // Menu Item Hashtags
  const [hashtags, setHashtags] = useState([]); // Stores API response hashtags
  const [selectedHashtags, setSelectedHashtags] = useState([]);
  const [suggestedHashtags, setSuggestedHashtags] = useState([]);

  useEffect(() => {
    getHashTags();
  })

  const getHashTags = async () => {
    try {
      const response = await fetchHashtags();
      console.log(response);
      if (response && Array.isArray(response)) {
        setHashtags(response.map(tag => tag.hashtag)); // Extracts only hashtag names
      }
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    if (itemName && hashtags.length > 0) {
      const words = itemName.toLowerCase().split(" ");
      let matchedTags = new Set();
      words.forEach(word => {
        hashtags.forEach(tag => {
          if (tag.toLowerCase().includes(word)) {
            matchedTags.add(tag);
          }
        });
      });
      setSuggestedHashtags(Array.from(matchedTags));
    }
  }, [itemName, hashtags]);

  const handleHashtagClick = (tag) => {
    if (!selectedHashtags.some(t => t.hashtagId === tag.hashtagId)) {
      setSelectedHashtags([...selectedHashtags, tag]);
    }
  };

  const handleRemoveHashtag = (tag) => {
    setSelectedHashtags(selectedHashtags.filter(t => t !== tag));
  };

  const handleMenuItemCreation = async () => {
    try {

      // Extract only the hashtag IDs from selectedHashtags
      const selectedHashtagIds = selectedHashtags.map(tag => tag.hashtagId);

      // Extract Base Item Details
      const menuItem = {
        restaurantId: restaurantId,
        menuSubCategoryId: menuSubCategoryId,
        data: {
          sortOrder: 1,  // Base sort order for the item
          hashTagIds: selectedHashtagIds,
          MenuItems: [
            {
              MenuItemRevision: [
                {
                  basePrice: parseFloat(menuBasePrice) || 0,
                  isEggiterian: false,
                  description: "Delicious meal",  // You can add a UI field for description
                  imageUrl: uploadedImage || "",
                  ingredients: null,
                  isVegetarian: true, // Can be updated dynamically based on selection
                  name: itemName,
                  preparationTime: 15,
                  isDeliverable: delivery,
                  isDineinAvailable: true,
                  isTakeaway: takeaway,
                  shareSlug: "",
                  isActiveRevision: true,
                  isApproved: true,
                  approvedAt: null,
                  approvedBy: null,
                  MenuItemsOptionsGroup: generateOptionsGroup()
                }
              ]
            }
          ]
        }
      };

      // Call API
      const response = await menuItemsCreate({ menuItems: [menuItem] });
      console.log("Menu item created successfully:", response);
      navigate('/menuhead');
    } catch (error) {
      console.error("Error creating menu item:", error);
    }
  };

  // Function to generate Options & Variants payload
  const generateOptionsGroup = () => {
    return optionsData.map((option, optionIndex) => ({
      sortOrder: optionIndex + 1,
      lowerLimit: 1,
      optionGroupDescription: "",
      optionGroupName: option.options,
      upperLimit: 1,
      MenuItemOption: [
        {
          additionalPrice: parseFloat(option.additionalPrice) || 0,
          description: "",
          name: option.name,
          optionImageUrl: "",
          sortOrder: optionIndex + 1,
          OptionsVarientsGroup: generateVariants(option.optionName)
        }
      ]
    }));
  };

  // Function to generate Variants payload
  const generateVariants = (optionName) => {
    const relatedVariants = variantsData.filter(variant => variant.optionName === optionName);
    return relatedVariants.map((variant, variantIndex) => ({
      sortOrder: variantIndex + 1,
      optionVariantGroupName: "Size",
      variantGroupDescription: "",
      OptionsVarientsItem: [
        {
          additionalPrice: parseFloat(variant.additionalPrice) || 0,
          variantDescription: "",
          sortOrder: variantIndex + 1,
          variantImageUrl: "",
          variantName: variant.variantName,
          OptionsAddonsGroup: generateAddOns(variant.variantName)
        }
      ]
    }));
  };

  // Function to generate Add-Ons payload
  const generateAddOns = (variantName) => {
    if (!addOnGroupsForVariant[variantName]) return [];

    return addOnGroupsForVariant[variantName].map((group, groupIndex) => ({
      sortOrder: groupIndex + 1,
      addOnGroupName: group.name,
      lowerLimit: 0,
      optionVariantId: "generated-id", // Can be replaced with actual IDs if available
      upperLimit: 5, // Change based on UI input if needed
      optionsAddOnsGroupDescription: "",
      OptionsAddOnsItem: generateAddOnItems(group.name)
    }));
  };

  // Function to generate Add-On Items
  const generateAddOnItems = (addOnGroupName) => {
    if (!addOnGroupsForVariant[addOnGroupName]) return [];

    return addOnGroupsForVariant[addOnGroupName].map((item, itemIndex) => ({
      addOnName: item.itemName,
      sortOrder: itemIndex + 1,
      addOnPrice: parseFloat(item.additionalPrice) || 0,
      addonsImageUrl: "",
      isEggiterian: false,
      isVegetarian: true
    }));
  };

  return (
    <div className='px-16 py-16 pt-10 bg-gray-50 h-screen'>
      {currentPage === 1 && (
        <>
          {/* Header with Back Button */}
          <div className="mb-4 flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-4">The Genie - Menu Creation Tool</h1>
          </div>

          {/* Base Price Input */}
          <div className="flex items-center">
            <BsArrowLeft onClick={handleBackClick} className="text-green text-2xl cursor-pointer mx-2" />
            <span className="font-semibold">Set Your Delivery Pricing</span>
          </div>
          <div className="mb-4 flex items-center justify-between mt-5">
            <div>
              <h2 className="text-lg flex-grow font-bold my-4">{menuItemName}</h2>
            </div>
            <div className='flex items-center'>
              <label className="block text-base font-medium text-gray-700 mb-1">Base Price</label>
              <input type="text" className="border border-gray-300 rounded-md text-center p-1 cursor-pointer ml-5" value={basePrice} readOnly />
            </div>
          </div>

          {/* Create Option Section */}
          <h2 className="text-lg font-bold">{optionName}</h2>
          <div className="mt-3">
            <h2 className="text-lg font-semibold">Options</h2>
            {optionsData?.length > 0 ? (
              optionsData.map((option, index) => (
                <div key={index} className="flex items-center mt-2 border border-gray-300 rounded p-2 bg-white cursor-pointer" onClick={() => handledata(index)}>
                  <span type="text" placeholder="Enter option name" className="flex-grow bg-white outline-none" onClick={() => handleOptionClick(option.optionName)} readOnly>{option.optionName}</span>
                  <span className="flex items-center">
                    +
                    <input type="text" placeholder="0.00" value={option.additionalPrice} className="w-20 text-center bg-white focus:outline-none cursor-pointer border border-gray-300 rounded p-1 mx-3" readOnly />
                  </span>
                </div>
              ))
            ) : (
              <p>No options added yet.</p>
            )}
          </div>

          {/* Variants Section */}
          <h2 className="text-lg font-semibold mt-3">
            Variants for <span className="font-normal">{selectedOption}</span>
          </h2>
          <div className="mt-2 flex items-center gap-5">
            {varientDatas.length > 0 ? (
              varientDatas.map((variant, index) => (
                <div key={index} className={`mt-2 border border-gray-300 rounded items-center p-2 bg-white flex w-full cursor-pointer ${selectedVariant === variant.variantName ? 'bg-green-100 text-green-700' : ''}`} onClick={() => handleVariantClick(variant.variantName)}>
                  <span className="flex-grow text-base font-medium">
                    {variant.variantName}
                  </span>
                  <span className="flex items-center">
                    +
                    <input type="text" placeholder="0.00" value={variant.additionalPrice} className="w-20 text-center bg-white focus:outline-none cursor-pointer border border-gray-300 rounded p-1 mx-3" readOnly />
                  </span>
                </div>
              ))
            ) : (
              <p>No variants added yet.</p>
            )}
          </div>

          {/* Create Add-On Group Section */}
          {selectedVariant && (
            <>
              <div className="mt-4 flex items-center justify-between">
                {/* Left Section: Add-on Group & Copy/Paste Buttons */}
                <div className="flex items-center gap-4">
                  <h2 className="text-lg font-semibold">Create Add-on Group for</h2>
                  <button type="button" className="bg-white border-2 px-4 py-2 rounded text-green font-bold">
                    {selectedVariant}
                  </button>

                  {/* Copy-Paste Buttons */}
                  <div className="flex items-center gap-4">
                    <button
                      type="button"
                      onClick={() => handleCopyAddOns(selectedVariant)}
                      className="bg-green flex items-center text-white px-4 py-2 rounded"
                    >
                      <FaCopy className="mr-1" />
                      Copy
                    </button>
                    <button
                      type="button"
                      onClick={() => handlePasteAddOns(selectedVariant)}
                      className="bg-green flex items-center text-white px-4 py-2 rounded"
                    >
                      <FaPaste className="mr-1" />
                      Paste
                    </button>
                  </div>
                </div>

                {/* Right Section: Total Price */}
                <div className="flex items-center gap-3">
                  <span className="text-lg font-semibold">Total Price:</span>
                  <input
                    type="text"
                    className="bg-white border-2 px-4 py-2 rounded text-black w-32 text-center cursor-pointer focus:outline-none"
                    value={totalPrice}
                    readOnly
                  />
                </div>
              </div>

              {addOnGroupsForVariant[selectedVariant]?.map((addOn, index) => (
                <div key={index} className="mt-3 flex items-center gap-5">
                  <input type="text" value={addOn.name} onChange={(e) => handleAddOnChange(e, index, selectedVariant)} className="w-full border border-gray-300 p-2 rounded" placeholder="Enter Add-on Group" />
                </div>
              ))}
              <div className="mt-4 flex justify-center">
                <button type="button" onClick={() => addAddOnGroup(selectedVariant)} className="bg-green text-white px-4 py-2 rounded">
                  + Add Another Add-On
                </button>
              </div>
            </>
          )}
          <div className="mt-4 flex justify-center">
            <button className="bg-green text-white px-4 py-2 rounded w-3/12" onClick={handleContinueClick}>
              Continue
            </button>
          </div>
        </>
      )}

      {currentPage === 2 && (
        <>
          {/* 5th Nested Page */}
          <div className="mb-4 flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-4">The Genie - Menu Creation Tool</h1>
          </div>
          <div className="flex items-center">
            <BsArrowLeft onClick={handleBackClick} className="text-green text-2xl cursor-pointer mx-2" />
            <span className="font-semibold">Set Your Delivery Pricing</span>
          </div>
          <div className="mb-4 flex items-center mt-5">
            <label className="block text-base font-medium text-gray-700 mb-1">Base Price</label>
            <input type="text" className="border border-gray-300 rounded-md p-1 ml-5 cursor-pointer focus:outline-none" value={basePrice} />
          </div>
          <h2 className="text-lg font-bold my-4">{options.optionName}</h2>
          <div className="mt-4">
            <h2 className="text-lg font-semibold">Options</h2>
            {options.length > 0 ? (
              options.map((option, index) => (
                <div key={index} className="flex items-center mt-2 border border-gray-300 rounded p-2 bg-white">
                  <span className="flex-grow bg-white outline-none cursor-pointer" onClick={() => handleOptionClick(option.optionName)}>{option.optionName}</span>
                  <span className="flex items-center">
                    Additonal Price
                    <input type="text" placeholder="0.00" value={option.additionalPrice} className="w-20 text-center bg-white focus:outline-none cursor-pointer border border-gray-300 rounded p-1 mx-3" />
                  </span>
                </div>
              ))
            ) : (
              <p>No options added yet.</p>
            )}
          </div>
          <h2 className="text-lg font-semibold mt-3">Variants for {selectedOption}</h2>
          <div className="mt-2 flex items-center gap-5 ">
            {varientDatas.length > 0 ? (
              varientDatas.map((variant, index) => (
                <div key={index} className={`mt-2 border border-gray-300 rounded p-2 bg-white flex w-full cursor-pointer ${selectedVariant === variant.variantName ? 'bg-green-100 text-green-700' : ''}`} onClick={() => handleVariantClick(variant.variantName)}>
                  <span className="block text-base font-medium">
                    {variant.variantName}
                  </span>
                </div>
              ))
            ) : (
              <p>No variants added yet.</p>
            )}
          </div>
          <h2 className="text-lg font-semibold mt-3">Created Add On Group for {selectedOption} in {selectedVariant}</h2>
          <div className="mt-4 flex items-center gap-5">
            {addOnGroupsForVariant[selectedVariant]?.length > 0 ? (
              addOnGroupsForVariant[selectedVariant].map((addOn, index) => (
                <div key={index} onClick={() => handleAddOnClick(addOn.name)} className="border border-gray-300 rounded p-2 cursor-pointer bg-white flex w-full">
                  <span
                    type="text"
                    className="block text-base font-medium w-32"
                    readOnly

                  >{addOn.name}</span>
                </div>
              ))
            ) : (
              <p>No add-on groups created yet for {selectedVariant}.</p>
            )}
          </div>
          {selectedAddOns && selectedAddOns.map((addOnName, idx) => (
            <div key={idx}>
              <h2 className="text-lg font-semibold mt-3">Add-on Items for {addOnName}</h2>
              <h2 className="text-base font-medium mt-3">Create Add On Items</h2>
              {addOnItems[addOnName]?.map((item, index) => (
                <div key={index} className="flex items-center mt-2 border border-gray-300 rounded p-2 bg-white">
                  <input type="text" name="itemName" placeholder="Enter Add On Item Name" value={item.itemName} onChange={(e) => handleAddOnItemChange(addOnName, index, e)} className="flex-grow bg-white outline-none" />
                  <span className="flex items-center">
                    Additional Price
                    <input type="text" name="additionalPrice" placeholder="0.00" value={item.additionalPrice} onChange={(e) => handleAddOnItemChange(addOnName, index, e)} className="w-20 text-center bg-white border border-gray-300 rounded p-1 mx-3" />
                  </span>
                </div>
              ))}
              <div className="mt-4 flex justify-center">
                <button type="button" onClick={() => handleAddAddOnItem(addOnName)} className="bg-green text-white px-4 py-2 rounded">
                  + Add Another Add On Item
                </button>
              </div>
            </div>
          ))}
          <div className="mt-4 flex justify-center">
            <button className="bg-green text-white px-4 py-2 rounded w-3/12" onClick={handleCompleteClick}>
              Completed
            </button>
          </div>
        </>
      )}
      {currentPage === 6 && (
        <>
          {/* Header for 6th nested page */}
          <div className="mb-4 flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-4">The Genie - Menu Creation Tool</h1>
          </div>
          <div className="flex items-center">
            <BsArrowLeft onClick={handleBackClick} className="text-green text-2xl cursor-pointer mx-2" />
            <span className="font-semibold">Categories</span>
          </div>
          <div className="mb-4 flex items-center mt-5">
            <input type="text" className="border border-gray-300 rounded-md p-2 w-full cursor-pointer focus:outline-none" value={itemName} readOnly />
          </div>
          <h2 className="text-base font-medium text-gray-700 my-4">Types</h2>
          <div className="mb-4">
            <div className="space-y-2 mb-2">
              <label className="inline-flex items-center">
                <input type="checkbox" className="form-checkbox text-green" checked={takeaway} />
                <span className="ml-2">Takeaway</span>
              </label>
            </div>
            <div className='space-y-2'>
              <label className="inline-flex items-center">
                <input type="checkbox" className="form-checkbox text-green" checked={delivery} />
                <span className="ml-2">Delivery</span>
              </label>
            </div>
          </div>
          <div className="mb-4 flex items-center">
            <span className="block text-base font-medium text-gray-700 mb-1">Same Price?</span>
            <div className="ml-4">
              <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" className="sr-only" checked={isSamePrice} />
                <div className={`w-11 h-6 rounded-full ${isSamePrice ? 'bg-green' : 'bg-gray-200'} transition-colors`}>
                  <div className={`dot absolute left-1 top-1 w-4 h-4 rounded-full bg-white transition-transform ${isSamePrice ? 'translate-x-5' : ''}`}></div>
                </div>
              </label>
            </div>
          </div>
          <div className="mt-6 flex items-center justify-between">
            <h2 className="text-lg font-semibold mb-4">Item Customization Completed</h2>
          </div>
          {/* Hashtag Selection Section */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">Select Hashtags</h2>
            <div className="mt-2 flex flex-wrap gap-2">
              {suggestedHashtags.map((tag, index) => (
                <button key={index} onClick={() => handleHashtagClick(tag)} className="px-3 py-1 border rounded-md bg-green text-white">{tag}</button>
              ))}
            </div>
          </div>

          {/* Selected Hashtags Display */}
          {selectedHashtags.length > 0 && (
            <div className="mt-4">
              <h2 className="text-lg font-semibold">Selected Hashtags</h2>
              <div className="mt-2 flex flex-wrap gap-2">
                {selectedHashtags.map((tag, index) => (
                  <span key={index} className="px-3 py-1 border rounded-md bg-green-300 flex items-center gap-2">
                    {tag}
                    <button onClick={() => handleRemoveHashtag(tag)} className="ml-2 text-red font-bold">✕</button>
                  </span>
                ))}
              </div>
            </div>
          )}
          {/* ✅ Image Upload Section */}
          <div className="mt-4 flex items-center">
            <h2 className="text-lg font-semibold mb-2">Upload Item Image</h2>
            <div className="border border-gray-300 mx-10 p-4 rounded-md relative flex justify-center items-center w-1/4 bg-gray-100">
              {uploadedImage ? (
                <div className="relative">
                  {/* Full-size Image Preview */}
                  <img src={uploadedImage} alt="Preview" className="object-cover rounded-md" />
                  {/* <img src={uploadedImage} alt="Preview" className="object-cover rounded-md border-2 border-gray-400" /> */}
                  {/* Delete Button as "X" in Top-Right Corner */}
                  <button
                    onClick={handleDeleteImage}
                    className="absolute top-0 right-0 bg-white border text-red font-bold p-1 rounded-full flex items-center justify-center w-6 h-6"
                  >
                    ✕
                  </button>
                </div>
              ) : (
                <button
                  onClick={triggerFileInput}
                  className="bg-green text-white px-6 py-3 rounded-md flex items-center gap-2"
                >
                  <FaUpload />
                  Upload Image
                </button>
              )}
              <input
                type="file"
                accept="image/*"
                className="hidden"
                ref={fileInputRef}
                onChange={handleImageUpload}
              />
            </div>
          </div>

          <div className="mt-6">
            <h2 className="text-lg font-semibold mb-2">Item Description</h2>
            {/* Message Input Box */}
            <textarea className="border border-gray-300 rounded-md p-2 w-full" placeholder="Enter item description here..." rows="3" style={{ minHeight: '100px', maxHeight: '250px', resize: 'vertical' }} />
          </div>
          <div className="mt-6 flex justify-center">
            <button className="bg-green text-white px-4 py-2 rounded w-3/12" onClick={handleMenuItemCreation}>Completed</button>
          </div>
        </>
      )}
    </div>
  );
};
export default Nested;