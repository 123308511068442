import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getRemoteConfig, fetchAndActivate, getAll } from "firebase/remote-config";

// Your Firebase Config - Replace with actual values
const firebaseConfig = {
    apiKey: "AIzaSyBo6TgYTQ8tCK4qxyP0KP4TJSwoi0OT4pQ",
    authDomain: "the-genie-merchant-app.firebaseapp.com",
    projectId: "the-genie-merchant-app",
    storageBucket: "the-genie-merchant-app.firebasestorage.app",
    messagingSenderId: "720612560850",
    appId: "1:720612560850:web:bedb61acc051489a08020b",
    measurementId: "G-B55CLH7YWP"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const remoteConfig = getRemoteConfig(app);

// Configure Remote Config settings
remoteConfig.settings = {
  minimumFetchIntervalMillis: 3600000, // Fetch new values every 1 hour
};

// Fetch and activate Remote Config values
const fetchRemoteConfig = async () => {
  try {
    await fetchAndActivate(remoteConfig);
    console.log("🔥 Remote Config Fetched & Activated");

    // Get all parameters
    const allParams = getAll(remoteConfig);
    const configValues = {};

    Object.keys(allParams).forEach((key) => {
      configValues[key] = allParams[key].asString(); // Convert values to string
    });

    console.log("📌 All Remote Config Values:", configValues);
  } catch (error) {
    console.error("❌ Error fetching Remote Config:", error);
  }
};

// Function to log page views
const logPageView = (page) => {
  logEvent(analytics, "page_view", { page_path: page });
};

export { analytics, logPageView, remoteConfig, fetchRemoteConfig };
