import React, { useEffect, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { FaEdit, FaTrash, FaCopy, FaPaste } from 'react-icons/fa';
import Nested from './Nested';
import NoCustomize from './NoCustomize';
import { getLocalItem, setLocalItem } from '../../API/Localstorage.helpers';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { fetchMenuDetails } from '../../API/Api';
import IndependentPricing from './IndependentPricing';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const MenuDetails = ({ onBack }) => {
  const query = useQuery()
  const [searchParams] = useSearchParams();
  const subCategoryIdFromURL = searchParams.get("subCategoryId");
  const [subCategoryId, setSubCategoryId] = useState(subCategoryIdFromURL || localStorage.getItem("subCategoryId"));
  const navigate = useNavigate()
  const item = getLocalItem('MenuDetailsItem')
  const [isNextClicked, setIsNextClicked] = useState(false);
  const [isSectionPage3, setIsSectionPage3] = useState(false);
  const [isSamePrice, setIsSamePrice] = useState(false);
  const [isCustomizable, setIsCustomizable] = useState(null);
  const [options, setOptions] = useState(['']);
  const [optionItems, setOptionItems] = useState([{ optionName: '', additionalPrice: '' }]);
  const [basePrice, setBasePrice] = useState('');
  const [variants, setVariants] = useState([{ variantName: '', additionalPrice: '' }]);
  const [variantGroupName, setVariantGroupName] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [showNested, setShowNested] = useState(false);
  const [takeaway, setTakeaway] = useState(false); // Checkbox state for Takeaway
  const [delivery, setDelivery] = useState(false); // Checkbox state for Delivery
  const [selectedBox, setSelectedBox] = useState(null);
  const [copiedSections, setCopiedSections] = useState([]);
  const [optionState, setOptionState] = useState([])
  const restaurantId = localStorage.getItem("restaurantId");
  const [categoryName, setCategoryName] = useState();
  const [subCategoryName, setSubCategoryName] = useState();
  const [menuItemName, setMenuItemName] = useState('');
  const [error, setError] = useState("");

  useEffect(() => {
    if (subCategoryId) {
      console.log("Retrieved subCategoryId:", subCategoryId);
      fetchMenu(subCategoryId);
    }
  }, [subCategoryId]);

  useEffect(() => {
    // Fetching saved values from localStorage
    const savedMenuItemName = getLocalItem("menuItemName");
    const savedTakeaway = getLocalItem("takeaway");
    const savedDelivery = getLocalItem("delivery");
    const savedIsSamePrice = getLocalItem("isSamePrice");
    const savedIsCustomizable = getLocalItem("isCustomizable");
    const savedSelectedBox = getLocalItem("selectedBox");
    const savedBasePrice = getLocalItem("basePrice");
    const savedOptions = getLocalItem("options");
    const savedOptionItems = getLocalItem("optionItems");
    const savedOptionState = getLocalItem("optionState"); // ✅ Restoring option state (including variants)
    const savedVariantGroupName = getLocalItem("variantGroupName"); // ✅ Restoring variant group name
    const savedVariants = getLocalItem("variants"); // ✅ Restoring saved variants

    // Setting state with saved values if they exist
    if (savedMenuItemName) setMenuItemName(savedMenuItemName);
    if (savedTakeaway !== null) setTakeaway(savedTakeaway);
    if (savedDelivery !== null) setDelivery(savedDelivery);
    if (savedIsSamePrice !== null) setIsSamePrice(savedIsSamePrice);
    if (savedIsCustomizable !== null) setIsCustomizable(savedIsCustomizable);
    if (savedSelectedBox !== null) setSelectedBox(savedSelectedBox);
    if (basePrice !== null) setBasePrice(savedBasePrice);
    if (savedOptions) setOptions(savedOptions);
    if (savedOptionItems) setOptionItems(savedOptionItems);
    if (savedOptionState) setOptionState(savedOptionState); // ✅ Load saved options with variants
    if (savedVariantGroupName) setVariantGroupName(savedVariantGroupName); // ✅ Load saved variant group name
    if (savedVariants) setVariants(savedVariants); // ✅ Load saved variants

  }, []);

  // ✅ Ensure first option is selected by default if not already set
  useEffect(() => {
    if (options.length > 0 && !selectedOption) {
      console.log("Setting first option as selected:", options[0].optionName); // Debugging ✅
      setSelectedOption(options[0].optionName);
    }
  }, [options, selectedOption]);


  useEffect(() => {
    let timer;

    const startTimer = () => {
      clearTimeout(timer); // ✅ Reset previous timer
      timer = setTimeout(() => {
        console.log("Saving data to localStorage..."); // ✅ Debugging
        setLocalItem("menuItemName", menuItemName);
        setLocalItem("takeaway", takeaway);
        setLocalItem("delivery", delivery);
        setLocalItem("isSamePrice", isSamePrice);
        setLocalItem("isCustomizable", isCustomizable);
        setLocalItem("selectedBox", selectedBox);
        setLocalItem("basePrice", basePrice);
        setLocalItem("options", options);
        setLocalItem("optionItems", optionItems);
        setLocalItem("optionState", optionState); // ✅ Saving option state (with variants)
        setLocalItem("variantGroupName", variantGroupName); // ✅ Saving variant group name
        setLocalItem("variants", variants); // ✅ Saving all variants
      }, 5000); // ✅ 5 seconds of inactivity
    };

    startTimer(); // ✅ Start/reset the timer

    return () => {
      console.log("Resetting Timer..."); // ✅ Debugging
      clearTimeout(timer);
    };
  }, [menuItemName, takeaway, delivery, isSamePrice, isCustomizable, selectedBox, basePrice, options, optionItems, optionState, variantGroupName, variants]);

  const fetchMenu = async () => {
    try {
      const data = await fetchMenuDetails(restaurantId); // Fetch menu details
      const menuData = Array.isArray(data.menuCategories) ? data.menuCategories : [];

      console.log("Menu Data Menu Details:", menuData);
      menuData.forEach((category) => {
        // category.name
        category.MenuSubCategory.forEach((subCategory) => {
          if (subCategory.id === subCategoryId) {
            console.log("categories", category.name);
            setCategoryName(category.name);
            setSubCategoryName(subCategory.name);
            setLocalItem("categoryName", category.name);
            setLocalItem("subCategoryName", subCategory.name);
          }
        });
      });

    } catch (error) {
      console.error("Error fetching menu details:", error.response?.data || error.message);
    }
  };

  const varientOnClick = (name) => {
    setSelectedOption(name);
    setOptionState((prev) => {
      // Create a copy of the previous state (array of options)
      const updatedOptions = [...prev];

      // Update the optionName of the first (0th) option
      updatedOptions[0] = {
        ...updatedOptions[0],    // Spread the existing fields of the first option
        optionName: name         // Set the new optionName
      };

      // Return the updated options array
      return updatedOptions;
    });
  };

  const handleCostamizable = (value) => {
    if (value) {
      setIsCustomizable(value)
    }
    else {
      setIsCustomizable(value)
    }
  }

  const addOptionVariant = (index) => {
    setOptionState(prev => {
      const updatedOptions = [...prev];

      // ✅ Find the selected option
      const selectedOptionIndex = updatedOptions.findIndex(opt => opt.optionName === selectedOption);

      if (selectedOptionIndex !== -1) {
        // ✅ Update variants for the selected option
        updatedOptions[selectedOptionIndex] = {
          ...updatedOptions[selectedOptionIndex],
          varient: [...updatedOptions[selectedOptionIndex].varient, { variantName: '', additionalPrice: '' }]
        };
      }

      return updatedOptions;
    });
  };


  const addVariant = (sectionIndex = null) => {
    if (sectionIndex === null) {
      // Add variant to original section
      setVariants([...variants, { variantName: '', additionalPrice: '', }]);
    } else {
      // Add variant to specific copied section
      const updatedCopiedSections = [...copiedSections];
      updatedCopiedSections[sectionIndex].variants.push({ variantName: '', additionalPrice: '' });
      setCopiedSections(updatedCopiedSections);
    }
  };

  const handleEdit = (index) => {
    // console.log(Edit variants at index: ${index});
  };


  const handleDelete = (index, sectionIndex = null) => {
    if (sectionIndex === null) {
      const newVariants = variants.filter((_, i) => i !== index);
      setVariants(newVariants);
    } else {
      const updatedCopiedSections = [...copiedSections];
      updatedCopiedSections[sectionIndex].variants = updatedCopiedSections[sectionIndex].variants.filter((_, i) => i !== index);
      setCopiedSections(updatedCopiedSections);
    }
  };
  const handleDeleteVariant = (optionIndex, variantIndex) => {
    setOptionState((prev) => {
      // Create a copy of the state
      const updatedOptions = [...prev];

      // Create a copy of the specific option's varient array
      const updatedVariants = [...updatedOptions[optionIndex].varient];

      // Remove the variant at variantIndex
      updatedVariants.splice(variantIndex, 1);

      // Update the varient array of the option at optionIndex
      updatedOptions[optionIndex] = {
        ...updatedOptions[optionIndex],
        varient: updatedVariants
      };

      // Return the updated state
      return updatedOptions;
    });
  };

  const handleBasePriceChange = (event) => {
    const value = event.target.value.replace(/[^0-9.]/g, ""); // Ensure only numbers & decimal
    setBasePrice(value);
  };


  const handleOptionChange = (index, event) => {
    const { name, value } = event.target;

    setOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value; // Update option name
      return updatedOptions;
    });
  };

  const handleOptionItemChange = (index, event) => {
    const { name, value } = event.target;

    setOptionItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index] = {
        ...updatedItems[index], // Keep existing values
        [name]: name === "additionalPrice" && value === "" ? "0.0" : value, // Default to 0.0 if empty
      };
      return updatedItems;
    });
  };

  const addOption = () => {
    setOptionItems([...optionItems, { optionName: '', additionalPrice: '', varients: [{ varientName: '', additionalPrice: '' }] }]);
  };

  const handleBoxSelect = (boxIndex) => {
    setSelectedBox(boxIndex);
    setIsNextClicked(false); // Reset "Next" clicked state when changing box
  };

  const handleTakeawayChange = (e) => setTakeaway(e.target.checked);
  const handleDeliveryChange = (e) => setDelivery(e.target.checked);
  const handleToggleChange = (e) => setIsSamePrice(e.target.checked);

  const isNextEnabled1 = menuItemName && (takeaway || delivery) && (selectedBox !== null);

  const isNextEnabled2 = () => {
    return (
      menuItemName.trim() !== "" &&
      (takeaway || delivery) && // At least one type selected
      options.every(opt => opt.trim() !== "") && // Ensure options are not empty
      optionItems.every(item => item.optionName.trim() !== "" && item.additionalPrice !== "")
    );
  };

  // ✅ Handle Variant Changes
  // const handleVariantChanges = (optionIndex, variantIndex, event) => {
  //   const { name, value } = event.target;

  //   setOptionState((prev) => {
  //     return prev.map((option, optIndex) => {
  //       if (option.optionName === selectedOption) {
  //         // ✅ Create a new copy of the variant array
  //         const updatedVariants = option.varient.map((variant, varIndex) => {
  //           if (varIndex === variantIndex) {
  //             return { ...variant, [name]: value.trim() };
  //           }
  //           return variant;
  //         });

  //         return { ...option, varient: updatedVariants };
  //       }
  //       return option;
  //     });
  //   });

  //   // ✅ Validation check
  //   if (name === "variantName" && value.trim() === "") {
  //     setError("Variant name cannot be empty.");
  //   } else if (name === "additionalPrice" && isNaN(value)) {
  //     setError("Additional Price must be a valid number.");
  //   } else {
  //     setError(""); // ✅ Clear error if valid
  //   }
  // };

  // ✅ Handle Variant Changes
  const handleVariantChanges = (optionIndex, variantIndex, event) => {
    const { name, value } = event.target;

    setOptionState((prev) => {
      return prev.map((option, optIndex) => {
        if (option.optionName === selectedOption) {
          // ✅ Create a new copy of the variant array
          const updatedVariants = option.varient.map((variant, varIndex) => {
            if (varIndex === variantIndex) {
              return { ...variant, [name]: value.trim() };
            }
            return variant;
          });

          return { ...option, varient: updatedVariants };
        }
        return option;
      });
    });

    // ✅ Validation check
    if (name === "variantName" && value.trim() === "") {
      setError("Variant name cannot be empty.");
    } else if (name === "additionalPrice" && isNaN(value)) {
      setError("Additional Price must be a valid number.");
    } else {
      setError(""); // ✅ Clear error if valid
    }
  };

  // ✅ Handle Variant Group Name Change
  const handleVariantGroupNameChange = (event) => {
    const value = event.target.value.trim();
    setVariantGroupName(value);

    // Validation check
    if (value === "") {
      setError("Variant Group Name is required.");
    } else {
      setError("");
    }
  };

  // ✅ Ensure 'Continue' button is enabled only if all fields are valid
  // const isNextEnabled3 = () => {
  //   return (
  //     basePrice.trim() !== "" &&
  //     options.every(opt => opt.trim() !== "") &&
  //     optionItems.every(item => item.optionName.trim() !== "" && item.additionalPrice !== "") &&
  //     optionState.every(option =>
  //       option.varient.every(variant => variant.variantName.trim() !== "" && variant.additionalPrice !== "")
  //     ) &&
  //     variantGroupName.trim() !== "" // ✅ Ensures variant group name is not empty
  //   );
  // };

  const isNextEnabled3 = () => {
    console.log("Base Price:", basePrice);
    console.log("Options:", options);
    console.log("Option Items:", optionItems);
    console.log("Option State:", optionState);
    console.log("Variant Group Name:", variantGroupName);

    return (
      basePrice.trim() !== "" &&
      options.every(opt => opt.trim() !== "") &&
      optionItems.every(item => item.optionName.trim() !== "" && item.additionalPrice !== "") &&
      // ✅ Ensure all options have a name (no empty options)
      optionState.filter(option => option.optionName.trim() !== "").every(option =>
        option.varient.filter(variant => variant.variantName.trim() !== "").every(variant =>
          variant.variantName.trim() !== "" &&
          (variant.additionalPrice === "" || !isNaN(parseFloat(variant.additionalPrice))) // ✅ Accepts valid numbers including zero
        )
      ) &&
      variantGroupName.trim() !== ""
    );
  };

  // ✅ State for storing copied variant data
  const [copiedVariantData, setCopiedVariantData] = useState('');

  // // ✅ Function to handle selecting a new option
  // const handleOptionClick = (optionName) => {
  //   setSelectedOption(optionName);

  //   // ✅ Find existing variants for the selected option
  //   const existingOption = optionState.find(opt => opt.optionName === optionName);

  //   if (existingOption) {
  //     // ✅ If option already has variants, show them
  //     setVariantGroupName(existingOption.variantGroupName || "");
  //     setOptionState([...optionState]); // Keep the same state reference
  //   } else {
  //     // ✅ If no variants exist, reset fields
  //     setVariantGroupName("");
  //     setOptionState([
  //       ...optionState,
  //       { optionName: optionName, variantGroupName: "", varient: [{ variantName: '', additionalPrice: '' }] }
  //     ]);
  //   }
  // };

  const handleOptionClick = (optionName) => {
    setSelectedOption(optionName);

    setOptionState(prev => {
      const existingOption = prev.find(opt => opt.optionName === optionName);

      if (existingOption) {
        return prev; // ✅ Don't add duplicate options
      } else {
        const matchedOption = optionItems.find(opt => opt.optionName === optionName);
        return [...prev, {
          optionName,
          additionalPrice: matchedOption ? matchedOption.additionalPrice : "0", // ✅ Store additionalPrice
          varient: [{ variantName: '', additionalPrice: '' }]
        }];
      }
    });
  };


  // ✅ Function to copy variant data
  const handleCopyVariants = () => {
    if (!selectedOption) return; // ✅ Prevent copying if no option is selected

    const selectedOptionData = optionState.find(opt => opt.optionName === selectedOption);

    if (selectedOptionData) {
      setCopiedVariantData({
        variantGroupName: variantGroupName, // ✅ Copy the group name
        variants: selectedOptionData.varient.map(v => ({ ...v })) // ✅ Deep copy of variants
      });

      console.log("Copied Variant Data:", {
        variantGroupName,
        variants: selectedOptionData.varient
      });
    }
  };

  // ✅ Function to paste copied variant data into the selected option
  const handlePasteVariants = (targetOptionName) => {
    if (!copiedVariantData || !targetOptionName) return; // ✅ Prevent pasting if no data copied

    setOptionState(prev => {
      return prev.map(opt => {
        if (opt.optionName === targetOptionName) {
          return {
            ...opt,
            varient: [...copiedVariantData.variants], // ✅ Paste copied variants
          };
        }
        return opt;
      });
    });

    setVariantGroupName(copiedVariantData.variantGroupName); // ✅ Paste the copied variant group name

    console.log("Pasted Variant Data:", copiedVariantData);
  };

  const handleNameChange = (e) => {
    let value = e.target.value; // Use `let` instead of `const`

    const maxLength = 50; // Define max length

    // Validation checks
    if (!value) {
      setError("Menu item name cannot be empty.");
    } else if (value.length > maxLength) {
      setError(`Menu item name cannot exceed ${maxLength} characters.`);
    } else {
      setError(""); // Clear error if valid
    }

    setMenuItemName(value);
  };


  // Handle Back navigation in MenuDetails
  const handleBackClick = () => {
    if (isSectionPage3) {
      setIsSectionPage3(false);
      setIsNextClicked(true);
    } else if (isNextClicked) {
      setIsNextClicked(false);
    } else if (!isCustomizable && selectedBox === 2) {
      setIsCustomizable(true); // or any other state reset logic
    }
  };

  // Handle the transition to Nested
  const handleContinueClick = () => {
    setShowNested(true);
  };

  const handleCopy = () => {
    setOptionState(prev => ([...prev, optionState[0]]))
  };

  // Render Nested component if showNested is true
  if (query.get('step1') === '4') {
    return (
      <Nested
        onBack={() => setShowNested(false) || setIsSectionPage3(true)} // Go back to isSectionPage3
        itemName={menuItemName} // Pass item name
        isSamePrice={isSamePrice} // Pass isSamePrice state
        takeaway={takeaway} // Pass takeaway state
        delivery={delivery} // Pass delivery state
        menuBasePrice={basePrice}
        handleToggleChange={handleToggleChange} // Pass toggle change handler
        handleTakeawayChange={handleTakeawayChange} // Pass takeaway change handler
        handleDeliveryChange={handleDeliveryChange} // Pass delivery change handler
        optionsData={optionState}
        variantsData={variants}
        optionsGroupName={options}
        subCategoryId={subCategoryId}
        restId={restaurantId}
      />
    );
  }

  if (!isCustomizable && isNextClicked) {
    return (
      <NoCustomize
        onBack={handleBackClick}
        itemName={item?.name}
        takeaway={takeaway}
        delivery={delivery}
        isSamePrice={isSamePrice}
        isCustomizable={isCustomizable}
        basePrice={basePrice}
      />
    );
  }

  if (query.get('step1') === '5' && isNextClicked && selectedBox === 2) {
    return (
      <IndependentPricing
        itemName={menuItemName}
        subCategoryId={subCategoryId}
        restId={restaurantId}
        menuBasePrice={basePrice}
      />
    );
  }

  return (
    <div className='px-16 py-16 pt-10 bg-gray-50 h-screen overflow-auto'>
      {query.get('step1') === '1' ? (
        <div className="p-4 bg-gray-50 h-screen mb-8">
          <div className="mb-4 flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-4">The Genie - Menu Creation Tool</h1>
          </div>
          <div className="flex items-center">
            <BsArrowLeft onClick={onBack} className="text-green text-2xl cursor-pointer mx-2" />
            <p className="font-semibold">{categoryName + " -> " + subCategoryName}</p>
          </div>
          <div className="mb-4 flex items-center mt-5">
            <input type="text" className="border border-gray-300 rounded-md p-2 w-full" placeholder="Enter Menu Item Name" value={menuItemName} onChange={handleNameChange} />
            {error && <p className="text-red-500 text-sm">{error}</p>}
          </div>
          <h2 className="text-base font-medium text-gray-700 my-4">Types</h2>
          <div className="mb-4">
            <div className="space-y-2 mb-2">
              <label className="inline-flex items-center">
                <input type="checkbox" className="form-checkbox text-green" checked={takeaway} onChange={handleTakeawayChange} />
                <span className="ml-2">Takeaway</span>
              </label>
            </div>
            <div className='space-y-2'>
              <label className="inline-flex items-center">
                <input type="checkbox" className="form-checkbox text-green" checked={delivery} onChange={handleDeliveryChange} />
                <span className="ml-2">Delivery</span>
              </label>
            </div>
          </div>
          {(takeaway && delivery) ? (
            <div className="mb-4 flex items-center">
              <span className="block text-base font-medium text-gray-700 mb-1">Same Price?</span>
              <div className="ml-4">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" className="sr-only" onChange={handleToggleChange} checked={isSamePrice} />
                  <div className={`w-11 h-6 rounded-full ${isSamePrice ? 'bg-green' : 'bg-gray-200'} transition-colors`}>
                    <div className={`dot absolute left-1 top-1 w-4 h-4 rounded-full bg-white transition-transform ${isSamePrice ? 'translate-x-5' : ''}`}></div>
                  </div>
                </label>
              </div>
            </div>)
            : (
              <div className="w-11 h-6"></div> // ✅ Placeholder to avoid layout shift
            )
          }
          <div className="mb-4 flex items-center space-x-4">
            <span className="text-base font-medium text-gray-700">Customizable?</span>
            <button className={`px-4 py-2 border rounded-md ${isCustomizable ? 'bg-green text-white' : 'bg-gray-300 text-black'}`} onClick={() => { handleCostamizable(true) }}>
              Yes
            </button>
            <button className={`px-4 py-2 border rounded-md ${!isCustomizable ? 'bg-green text-white' : 'bg-gray-300 text-black'}`} onClick={() => { handleCostamizable(false) }}>
              No
            </button>
          </div>
          <h2 className="text-lg font-bold my-4">Pricing Structure</h2>
          <div className="flex justify-start bg-white space-x-4 w-fit">
            <div className={`border-2 p-4 w-64 relative cursor-pointer 
            ${selectedBox === 1 ? 'border-green bg-lime-300' : 'border-green'}`}
              onClick={() => handleBoxSelect(1)}>
              <div className="relative mb-8">
                <div className="font-semibold">Option</div>
                <div className="absolute h-10 border-l-2 border-dotted border-black left-0 top-6"></div>
                <div className="absolute w-8 border-t-2 border-dotted border-black left-0 top-16"></div>
              </div>
              <div className="relative mb-8 ml-8">
                <div className="font-semibold ml-2">Variant</div>
                <div className="absolute h-10 border-l-2 border-dotted border-black left-0 top-6"></div>
                <div className="absolute w-8 border-t-2 border-dotted border-black left-0 top-16"></div>
              </div>
              <div className="relative ml-16">
                <div className="font-semibold ml-2">Add On</div>
              </div>
            </div>
            <div className={`border-2 p-4 w-64 relative cursor-pointer 
            ${selectedBox === 2 ? 'border-green bg-lime-300' : 'border-green'}`}
              onClick={() => handleBoxSelect(2)}>
              <div className="relative mb-8">
                <div className="font-semibold">Option</div>
                <div className="absolute h-10 border-l-2 border-dotted border-black left-0 top-6"></div>
              </div>
              <div className="relative mb-8 ">
                <div className="font-semibold ml-2">Variant</div>
                <div className="absolute h-10 border-l-2 border-dotted border-black left-0 top-6"></div>
              </div>
              <div className="relative">
                <div className="font-semibold ml-2">Add On</div>
              </div>
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <button
              onClick={() => {
                setIsNextClicked(true); // ✅ Mark "Next" as clicked

                if (selectedBox === 2) {
                  navigate('/menuhead?steps=MenuDetails&step1=5'); // ✅ Redirect to Independent Pricing
                } else {
                  navigate('/menuhead?steps=MenuDetails&step1=2'); // ✅ Continue with the normal flow
                }
              }}
              className={`px-4 py-2 rounded w-3/12 ${isNextEnabled1 ? 'bg-green text-white' : 'bg-gray-300 text-black cursor-not-allowed'}`}
              disabled={!isNextEnabled1}
            >
              Next
            </button>
          </div>
        </div>) : ''
      }
      {query.get('step1') === '3' ? (
        <div className="p-4">
          <div className="mb-4 flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-4">The Genie - Menu Creation Tool</h1>
          </div>

          {/* Back Button */}
          <div className="flex items-center">
            <BsArrowLeft onClick={() => { navigate('/menuhead?steps=MenuDetails&step1=2') }} className="text-green text-2xl cursor-pointer mx-2" />
            <span className="font-semibold">Set Your Delivery Pricing</span>
          </div>

          {/* Base Price Input */}
          <div className="mb-4 flex items-center mt-5">
            <label className="block text-base font-medium text-gray-700 mb-1">
              Base Price
            </label>
            <span className="flex items-center mx-5">
              <input type="text" className="border border-gray-300 rounded-md p-1" placeholder="Base Price" value={basePrice} />
            </span>
          </div>

          {/* Created Options List */}
          <h2 className="text-lg font-bold my-4">{options}</h2>
          <div className="mt-4">
            {optionItems.map((option, index) => (
              <div key={index} className="flex items-center mt-2 border border-gray-300 rounded p-2 bg-white"
                onClick={() => handleOptionClick(option.optionName)}>

                {/* Option Name - Click to Show Variants */}
                <input type="text" placeholder="Enter option name" name='optionName' value={option.optionName}
                  className="flex-grow bg-white outline-none cursor-pointer" readOnly />

                {/* Additional Price */}
                <span className="flex items-center">
                  +
                  <input type="text" placeholder="0.00" name='additionalPrice' value={option.additionalPrice}
                    className="w-20 text-center border-none bg-white outline-none border rounded p-1 cursor-pointer" readOnly />
                </span>
              </div>
            ))}

          </div>

          {/* ✅ Show Selected Option Name & Paste Button */}
          {/* ✅ Show Copy & Paste Buttons */}
          {selectedOption && (
            <div className="mt-4 flex items-center">
              <h3 className="text-lg font-semibold text-green-700">Selected Option: {selectedOption}</h3>

              {/* ✅ Paste Button (Only if data is copied) */}
              {copiedVariantData && (
                <button onClick={() => handlePasteVariants(selectedOption)} className="ml-4 px-4 py-2 bg-green text-white rounded flex items-center">
                  <FaPaste className="mr-1" /> Paste
                </button>
              )}
            </div>
          )}

          {/* ✅ Show Variant Creation Section Only for the Selected Option */}
          {selectedOption && (
            <div className="mt-4">
              <div className="mb-4 flex items-center">
                {/* Variant Group Name */}
                <div>
                  <label className="text-lg font-semibold">Variant Group Name:</label>
                  <input
                    type="text"
                    value={variantGroupName}
                    onChange={handleVariantGroupNameChange}
                    className="mt-2 bg-white outline-none border border-gray-300 rounded p-2 mx-2" />
                </div>

                {/* Copy Button */}
                <div className="flex justify-end mt-2">
                  <button onClick={() => handleCopyVariants()} className="px-4 py-2 bg-green text-white rounded flex items-center">
                    <FaCopy className="mr-1" /> Copy
                  </button>
                </div>
              </div>

              {/* Create Variant Items - Show only for selected option */}
              <p className="text-lg font-semibold mt-3">Create Variant Items</p>
              {optionState
                .filter(option => option.optionName === selectedOption) // ✅ Only show variants for selected option
                .map((variant, index) => (
                  <div key={index}>
                    {variant.varient.map((value, i) => (
                      <div key={i} className="flex items-center mt-2 border border-gray-300 rounded p-2 bg-white">
                        <input type="text" placeholder="Enter variant name" name='variantName' value={value.variantName}
                          onChange={(e) => handleVariantChanges(index, i, e)}
                          className="flex-grow bg-white outline-none" />
                        <span className="flex items-center">
                          Additional Price:
                          <input type="text" placeholder="0.00" name='additionalPrice' value={value.additionalPrice}
                            onChange={(e) => handleVariantChanges(index, i, e)}
                            className="ml-2 w-20 text-center bg-white outline-none border rounded p-1" />
                          <FaEdit className="ml-2 text-green cursor-pointer" onClick={() => handleEdit(index)} />
                          <FaTrash className="ml-2 text-red cursor-pointer" onClick={() => handleDeleteVariant(index, i)} />
                        </span>
                      </div>
                    ))}
                    <div className="flex justify-center">
                      <button onClick={() => { addOptionVariant(index) }} className="mt-4 px-4 py-2 bg-green text-white rounded">
                        + Add Another Variant
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          )}

          {/* Continue Button */}
          <div className='flex justify-center'>
            <button
              onClick={() =>
                navigate('/menuhead?steps=MenuDetails&step1=4')
              }
              className={`px-4 mt-4 py-2 rounded w-3/12 ${isNextEnabled3() ? 'bg-green text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
            // disabled={!isNextEnabled3()}
            >
              Continue
            </button>
          </div>
        </div>
      ) : ''}

      {query.get('step1') === '2' ? (
        <div className="p-4 bg-gray-50 h-screen">
          <div className="mb-4 flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-4">The Genie - Menu Creation Tool</h1>
          </div>
          <div className="flex items-center">
            <BsArrowLeft onClick={() => { navigate('/menuhead?steps=MenuDetails&step1=1') }} className="text-green text-2xl cursor-pointer mx-2" />
            <span className="font-semibold">Set Your Delivery Pricing</span>
          </div>
          <div className="mb-4 flex items-center mt-5">
            <label className="block text-base font-medium text-gray-700 mb-1">
              Base Price
            </label>
            <span className="flex items-center mx-5">
              <input
                type="text"
                className="border border-gray-300 rounded-md p-1"
                placeholder="Base Price"
                value={basePrice} // ✅ Use state
                onChange={handleBasePriceChange} // ✅ Update state
              />
            </span>
          </div>
          <div className='flex items-center'>
            <span className="text-lg font-bold my-4">Create Option</span>
            {options.map((option, index) => (
              <div key={index} className="">
                <input type="text" className="border border-gray-300 rounded-md p-2 w-full mx-3"
                  value={option} name="optionName" onChange={(e) => handleOptionChange(index, e)} placeholder="Option" />
              </div>
            ))}
          </div>
          <div className="mt-4">
            <h2 className="text-lg font-bold my-4">Option</h2>
            {optionItems.map((option, index) => (
              <div key={index} className="flex items-center border border-gray-300 rounded-md p-2 mb-2 bg-white">
                <input
                  type="text"
                  className="flex-grow bg-white outline-none"
                  value={option.optionName}
                  name="optionName"
                  onChange={(e) => handleOptionItemChange(index, e)}
                  placeholder="Option"
                />
                <span className="flex items-center ml-2">
                  Additional Price:
                  <input
                    type="text"
                    className="ml-2 w-20 text-right bg-white outline-none"
                    value={option.additionalPrice}
                    name="additionalPrice"
                    onChange={(e) => handleOptionItemChange(index, e)}
                    placeholder="0.0"
                  />
                </span>
              </div>
            ))}

            <button type="button" className="text-gray-700 border border-gray-300 bg-gray-200 rounded-md p-2 w-full" onClick={addOption}>
              + Add another Option
            </button>
          </div>
          <div className="mt-6 flex justify-center">
            <button
              onClick={() => { setIsNextClicked(false); setIsSectionPage3(true); navigate('/menuhead?steps=MenuDetails&step1=3') }}
              className={`px-4 py-2 rounded w-3/12 ${isNextEnabled2() ? 'bg-green text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
              disabled={!isNextEnabled2()}
            >
              Continue
            </button>

          </div>
        </div>
      ) : ''}
    </div>
  );
};
export default MenuDetails;